import React from 'react';
import {Switch} from 'react-router-dom';
import {useMountEffect} from '../../../hooks';
import {ErrorBoundaryRoute} from '../../../componentsHoops/Errors';
import QuotesContainer from '../../quotes';
import {QuoteEditPage} from '../../../componentsOld/quotes/QuoteEditPage';
import {AuthorisedQuotePreview} from '../../../componentsOld/quotes/AuthorisedQuotePreview';

export function QuotesClassicRoutes() {
  useMountEffect(() => {
    document.title = 'Quotes | Hoops';
  });

  return (
    <Switch>
      <ErrorBoundaryRoute exact path='/quotes'>
        <QuotesContainer/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute exact path='/quotes/new'>
        <QuoteEditPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute exact path='/quotes/:quoteNumber/edit'>
        <QuoteEditPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path={['/quotes/:quoteId/view', '/app/quotes/:quoteId/view']}>
        <AuthorisedQuotePreview/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path={['/quotes/:quoteId/comments', '/app/quotes/:quoteId/comments']}>
        <AuthorisedQuotePreview/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path={['/quotes/:quoteId/files', '/app/quotes/:quoteId/files']}>
        <AuthorisedQuotePreview/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path={['/quotes/:quoteId/tasks', '/app/quotes/:quoteId/tasks']}>
        <AuthorisedQuotePreview/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path={['/quotes/:quoteId/activity', '/app/quotes/:quoteId/activity']}>
        <AuthorisedQuotePreview/>
      </ErrorBoundaryRoute>
    </Switch>
  );
}
