import React, {useMemo} from 'react';
import {useParams} from 'react-router';
import {Column, HeaderInfo, Page, PageHeader, Row} from '../../../componentsLib/Layout';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {fullName} from '../../../utils';
import {TabPanel, TabbedContent} from '../../../componentsLib/Basic/TabbedContent';
import {useSalesDocViewOnlyState} from '../State/useSalesDocViewOnlyState';
import {useCompany} from '../../../hooks';
import {SalesDocView} from '../Views';
import {SalesDocActivityTab, SalesDocCommentsTab, JobProofsTab, JobPurchasingTab, JobShippingTab, SalesDocRevisionsTab, SalesDocTasksTab} from '../Tabs';
import Files from '../../../modules/file/Files';
import {ViewStatusBar} from '../../../componentsHoops/Views';
import {Deadline} from '../../../componentsLib/Basic/Deadline';
import {JobActionMenuButton} from '../Components/JobActionMenu';

export function JobPage({updateBoardField, /*refetchBoard*/}) {
  const {jobIdOrNumber} = useParams();
  const {salesDoc, refetchSalesDoc, updateField} = useSalesDocViewOnlyState({
    jobIdOrNumber,
    updateField: updateBoardField
  });
  const {company} = useCompany(salesDoc?.companyTradingEntityId ?? salesDoc?.customer?.companyTradingEntityId);

  const context = useMemo(() => ({
    company,
    job: salesDoc,
    salesDoc,
    refetchSalesDoc,
    refetchJob: refetchSalesDoc,
    updateBoardField,
  }), [company, refetchSalesDoc, salesDoc, updateBoardField]);

  return (
    <Page testId={'job-page'} className={'job-page entity-page'} context={context} pad>
      {salesDoc &&
        <>
          <PageHeader>
            <HeaderInfo fillWidth>
              <HeadingText><span>Job #{salesDoc.jobNumber}</span> {salesDoc.title}</HeadingText>
              <Row alignBaseline>
                <HeadingText subHeading>
                  <span>{[salesDoc.customer?.name, fullName(salesDoc.contact)].filter(Boolean).join(' | ')} </span>
                </HeadingText>
                {salesDoc.deadline &&
                  <Deadline date={salesDoc.deadline} />
                }
              </Row>
              <BodyText className={'salesdoc-owner'}>Owner: {salesDoc.owner?.fullName}</BodyText>
            </HeaderInfo>
            <Column>
              <JobActionMenuButton salesDoc={salesDoc} />
            </Column>
          </PageHeader>

          <ViewStatusBar entity={'jobs'} doc={salesDoc} updateField={updateField} />

          <TabbedContent routeParam={'tabName'}>
            <TabPanel label='Sale Detail' routeValue={'details'}>
              <SalesDocView />
            </TabPanel>
            <TabPanel label={'Purchasing'} routeValue={'purchasing'}>
              <JobPurchasingTab job={salesDoc}/>
            </TabPanel>
            <TabPanel label={'Proofs'} routeValue={'proofs'}>
              <JobProofsTab salesDoc={salesDoc} />
            </TabPanel>
            <TabPanel label='Files' routeValue={'files'}>
              <Files
                customerId={salesDoc.customer?._id}
                getEntityIds={[salesDoc._id, salesDoc.jobId].filter(Boolean)}
                setEntityIds={[salesDoc._id, salesDoc.jobId].filter(Boolean)}
                isWorkable={true}
                createTaskState={{jobIds: [salesDoc.jobId ?? salesDoc._id]}}
                showCustomerBtn={true}
                showUploadFileBtn={true}
              />
            </TabPanel>
            <TabPanel label='Comments' routeValue={'comments'}>
              <SalesDocCommentsTab salesDoc={salesDoc} />
            </TabPanel>
            <TabPanel label='Tasks' routeValue={'tasks'}>
              <SalesDocTasksTab salesDoc={salesDoc} />
            </TabPanel>
            <TabPanel label='BackTrack' routeValue={'backtrack'}>
              <SalesDocRevisionsTab salesDoc={salesDoc} />
            </TabPanel>
            <TabPanel label='Activity' routeValue={'activity'}>
              <SalesDocActivityTab quote={salesDoc} />
            </TabPanel>
            <TabPanel label='Shipping' routeValue={'shipping'}>
              <JobShippingTab />
            </TabPanel>
          </TabbedContent>
        </>
      }
    </Page>
  );
}
