import React, {useMemo} from 'react';
import {Column, Grid, GridRow, GridTitles} from '../../../componentsLib/Layout';
import {ImageGallery} from '../../../componentsLib/Basic/ImageGallery';
import {BodyText, CaptionText, HeadingText} from '../../../componentsLib/Text';
import {asCurrencyString, byLocaleCaseInsensitive} from '../../../utils';
import {registerGlobalStyle} from '../../../theme';
import {PurchaseOrderVendorActionView} from '../../Purchasing';
import {Link} from '../../../componentsLib/Basic';

registerGlobalStyle('.purchasing-view', (theme) => ({
  width: '100%',
  '.po-number': {
    color: theme.colors.text.highlight,
    fontWeight: theme.fontWeight.dark,
  },
  '.image-gallery': {
    width: 140,
    height: 140,
  },
  '.text-heading + .text-caption': {marginTop: theme.spacing(-2)},
  '&.purchasing-as-job-view': {
    '.group-view': {
      width: '100%',
      gridTemplateColumns: 'min-content 380fr 220fr 185fr 185fr 130fr 130fr 160fr 160fr 160fr',
      rowGap: 2,
      columnGap: 2,
      '.variant-items-view': {
        '&>.grid-row': {
          gridColumn: '2 / -1',
          alignSelf: 'start',
          gridAutoRows: 'min-content',
          gridTemplateRows: 'minmax(70px, min-content)',
        },
        '.variant-item-description': {
          fontWeight: theme.fontWeight.dark,
          alignSelf: 'end',
          gridColumn: 'span 4',
        },
      },
    },
  },
}));

export const PurchasingViewMode = {
  AS_JOB: 'as-job',
  AS_PO_TIMELINE: 'as-po-timeline',
};

export function PurchasingView({job, purchaseOrders: _purchaseOrders, viewMode}) {
  const purchaseOrders = useMemo(() => _purchaseOrders.toSorted((a, b) => byLocaleCaseInsensitive(a.vendor.name, b.vendor.name)), [_purchaseOrders]);

  return (
    <>
      {viewMode === PurchasingViewMode.AS_JOB &&
        <PurchasingAsJobView job={job}/>
      }
      {viewMode === PurchasingViewMode.AS_PO_TIMELINE &&
        <PurchasingAsPoTimelineView job={job} purchaseOrders={purchaseOrders}/>
      }
    </>
  );
}

export function PurchasingAsJobView({job}) {

  return (
    <Column className={'purchasing-as-job-view purchasing-view'} gap>
      {job.getGroupIds().map((groupId) => (
        <Grid key={groupId} className={'group-view'} border>
          <GridTitles shaded border>
            <CaptionText/>
            <CaptionText text={'Item'} />
            <CaptionText text={'Vendor'} />
            <CaptionText/>
            <CaptionText/>
            <CaptionText text={'Quantity'} right />
            <CaptionText text={'Rate'} right />
            <CaptionText text={'Total'} right />
            <CaptionText text={'Status'} right />
            <CaptionText text={'Action'} right />
          </GridTitles>
          {job.getProductVariantIdsInGroup(groupId).map((variantId) => (
            <VariantItemsView key={variantId} variants={job.getVariantItems(variantId)}/>
          ))}
        </Grid>
      ))}
    </Column>
  );
}

export function PurchasingAsPoTimelineView({/*job,*/ purchaseOrders}) {

  return (
    <Column className={'purchasing-as-job-view purchasing-view'} gap>
      <HeadingText x20 dark text={'Needs to be added to a PO'}/>
      <CaptionText>
        You can create Purchase Orders for just this job right here. You can pick and choose which items are included in the Purchase Orders,
        and you can even tweak the quantities and prices. If you used merged Purchase Orders in the past, you can create them in the
        <Link to={'/purchasing/queue/'} underline> Purchasing Queue </Link>
        without needing to create each Job&apos;s Purchase Orders individually first.
      </CaptionText>
      {purchaseOrders.map((purchaseOrder) => (
        <PurchaseOrderVendorActionView key={purchaseOrder.draftId} purchaseOrder={purchaseOrder}/>
      ))}
      <HeadingText x20 dark text={'Needs to be Ordered'}/>
      <HeadingText x20 dark text={'Been Ordered, Waiting to Receive'}/>
    </Column>
  );
}

function VariantItemsView({variants}) {
  return (variants.length > 0 &&
    <GridRow className={'variant-items-view'}>
      {variants[0].image &&
        <ImageGallery images={[variants[0].image]} clickForFullscreen />
      }
      <GridRow className={'variant-items-details'}>
        <BodyText className={'variant-item-description'} text={variants[0].name}/>
        <VariantItemHeadings />
        {variants.map((item) => (
          <VariantItemView key={item.itemId} item={item} />
        ))}
      </GridRow>
    </GridRow>
  );
}

function VariantItemHeadings() {
  return (
    <GridRow className={'variant-item-headings'}>
      <CaptionText text={'PO #'} />
      <CaptionText text={'Vendor'} />
      <CaptionText text={'Size'} />
      <CaptionText text={'Color'} />
      <CaptionText text={'Quantity'} right />
      <CaptionText text={'Rate (Ea)'} right />
      <CaptionText text={'Total'} right />
    </GridRow>
  );
}

function VariantItemView({item}) {
  return (
    <GridRow className={'variant-item-view'}>
      <BodyText className='po-number' text={'draft'} />
      <BodyText text={item.vendorName} />
      <BodyText text={item.size} />
      <BodyText text={item.color} />
      <BodyText right text={item.quantity} />
      <BodyText currency right text={asCurrencyString(item.buyPrice)} />
      <BodyText currency right text={asCurrencyString(item.totalCost)} />
      {/*<ActionsButton defaultAction={'Ordered'} />*/}
    </GridRow>
  );
}
