import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {fullName} from '../utils';

export function useUser() {
  const user = useSelector((state) => state.authReducer.userData);
  return useMemo(() => ({
    user: {
      ...user,
      fullName: user.fullName || fullName(user)
    }
  }), [user]);
}
