import React, {useCallback, useState} from 'react';
import {StatusPicker} from '../../componentsLib/Pickers';
import {Placement} from '../../componentsLib/Popovers';
import {registerGlobalStyle} from '../../theme';
import {get} from 'lodash';
import {useViewState} from './index';
import {FieldColumns} from '../../componentsLib/Layout';
import {useMountEffect} from '../../hooks';

registerGlobalStyle('.view-status-bar', (theme) => ({
  margin: theme.spacing(2, 0, 2, 0),
  alignSelf: 'start',
  '.status-column': {
    minWidth: 145,
    marginRight: theme.spacing(1)
  },
  '.status-picker': {
    width: '100%',
    '.text.text-caption': {order: 1,},
    '.input-outline': {
      padding: 0,
      border: 'none'
    },
    '.status-label': {padding: theme.spacing(.5, 1)},
  },
}));

export function ViewStatusBar({entity, doc, updateField}) {
  const [values, setValues] = useState({});
  const viewState = useViewState(entity);
  const statusFields = viewState?.fields.filter(({type}) => type === 'STATUS');

  useMountEffect(() => {
    viewState?.fields
      .filter(({type}) => type === 'STATUS')
      .map((field) => {setValues((prev) => ({...prev, [field.path]: get(doc, field.path, '')}));});
  },[]);

  const handleChange = useCallback((field, newValue) => {
    setValues((prev) => ({...prev, [field.path]: newValue}));
    if (updateField) {
      updateField({id: doc._id, field: field.path, value: newValue});
    }
  }, [doc, updateField]);

  const handleChangeOptions = useCallback(async (field, newOptions) => {
    await viewState.setFieldOptions(field.path, newOptions);
  }, [viewState]);

  return (
    <FieldColumns columns={statusFields.length} className={'view-status-bar'}>
      {statusFields?.map((field, idx) => (
        <div key={idx} className={'status-column'}>
          <StatusPicker
            initialOpen={false}
            label={field.title}
            placement={Placement.Bottom}
            value={values[field.path]}
            onChange={(value) => handleChange(field, value)}
            options={field.options}
            onChangeOptions={(options) => handleChangeOptions(field, options)}
          />
        </div>
      ))}
    </FieldColumns>
  );
}
