
export class ObjectMap {
  constructor(entries) {
    this.insertEntries(entries instanceof ObjectMap ? entries.entries() : entries);
  }

  static groupBy(array, funcOrPropertyName) {
    if (typeof funcOrPropertyName === 'string') {
      return array.reduce((map, elem) => {
        const key = elem[funcOrPropertyName];
        if (map.has(key)) {
          map[key].push(elem);
        } else {
          map[key] = [elem];
        }
        return map;
      }, new ObjectMap());
    } else {
      return array.reduce((map, elem) => {
        const key = funcOrPropertyName(elem);
        if (map.has(key)) {
          map[key].push(elem);
        } else {
          map[key] = [elem];
        }
        return map;
      }, new ObjectMap());
    }
  }

  static mapBy(array, funcOrPropertyName) {
    if (typeof funcOrPropertyName === 'string') {
      return array.reduce((map, elem) => {
        const key = elem[funcOrPropertyName];
        if (!map.has(key)) {
          map[key] = elem;
        }
        return map;
      }, new ObjectMap());
    } else {
      return array.reduce((map, elem) => {
        const key = funcOrPropertyName(elem);
        if (!map.has(key)) {
          map[key] = elem;
        }
        return map;
      }, new ObjectMap());
    }
  }

  delete(key) {
    if (!this.has(key)) {
      return false;
    }
    delete this[key];
    return true;
  }

  entries() {
    return Object.entries(this);
  }

  get(key) {
    return this[key];
  }

  has(key) {
    return Object.hasOwn(this, key);
  }

  insertBy(values, by) {
    if (values) {
      values.forEach((value) => {
        const key = typeof by === 'string' ? value[by] : by(value);
        if (!this.has(key)) {
          this[key] = value;
        }
      });
    }
    return this;
  }

  insertEntries(entries) {
    if (entries) {
      entries.forEach(([key, value]) => {
        if (!this.has(key)) {
          this[key] = value;
        }
      });
    }
    return this;
  }

  keys() {
    return Object.keys(this);
  }

  set(key, value) {
    this[key] = value;
    return this;
  }

  setInit(key, initValue) {
    if (!this.has(key)) {
      this[key] = initValue;
      return initValue;
    }
    return this[key];
  }

  values() {
    return Object.values(this);
  }
}
