// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {useLazyQuery, useMutation} from '@apollo/client';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, makeStyles, MuiThemeProvider, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {GQL_BULK_CREATE_PROOF, GQL_GET_CUSTOMER_PROOFS} from '../../../queries/proofs';
import theme from '../../../ui/theme';
import green from '../../../ui/theme/green';
import ProofList from './ProofList';
import {PROOF_STATUSES} from '../../../constants/status';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(2),
      overflowX: 'hidden'
    }
  },
  greyText: {color: theme.colors.grey.main},
  rightButtons: {marginLeft: theme.spacing(1)}
}));

const standardProofPreviewOptions = {
  thumbWidth: 80,
  thumbHeight: 80,
  tooltipMaxWidth: 650
};

export const AddExistingProofModal = ({
  isOpen = false,
  title = 'Previous Proofs',
  subTitle = 'Any proof linked with this customer will display here and can be reused on this job. The proof image will need to be sent for approval again.',
  titleLinkProduct = 'Link to Product',
  job = {},
  salesDoc = {},
  customerId = null,
  proofPreviewOptions = standardProofPreviewOptions,
  onCloseModal = () => null,
  resetForm = () => null,
  updateJobProof,
  updateJobBoard,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [linkProducts, setLinkProducts] = useState({});
  const [view, setView] = useState(1);

  const [getProofs, {data,  loading: loadingProofs}] = useLazyQuery(GQL_GET_CUSTOMER_PROOFS, {
    fetchPolicy: 'no-cache',
    variables: {customerId: customerId}
  });
  const proofs = data?.proofs;
  const [createProof, {loading: loadingUpdate}] = useMutation(GQL_BULK_CREATE_PROOF, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result) {
        resetForm();
        onCloseModal();
        updateJobProof();
        updateJobBoard();
      }
    },
  });

  const handleSelectionChange = (selections) => {
    setSelected(selections.filter(Boolean));
  };

  const handleLinkProducts = (item, productIds) => {
    setLinkProducts((prev) => ({...prev, [item._id]: productIds.map((el) => el.value),}));
  };

  const handleSubmit = () => {
    const data = selected.map((_id) => ({
      fileId: proofs.find((proof) => proof._id === _id).fileId,
      jobId: job?._id ?? salesDoc.jobId,
      salesDocId: salesDoc.hasClassicJob() ? undefined : salesDoc._id,
      productIds: linkProducts[_id],
      status: PROOF_STATUSES[0].name
    }));
    createProof({variables: {data}});

  };

  useEffect(() => {
    getProofs();
  }, [getProofs]);

  return (
    <Dialog
      open={isOpen}
      maxWidth={'lg'}
      fullWidth={true}
      onClose={onCloseModal}
      className={classes.dialog}
    >
      <DialogTitle>
        <Typography variant='h4'>{view === 1 ? title : titleLinkProduct}</Typography>
        <Typography variant='body2' className={classes.greyText}>{view === 1 && subTitle}</Typography>
      </DialogTitle>
      <DialogContent style={{overflowX: 'hidden'}}>

        <ProofList
          proofs={proofs}
          proofPreviewOptions={proofPreviewOptions}
          selectable={true}
          selected={selected}
          onSelect={handleSelectionChange}
          onLinkProducts={handleLinkProducts}
          showAddButton={false}
          loading={loadingProofs}
          view={view}
          job={job ?? salesDoc}
        />

      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button onClick={onCloseModal}>
              <Icon>close</Icon>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>

                {view === 1 &&
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => setView(2)}
                    type='submit'
                    disabled={loadingUpdate || !(selected.length > 0)}
                    className={classes.rightButtons}
                  >
                    Next
                  </Button>
                }

                {view === 2 &&
                  <>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={() => setView(1)}
                      type='submit'
                      disabled={false}
                      className={classes.rightButtons}
                    >
                      Back
                    </Button>

                    <MuiThemeProvider theme={green}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleSubmit}
                        type='submit'
                        disabled={false}
                        className={classes.rightButtons}
                      >
                        {loadingUpdate
                          ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Save</>
                          : 'Save'
                        }
                      </Button>
                    </MuiThemeProvider>
                  </>
                }

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

};

export default AddExistingProofModal;
