import {useLayoutEffect, useMemo, useState} from 'react';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.ripple-container', () => ({
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 'calc(1px * var(--ripple-x))',
    top: 'calc(1px * var(--ripple-y))',
    width: 0,
    height: 0,
    display: 'block',
    borderRadius: '50%',
    border: '0px solid #FFFFFF20',
    backgroundColor: 'transparent',
    transition: 'width .3s linear, height .3s linear',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
  },
  '&.ripple::after': {
    content: '""',
    borderWidth: '10px 20px',
    width: 'calc(2.25 * var(--ripple-width) * 1px)',
    height: 'calc(2.25 * var(--ripple-width) * 1px)',
    transition: 'width .3s linear, height .3s linear',
  },
  overflow: 'clip',
}));

export function useRippleEffect(element) {
  const [rippleStyles, setRippleStyles] = useState({});
  const [rippleClasses, setRippleClasses] = useState('');

  useLayoutEffect(() => {
    if (element) {
      const mouseDown = (e) => {
        if (e.button === 0) {
          setRippleStyles({
            ['--ripple-x']: e.layerX,
            ['--ripple-y']: e.layerY,
            ['--ripple-width']: element.offsetWidth,
            ['--ripple-height']: element.offsetHeight
          });
          setRippleClasses('ripple');
        }
      };
      const transEnd = (e) => {
        if (e.propertyName === 'width' && e.target.classList.contains('ripple') && e.pseudoElement === '::after') {
          setRippleClasses('');
        }
      };
      element.addEventListener('mousedown', mouseDown);
      element.addEventListener('transitionend', transEnd);
      return () => {
        element.removeEventListener('mousedown', mouseDown);
        element.removeEventListener('transitionend', transEnd);
      };
    }
    return undefined;
  }, [element]);

  return useMemo(() => ({
    rippleStyles,
    rippleClasses: [rippleClasses, 'ripple-container'].filter(Boolean).join(' '),
  }), [rippleClasses, rippleStyles]);
}
