import React from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import PropTypes from 'prop-types';
import {CaptionText} from '../Text';

registerGlobalStyle('.grid-layout', (theme) => ({
  display: 'grid',
  alignItems: 'baseline',
  rowGap: theme.spacing(1),
  columnGap: theme.spacing(1),
  alignSelf: 'stretch',
  justifyItems: 'start',
  '&>.grid-titles, .subgrid-row>.grid-titles': {
    display: 'grid',
    gridTemplateColumns: 'subgrid',
    gridTemplateRows: 'subgrid',
    gridColumn: '1 / -1',
    borderRadius: 'inherit',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    alignItems: 'center',
    padding: theme.spacing(2, 2, 2, 0),
    '&.border': {
      borderBottom: `1px solid ${theme.colors.border.light}`,
      borderColor: 'inherit',
    },
    '&.shaded': {
      backgroundColor: theme.colors.background.empty,
      '.text-caption': {color: theme.colors.text.main},
    },
    '.text-caption': {
      fontSize: '.875rem',
      fontWeight: theme.fontWeight.dark,
    },
  },
  '.grid-row': {
    width: '100%',
    height: '100%',
    gridColumn: '1 / -1',
  },
  '&.border, .grid-row.border': {
    border: `1px solid ${theme.colors.border.light}`,
    borderRadius: theme.shape.borderRadius,
  },
  '.grid-span': {width: '100%'},
  '.subgrid': {
    display: 'grid',
    alignItems: 'inherit',
    rowGap: 'inherit',
    columnGap: 'inherit',
  },
  '.subgrid-row': {
    gridTemplateColumns: 'subgrid',
    gridColumn: '1 / -1',
  },
  '.subgrid-column': {gridTemplateRows: 'subgrid'},
}));

export function Grid({className, auto, border, columns, fixed, rows, gridTemplateColumns, gridTemplateRows, children, ...props}) {
  if (typeof columns === 'number') {
    gridTemplateColumns = `repeat(${columns}, ${auto ? 'auto' : '1fr'})`;
  } else if (typeof columns === 'string') {
    gridTemplateColumns = columns;
  } else if (Array.isArray(columns)) {
    gridTemplateColumns = columns.map((col) => typeof col === 'string' ? col : (fixed ? `minmax(0, ${col}fr)` : `${col}fr`)).join(' ');
  }

  if (typeof rows === 'number') {
    gridTemplateRows = `repeat(${rows}, ${auto ? 'auto' : '1fr'})`;
  } else if (typeof rows === 'string') {
    gridTemplateRows = rows;
  } else if (Array.isArray(rows)) {
    gridTemplateRows = rows.map((row) => typeof row === 'string' ? row : (fixed ? `minmax(0, ${row}fr)` : `${row}fr`)).join(' ');
  }

  return (
    <div
      className={classNames([className, 'grid-layout', border && 'border'])}
      style={{gridTemplateColumns, gridTemplateRows}}
      {...props}
    >
      {children}
    </div>
  );
}

Grid.propTypes = {
  className: HoopsPropTypes.className,
  auto: HoopsPropTypes.bool,
  border: HoopsPropTypes.bool,
  columns: HoopsPropTypes.oneOfType([HoopsPropTypes.number, HoopsPropTypes.string, HoopsPropTypes.arrayOf(HoopsPropTypes.stringOrNumber)]),
  fixed: HoopsPropTypes.bool,
  gridTemplateColumns: PropTypes.string,
  gridTemplateRows: PropTypes.string,
  rows: HoopsPropTypes.oneOfType([HoopsPropTypes.number, HoopsPropTypes.string, HoopsPropTypes.arrayOf(HoopsPropTypes.stringOrNumber)]),
  children: HoopsPropTypes.children,
};

export function GridRow({className, border, children}) {
  return (
    <div className={classNames([className, 'grid-row subgrid subgrid-row', border && 'border'])}>
      {children}
    </div>
  );
}

GridRow.propTypes = {
  className: HoopsPropTypes.className,
  border: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};

export function GridSpan({className, gridColumn, gridRow, colFill, colSpan, rowFill, rowSpan, subgrid, children}) {
  if (typeof colSpan === 'number') {
    gridColumn = `span ${colSpan}`;
  } else if (colSpan) {
    gridColumn = colSpan;
  } else if (colFill) {
    gridColumn = 'span 1 / -1';
  }

  if (typeof rowSpan === 'number') {
    gridRow = `span ${rowSpan}`;
  } else if (colSpan) {
    gridRow = rowSpan;
  } else if (rowFill) {
    gridRow = 'span 1 / -1';
  }

  return (
    <div
      className={classNames([
        className,
        'grid-span',
        subgrid && 'subgrid subgrid-row subgrid-column',
      ])}
      style={{gridColumn, gridRow}}
    >
      {children}
    </div>
  );
}

GridSpan.propTypes = {
  className: HoopsPropTypes.className,
  colFill: HoopsPropTypes.bool,
  colSpan: HoopsPropTypes.stringOrNumber,
  rowFill: HoopsPropTypes.bool,
  rowSpan: HoopsPropTypes.stringOrNumber,
  subgrid: HoopsPropTypes.bool,
  gridColumn: PropTypes.string,
  gridRow: PropTypes.string,
  children: HoopsPropTypes.children,
};

export function GridTitles({className, border, shaded, titles, children}) {
  return (
    <div className={classNames([className, 'grid-titles', border && 'border', shaded && 'shaded'])}>
      {titles?.map((title, index) => (
        <CaptionText key={index} text={title}/>
      ))}
      {children}
    </div>
  );
}

GridTitles.propTypes = {
  className: HoopsPropTypes.className,
  border: HoopsPropTypes.bool,
  shaded: PropTypes.bool,
  titles: HoopsPropTypes.arrayOfString,
  children: HoopsPropTypes.children,
};
