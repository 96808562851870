//
// Helper for mapping IDs from one document onto another document.
//
// The map function will cause an existing ID to be mapped to a new ID of the same type. That is,
// the prefix and the suffix will be preserved and the key string will be replaced.
//
import {randomString} from '../utils';

export class ItemIdMap {
  map(key) {
    if (!key) {
      return key;
    }
    if (!this[key]) {
      const tokens = key.split(':');
      tokens[1] = randomString();
      this[key] = tokens.join(':');
    }
    return this[key];
  }
}
