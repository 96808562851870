import {bind, byLocaleCaseInsensitive, listToMap, sortSizes} from '../utils';
import {uniq} from 'lodash';
import {Vendor} from './Vendor';

export class Product {
  constructor(that) {
    if (that) {
      Object.assign(this, that);
    }
    bind(this);
  }

  static fromApi(apiProduct) {
    const colorsMap = {};
    const sizesMap = {};
    const variantImagesMap = {};

    apiProduct.variants?.filter(Boolean).forEach((variant) => {
      if (colorsMap[variant.color] == null) {
        colorsMap[variant.color] = variant.color;
      }
      if (sizesMap[variant.size] == null) {
        sizesMap[variant.size] = variant.size;
      }
      if (variantImagesMap[variant.color] == null && variant.image) {
        variantImagesMap[variant.color] = variant.image;
      }
    });

    // For Sage products get the images and colors
    const namedImagesMap = apiProduct.extensions?.pics.reduce((acc, {caption, index, url}) => ({...acc, [caption || index.toString()]: url}), {}) ?? [];
    if (!apiProduct.variants?.length) {
      apiProduct.extensions?.colors?.split(/\s*,\s*/).forEach((color) => colorsMap[color] = color);
    }

    // Sort the colors alphabetically, then create a new color map that has the keys sorted alphabetically as well
    const colors = Object.keys(colorsMap).toSorted(byLocaleCaseInsensitive);
    const names = uniq([...colors, ...Object.keys(namedImagesMap)]).toSorted(byLocaleCaseInsensitive);
    const namedImages = names.reduce((acc, name) => {
      const image = variantImagesMap[name] || namedImagesMap[name];
      if (image) {
        acc[name] = image;
      }
      return acc;
    }, {});

    return new Product({
      variants: [],
      ...apiProduct,
      colors,
      namedImages,
      primaryImage: Object.values(namedImages).includes(apiProduct.primaryImage) ? null : apiProduct.primaryImage,
      sizes: sortSizes(Object.keys(sizesMap)),
      vendor: apiProduct.vendor ? Vendor.fromApi(apiProduct.vendor) : undefined,
    });
  }

  static fromApiMap(apiProducts) {
    if (!Array.isArray(apiProducts)) {
      apiProducts = [apiProducts];
    }
    return listToMap(apiProducts.map((product) => Product.fromApi(product)));
  }
}
