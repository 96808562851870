import {ObjectMap} from './ObjectMap';

let applied = false;
export function applyLanguageExtensions() {
  if (applied) {
    return;
  }
  applied = true;

  if (!Array.prototype.toGroupedBy || process.env.NODE_ENV !== 'production') {
    Array.prototype.toGroupedBy = function (funcOrPropertyName) {
      return ObjectMap.groupBy(this, funcOrPropertyName);
    };
  }

  if (!Array.prototype.toMappedBy || process.env.NODE_ENV !== 'production') {
    Array.prototype.toMappedBy = function (funcOrPropertyName) {
      return ObjectMap.mapBy(this, funcOrPropertyName);
    };
  }

  if (!Array.prototype.toReversed || process.env.NODE_ENV !== 'production') {
    Array.prototype.toReversed = function () {
      return [...this].reverse();
    };
  }

  if (!Array.prototype.toSorted || process.env.NODE_ENV !== 'production') {
    Array.prototype.toSorted = function (sortFn) {
      return [...this].sort(sortFn);
    };
  }

  if (!Array.prototype.toSpliced || process.env.NODE_ENV !== 'production') {
    Array.prototype.toSpliced = function (start, deleteCount, ...insert) {
      const arr = [...this];
      arr.splice(start, deleteCount, ...insert);
      return arr;
    };
  }

  if (!Array.prototype.toUniq || process.env.NODE_ENV !== 'production') {
    Array.prototype.toUniq = function () {
      return [...(new Set(this)).keys()];
    };
  }

  if (!Array.prototype.toUniqBy || process.env.NODE_ENV !== 'production') {
    Array.prototype.toUniqBy = function (uniqByFunc) {
      return [...(new Map(this.map((elem) => [uniqByFunc(elem), elem]))).values()];
    };
  }
}

applyLanguageExtensions();
