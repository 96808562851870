import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import AlertIcon from '@mui/icons-material/ReportProblem';
import {ToolTip} from './ToolTip';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {Placement} from '../Popovers';

registerGlobalStyle('.info-tip', (theme) => ({
  '&.tooltip-container': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    padding: 'inherit',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    'svg': {
      position: 'relative',
      left: -2,
      fontSize: '1rem',
      color: theme.colors.text.mediumIcon
    },
  },
  '&.tooltip-popover.tooltip-popover.tooltip-popover': {marginBottom: 4},
  '&.alert-tip': {
    '&.tooltip-container svg': {
      color: theme.colors.text.warning,
      fontSize: '1.5rem',
    },
    '&.tooltip-popover': {
      color: theme.colors.palette.orangeContrast,
      backgroundColor: theme.colors.palette.orangeLight,
      boxShadow: `inset 0 0 0 1px ${theme.colors.palette.orange}`,
      '*': {
        color: theme.colors.palette.orange,
        marginLeft: -3,
      },
      '&.tooltip-popover.tooltip-popover': {marginBottom: 8},
    },
  },
}));

export function InfoTip({className, alert, tip, offset, placement, onClick, children}) {
  return (
    <ToolTip
      className={[className, 'info-tip', alert && 'alert-tip']}
      placement={placement ?? Placement.Top}
      tip={tip ?? children}
      onClick={onClick}
      offset={offset}
    >
      {alert ? <AlertIcon/> : <InfoIcon/>}
    </ToolTip>
  );
}

InfoTip.propTypes = {
  className: HoopsPropTypes.className,
  alert: HoopsPropTypes.bool,
  offset: HoopsPropTypes.arrayOf(HoopsPropTypes.number),
  placement: HoopsPropTypes.string,
  tip: HoopsPropTypes.string,
  onClick: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
