import React, {useCallback, useLayoutEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {ExpandMore as DownArrowIcon} from '@mui/icons-material';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {BusySpinner} from './BusySpinner';
import {PopupMenu} from '../Popovers';
import {useRippleEffect} from '../Effects';
import {Decorator} from './Decorator';

registerGlobalStyle('.button', (theme) => ({
  display: 'flex',
  position: 'relative',
  width: 'fit-content',
  textWrap: 'nowrap',
  transition: theme.transitions.out.all,
  '*, svg': {transition: theme.transitions.out.all},
  '&:hover, &:hover *, &:hover svg': {transition: theme.transitions.in.all},
  '&:not(.disabled)': {cursor: 'pointer',},
  '&>.split-button:not(.disabled)': {cursor: 'pointer',},
  '&.action-button, &.nav-button': {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.defaultFontSize,
    fontWeight: theme.fontWeight.dark,
    lineHeight: 1.75,
    textTransform: 'uppercase',
    boxShadow: '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  },
  '&.nav-button': {
    padding: theme.spacing(0.75, 1.875, .5),
    '&.inplace-edit': {padding: theme.spacing(0.25, 1.875, 0)},
    '&.fill-width': {
      width: '100%',
      textAlign: 'left',
      '.button-text': {
        flexGrow: 1,
        textAlign: 'left',
      },
    },
  },
  '&>.button-text:not(:first-child)': {paddingLeft: theme.spacing(.5),},
  '&>.button-text:not(:last-child)': {paddingRight: theme.spacing(1.5)},
  '&.slide-text>.button-text:not(:first-child)': {
    paddingLeft: 0,
    '&>div': {paddingLeft: theme.spacing(.5)},
  },
  '&.slide-text>.button-text:not(:last-child)': {
    paddingRight: 0,
    '&>div': {paddingRight: theme.spacing(1.5)},
  },
  '&>div': {alignSelf: 'baseline'},
  '&>svg, &>.material-symbols-outlined': {
    alignSelf: 'start',
    fontSize: '150%',
  },
  '&.unstyled': {
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius,
    color: theme.colors.text.main,
    padding: theme.spacing(0.625, 1.875),
    fontSize: theme.defaultFontSize,
    fontWeight: theme.fontWeight.dark,
    lineHeight: 1.75,
    background: 'transparent',
  },
  '&.nav-primary, &.nav-primary.split>.split-button': {
    backgroundColor: theme.colors.palette.blue,
    borderColor: theme.colors.palette.blue,
    color: theme.colors.text.contrast,
    '&.disabled': {
      backgroundColor: theme.colors.palette.blueDisabled,
      borderColor: theme.colors.palette.blueDisabled,
      color: theme.colors.text.contrast,
    },
    '&:not(.disabled):hover:not(:has(>.split-button:hover))': {
      backgroundColor: theme.colors.palette.blueHover,
      borderColor: theme.colors.palette.blueHover,
      color: theme.colors.text.contrast,
    },
  },
  '&.nav-main, &.nav-positive, &.nav-main>.split-button, &.nav-positive>.split-button': {
    backgroundColor: theme.colors.palette.green,
    borderColor: theme.colors.palette.green,
    color: theme.colors.text.contrast,
    '&.disabled': {
      backgroundColor: theme.colors.palette.greenDisabled,
      borderColor: theme.colors.palette.greenDisabled,
      color: theme.colors.text.contrast,
    },
    '&:not(.disabled):hover:not(:has(>.split-button:hover))': {
      backgroundColor: theme.colors.palette.greenHover,
      borderColor: theme.colors.palette.greenHover,
      color: theme.colors.text.contrast,
    },
  },
  '&.nav-standard, &.nav-standard.split>.split-button': {
    backgroundColor: theme.colors.palette.white,
    borderColor: theme.colors.palette.blue,
    color: theme.colors.palette.blue,
    '&.disabled': {
      backgroundColor: theme.colors.palette.white,
      borderColor: theme.colors.palette.blueDisabled,
      color: theme.colors.palette.blueDisabled,
    },
    '&:not(.disabled):hover:not(:has(>.split-button:hover))': {
      backgroundColor: theme.colors.background.hover,
      borderColor: theme.colors.palette.blueHover,
      color: theme.colors.text.highlightDark,
    },
  },
  '&.nav-minor, &.nav-minor.split>.split-button': {
    backgroundColor: theme.colors.transparent,
    borderColor: theme.colors.transparent,
    color: theme.colors.palette.blue,
    boxShadow: 'none',
    '&.disabled': {
      backgroundColor: theme.colors.palette.transparent,
      borderColor: theme.colors.palette.transparent,
      color: theme.colors.palette.blueDisabled,
    },
    '&:not(.disabled):hover:not(:has(>.split-button:hover))': {
      backgroundColor: theme.colors.background.hover,
      borderColor: theme.colors.transparent,
      color: theme.colors.text.highlight,
    },
  },
  '&.nav-negative, &.nav-negative.split>.split-button': {
    backgroundColor: theme.colors.palette.white,
    borderColor: theme.colors.palette.white,
    color: theme.colors.text.medium,
    boxShadow: 'none',
    '&.disabled': {
      backgroundColor: theme.colors.palette.white,
      borderColor: theme.colors.palette.white,
      color: theme.colors.text.mediumDisabled,
    },
    '&:not(.disabled):hover:not(:has(>.split-button:hover))': {
      backgroundColor: theme.colors.alpha.dark,
      borderColor: theme.colors.palette.transparent,
      color: theme.colors.text.medium,
    },
  },
  '&.danger': {
    backgroundColor: theme.colors.palette.red,
    borderColor: theme.colors.palette.red,
    color: theme.colors.text.contrast,
    '&.disabled': {
      backgroundColor: theme.colors.palette.redDisabled,
      borderColor: theme.colors.palette.redDisabled,
      color: theme.colors.text.contrast,
    },
    '&:not(.disabled):hover:not(:has(>.split-button:hover))': {
      backgroundColor: theme.colors.palette.redHover,
      borderColor: theme.colors.palette.redHover,
      color: theme.colors.text.contrast,
    },
  },
  '&.action-standard': {
    display: 'inline-flex',
    background: 'none',
    border: 'none',
    textTransform: 'none',
    padding: 0,
    boxShadow: 'none',
    fontWeight: theme.fontWeight.normal,
    '&.disabled': {opacity: .7,}
  },
  '&.action-primary': {
    display: 'inline-flex',
    background: 'none',
    border: 'none',
    textTransform: 'none',
    padding: 0,
    boxShadow: 'none',
    fontWeight: theme.fontWeight.normal,
    color: theme.colors.text.highlight,
  },
  '&.action-inline': {
    display: 'inline',
    background: 'none',
    border: 'none',
    textTransform: 'none',
    padding: 2,
    boxShadow: 'none',
    textDecoration: 'underline',
    fontWeight: 'inherit',
    color: 'inherit',
    margin: '0 3px',
  },
  '&.icon:not(.nav-button)': {
    display: 'inline-flex',
    background: 'none',
    border: 'none',
    textTransform: 'none',
    padding: 0,
    boxShadow: 'none',
    fontWeight: theme.fontWeight.normal,
    '&:hover': {color: theme.colors.text.highlight,},
  },
  '&.icon.nav-button': {
    textTransform: 'none',
    padding: 0,
    boxShadow: 'none',
    fontWeight: theme.fontWeight.normal,
  },
  '&.icon-button': {
    color: theme.colors.text.main,
    '.icon-button-spacer': {width: 0},
    '&.disabled': {color: theme.colors.text.medium},
    '&>svg': {fontSize: '170%'},
    '&>svg, &>.material-symbols-outlined, &>.busy-spinner': {
      color: theme.colors.text.white,
      backgroundColor: theme.colors.palette.green,
      border: `1px solid ${theme.colors.palette.green}`,
      borderRadius: theme.shape.borderRadius,
    },
    '&:hover': {
      '&>svg, &>.material-symbols-outlined': {
        backgroundColor: theme.colors.palette.greenHover,
        borderColor: theme.colors.palette.greenHover,
      }
    },
    '&.disabled, &.disabled:hover': {
      '&>svg, &>.material-symbols-outlined': {
        backgroundColor: theme.colors.palette.greenDisabled,
        border: `1px solid ${theme.colors.palette.greenDisabled}`
      }
    },
    '&.action-primary': {
      '&>svg, &>.material-symbols-outlined': {
        backgroundColor: theme.colors.palette.blue,
        borderColor: theme.colors.palette.blue,
      },
      '&:hover': {
        '&>svg, &>.material-symbols-outlined': {
          backgroundColor: theme.colors.palette.blueHover,
          borderColor: theme.colors.palette.blueHover,
        }
      },
      '&.disabled, &.disabled:hover': {
        '&>svg, &>.material-symbols-outlined': {
          backgroundColor: theme.colors.palette.blueDisabled,
          border: `1px solid ${theme.colors.palette.blueDisabled}`
        }
      },
    },
  },
  '&.split': {
    '&>.split-button': {
      position: 'relative',
      display: 'block',
      top: -1,
      margin: theme.spacing(0, 0, 0, -.25),
      padding: theme.spacing(0.625, 0.625),
      transition: theme.transitions.out.all,
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 8,
        bottom: 8,
        borderLeft: '1px solid currentColor',
      },
      '&:hover': {transition: theme.transitions.in.all,},
      '&>svg, &>.material-symbols-outlined': {
        marginTop: 1,
        verticalAlign: 'top',
        fontSize: '1.5rem',
      },
    },
    '&.nav-button': {
      '&>.button-text': {marginRight: theme.spacing(.5),},
      '&>.split-button': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderLeft: 'none',
        borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
        margin: theme.spacing(-.75, -2, -.875, 0),
        '&::before': {
          top: 0,
          bottom: 0,
        },
      }
    },
  },
  '&.slide-text': {
    '.button-slider': {
      width: 0,
      overflow: 'hidden',
      color: theme.colors.text.highlightDark,
      '&>.not-ready': {position: 'absolute'},
    },
    '&:hover': {'.button-slider': {width: 'var(--slideWidth)',}},
  },
  '&.menu:not(.split)': {
    '.button-text': {paddingRight: theme.spacing(.75)},
    ':has(.button-text)': {paddingRight: theme.spacing(0.625)},
    'svg, .material-symbols-outlined': {fontSize: '1.5rem',},
  },
  '&.menu-open': {'span:last-child, svg:last-child': {transform: 'rotate3d(1, 0, 0, 180deg) translateY(1px)'}},
  '&.button-wrap': {textWrap: 'wrap',},
  '&.all-caps': {textTransform: 'uppercase'},
  '&.no-caps': {textTransform: 'none'},
  '.busy-spinner': {
    position: 'relative',
    top: 3,
    height: 0,
    marginRight: theme.spacing(2),
    color: 'inherit',
    span: {color: 'inherit'},
    svg: {
      color: 'inherit',
      margin: 0,
    },
  },
}));

export function Button(
  {
    className,
    testId,
    navPrimary,
    navMain,
    navStandard,
    navMinor,
    navNegative,
    navPositive,
    navUnstyled,
    actionPrimary,
    actionStandard,
    actionInline,
    unstyled,
    danger,
    caps,
    disabled,
    fillWidth,
    icon,
    inplaceEdit,
    loading,
    menu,
    name,
    noChevron,
    placement,
    prefix,
    purpose,
    slideText,
    split,
    splitDisabled,
    splitEnabled,
    style,
    suffix,
    tabIndex,
    text,
    wrap,
    onClick,
    onShownChange,
    onSplitClick,
    children,
  }
) {
  const buttonRef = useRef(null);
  const textContainerRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [slideWidth, setSlideWidth] = useState(0);
  const {rippleStyles, rippleClasses} = useRippleEffect(buttonRef.current);

  if (typeof children === 'string') {
    text = children;
    children = undefined;
  }

  splitDisabled = splitDisabled ?? (disabled && !splitEnabled);

  const handleClick = useCallback((e) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // Only handle the click if it is on this button
    if (buttonRef.current?.contains(e.target)) {
      if (!split) {
        setMenuOpen((prevOpen) => !prevOpen);
      } else {
        setMenuOpen(false);
      }
      onClick?.(e);
    }
  }, [disabled, onClick, split]);

  const handleSplitClick = useCallback((e) => {
    if (splitDisabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    e.stopPropagation();
    if (menu) {
      setMenuOpen((prevOpen) => !prevOpen);
    } else {
      onSplitClick(e);
    }
  }, [menu, onSplitClick, splitDisabled]);

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  useLayoutEffect(() => {
    if (slideText && textContainerRef.current && textContainerRef.current.offsetWidth > slideWidth) {
      setSlideWidth(textContainerRef.current.offsetWidth);
    }
  }, [slideText, slideWidth]);

  const handleMouseDown = useCallback((e) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [disabled]);

  if ((split || menu) && !suffix && !noChevron) {
    suffix = DownArrowIcon;
  }

  className = classNames([
    className,
    'button',
    navPrimary && 'nav-primary nav-button',
    navMain && 'nav-main nav-button',
    navStandard && 'nav-standard nav-button',
    navMinor && 'nav-minor nav-button',
    navNegative && 'nav-negative nav-button',
    navPositive && 'nav-positive nav-button',
    navUnstyled && 'nav-button',
    actionPrimary && 'action-primary action-button',
    actionStandard && 'action-standard action-button',
    actionInline && 'action-inline inline-action-button',
    (unstyled || navUnstyled) && 'unstyled',
    danger && 'danger',
    caps && 'all-caps',
    caps != null && !caps && 'no-caps',
    disabled && 'disabled',
    fillWidth && 'fill-width',
    icon && 'icon-button',
    menu && 'menu',
    menu && menuOpen && 'menu-open',
    inplaceEdit && 'inplace-edit',
    prefix && !text && !children && !suffix && 'icon',
    purpose,
    slideText && 'slide-text',
    split && 'split',
    wrap && 'button-wrap',
    rippleClasses,
  ]);

  return (
    <button
      className={className}
      id={testId}
      data-text={text}
      ref={buttonRef}
      disabled={loading || (disabled && splitDisabled)}
      name={name}
      tabIndex={disabled ? '-1' : tabIndex}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      {...(style || rippleStyles ? {style: {...style ?? {}, ...rippleStyles ?? {}}} : {})}
    >
      {loading && <BusySpinner />}
      {prefix && !loading &&
        <Decorator>{prefix}</Decorator>
      }
      {text && !prefix && !loading && !suffix && !children && text}
      {text && (prefix || loading || suffix || children) && !slideText &&
        <div className={'button-text'}>
          {text}
        </div>
      }
      {text && (prefix || suffix || children || loading) && slideText &&
        <div className={'button-text button-slider'} style={{'--slideWidth': `${slideWidth}px`}}>
          <div ref={textContainerRef} className={slideWidth > 4 ? '' : 'not-ready'}>
            {text}
          </div>
        </div>
      }
      {!split && suffix &&
        <Decorator>{suffix}</Decorator>
      }
      {split &&
        <div className={`split-button${splitDisabled ? ' disabled' : ''}`} onClick={handleSplitClick} tabIndex={splitDisabled ? '-1' : undefined}>
          <Decorator>{suffix}</Decorator>
        </div>
      }
      {!menu && children}
      {icon && !text && !children &&
        <div className={'icon-button-spacer'}>{'\u00A0'}</div>
      }
      {menu && buttonRef.current &&
        <PopupMenu
          className={['split-button-menu', `${className.split(' ')[0]}-menu`]}
          anchorEl={buttonRef.current}
          open={menuOpen}
          placement={placement}
          onClose={handleCloseMenu}
          onShownChange={onShownChange}
        >
          {children}
        </PopupMenu>
      }
    </button>
  );
}

Button.propTypes = {
  className: HoopsPropTypes.className,
  testId: HoopsPropTypes.string,
  navPrimary: HoopsPropTypes.bool,
  navMain: HoopsPropTypes.bool,
  navStandard: HoopsPropTypes.bool,
  navMinor: HoopsPropTypes.bool,
  navNegative: HoopsPropTypes.bool,
  navPositive: HoopsPropTypes.bool,
  navUnstyled: HoopsPropTypes.bool,
  actionPrimary: HoopsPropTypes.bool,
  actionStandard: HoopsPropTypes.bool,
  actionInline: HoopsPropTypes.bool,
  unstyled: HoopsPropTypes.bool,
  danger: HoopsPropTypes.bool,
  caps: HoopsPropTypes.bool,
  disabled: HoopsPropTypes.bool,
  fillWidth: HoopsPropTypes.bool,
  icon: HoopsPropTypes.bool,
  inplaceEdit: HoopsPropTypes.bool,
  loading: HoopsPropTypes.bool,
  menu: HoopsPropTypes.bool,
  name: HoopsPropTypes.string,
  noChevron: HoopsPropTypes.bool,
  placement: HoopsPropTypes.string,
  prefix: HoopsPropTypes.decorator,
  slideText: HoopsPropTypes.bool,
  split: HoopsPropTypes.bool,
  splitDisabled: HoopsPropTypes.bool,
  splitEnabled: HoopsPropTypes.bool,
  style: HoopsPropTypes.object,
  suffix: HoopsPropTypes.decorator,
  tabIndex: HoopsPropTypes.number,
  text: HoopsPropTypes.string,
  wrap: HoopsPropTypes.bool,
  onClick: HoopsPropTypes.func,
  onShownChange: HoopsPropTypes.func,
  onSplitClick: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
