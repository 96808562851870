import React from 'react';
import {Switch} from 'react-router-dom';
import {useMountEffect} from '../../../hooks';
import {ErrorBoundaryRoute} from '../../../componentsHoops/Errors';
import {SalesBoardPage, SalesDocBuilderPage, SalesDocGuestPage} from '../index';

export function SalesDocsRoutes() {
  useMountEffect(() => {
    document.title = 'Jobs | Hoops';
  });

  return (
    <Switch>
      <ErrorBoundaryRoute exact path={['/sales/jobs/:jobIdOrNumber', '/sales/jobs/:jobIdOrNumber/:tabName']}>
        <SalesBoardPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute exact path='/sales/view/:salesDocId'>
        <SalesDocGuestPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute exact path={[
        '/sales/new',
        '/sales/new/:templateId',
        '/sales/edit/:salesDocNumber',
        '/sales/edit/:salesDocNumber/revision/:revisionId',
        '/sales/draft/:draftId',
        '/sales/:action/:salesDocNumber',
      ]}>
        <SalesDocBuilderPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute exact path={['/sales', '/sales/:salesDocNumber', '/sales/:salesDocId']}>
        <SalesBoardPage/>
      </ErrorBoundaryRoute>
    </Switch>
  );
}
