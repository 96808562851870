// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Divider, MuiThemeProvider, Paper, Switch, Tooltip} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import {get} from 'lodash';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {change, Field, getFormValues, reduxForm, reset} from 'redux-form';
import {snackError} from '../../actions/action-types';
import {openPOShipTo} from '../../actions/modals';
import {createPo, getPurchaseOrder, resetFormPo, updatePo} from '../../actions/purchaseOrders';
import {datePickerField, renderTextField, UserAutocompleteField} from '../shared/formHelpers/fields';
import VendorAutocomplete from '../vendors/VendorAutocomplete';
import TaxService from '../../modules/tax/TaxService';
import {editEntity} from '../../ui/theme/editEntity';
import green from '../../ui/theme/green';
import {isSameDay} from '../../utils/isSameDay';
import EntityListHero from '../EntityList/EntityListHero';
import {AddressOneLine} from '../shared/formHelpers/AddressOneLine';
import {required} from '../shared/formHelpers/validation';
import PurchaseOrderTable from './SinglePurchaseOrder/PurchaseOrderTable';
import {getLocalStorageItem, putLocalStorageItem} from '../../utils';
import {useHistory, useParams} from 'react-router';

export const purchaseOrderForm = 'purchaseOrderForm';

const useStyles = makeStyles((theme) => ({
  ...editEntity,
  toggleVendorButtom: {marginTop: theme.spacing(2)}
}));

const PO_SHOW_CUSTOMER_DETAILS_LOCALSTORAGE_KEY = 'po_showCustomerDetails';

export const PurchaseOrderEditPage = ({handleSubmit}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(getLocalStorageItem(PO_SHOW_CUSTOMER_DETAILS_LOCALSTORAGE_KEY));
  const [inheritDeadline, setInheritDeadline] = useState(true);
  const company = useSelector((state) => state?.authReducer.userData.company);
  const user = useSelector((state) => state?.authReducer.userData);
  const poFormValues = useSelector((state) => getFormValues(purchaseOrderForm)(state));
  const job = useSelector((state) => state?.jobsReducer.activeJob);
  const {orderId} = useParams();

  const initTaxes = useCallback(() => {
    const defaultValue = TaxService.getExpenseDefaultTax(company);
    if (company && (!poFormValues || !poFormValues._id)) {
      dispatch(change('items', [{
        tax: defaultValue || {},
        files: [],
      }]));
    }
  }, [company, dispatch, poFormValues]);

  const initShipTo = useCallback(() => {
    if ((company && !poFormValues) || (!poFormValues._id)) {
      dispatch(change('shipTo', {
        name: get(company, 'name'),
        attention: get(user, 'fullName'),
        email: get(company, 'email'),
        phone: get(company, 'phone'),
        address: get(company, 'shippingAddress', {})
      }));
    }
  }, [company, dispatch, poFormValues, user]);

  useEffect(() => {
    if (orderId) {
      dispatch(getPurchaseOrder(orderId));
    } else {
      if (!get(job, '_id')) {
        initTaxes();
        initShipTo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const submit = (values) => {
    if (orderId) {
      dispatch(updatePo(orderId, values, (err, po) => {
        if (err) {
          handleError(err);
        } else {
          handleSuccess(po);
        }
      }));
      return;
    }
    dispatch(createPo(values, {jobId: job._id}, (err, po) => {
      if (err) {
        handleError(err);
      } else {
        handleSuccess(po);
      }
    }));
  };
  const handleError = (err) => {
    dispatch(snackError(err.message));
  };

  const handleSuccess = () => {
    dispatch(reset(purchaseOrderForm));
    dispatch(resetFormPo());
    if (job && job._id) {
      history.push(`/jobs/${job._id}/classic-purchase-orders`);
    } else {
      history.push('/classic-purchase-orders');
    }
    dispatch(reset('PoUploadForm'));
  };

  const handleEditShipTo = () => {
    dispatch(openPOShipTo(poFormValues?.shipTo, true));
  };

  const handleShowDetails = () => {
    const newStatus = !showDetails;
    if (newStatus) {
      putLocalStorageItem(PO_SHOW_CUSTOMER_DETAILS_LOCALSTORAGE_KEY, 'show');
    } else {
      putLocalStorageItem(PO_SHOW_CUSTOMER_DETAILS_LOCALSTORAGE_KEY, '');
    }
    setShowDetails(newStatus);
  };

  const shipTo = get(poFormValues, 'shipTo', {});
  const shippingAddress = get(shipTo, 'address.formattedAddress', '');
  const purchaseOrderNumber = get(poFormValues, 'number', '');
  const fromJob = !!job._id;
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container>
        <EntityListHero
          title={purchaseOrderNumber ? `Purchase Order ${purchaseOrderNumber}` : 'New Purchase Order'}
          subtitle='Manage the items on your orders & track your purchases easily.'
          helpArticleUrl='http://help.hoopscrm.com/en/articles/4632984-creating-quotes'
          videoId='oJjG9FUEMUo'
        >

          <Button
            data-intercom-target={'purchase-order-create'}
            onClick={() => history.goBack()}
            className={`${classes.whiteButton} ${classes.buttons}`}
            disableElevation
            variant={'contained'}>Cancel</Button>

          <MuiThemeProvider theme={green}>
            <Button
              data-intercom-target={'purchase-order-save-send'}
              // disabled={invalid}
              type={'submit'}
              color={'primary'}
              className={classes.buttons}
              variant={'contained'}>Save</Button>
          </MuiThemeProvider>

        </EntityListHero>

        {/* from here */}

        <Grid container
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={3}
        >
          <Grid item xs={12} data-intercom-target={'purchase-order-table'}>
            <Paper>
              <Grid container className={classes.header}>
                {/* PO Title */}
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'
                  spacing={4}
                >

                  <Grid item xs={6}>
                    <Typography variant='caption' className={classes.labelText}>Vendor</Typography>
                    <Field
                      fullWidth
                      name={'vendor'}
                      component={VendorAutocomplete}
                      disabled={poFormValues && poFormValues.locked}
                      classes={{root: classes.inputStyles}}
                      textFieldProps={{
                        margin: 'dense',
                        variant: 'outlined',
                      }}
                      validate={required}
                    />
                  </Grid>
                  <Grid item xs={6} style={{paddingTop: 30}}>
                    <Button
                      onClick={handleShowDetails}
                      color='primary'
                      style={poFormValues && poFormValues.vendor ? null : {display: 'none'}}
                      className={classes.toggleVendorButtom}>
                      {showDetails ? 'Hide' : 'View'} billing/shipping information
                    </Button>
                  </Grid>
                  {poFormValues && user && company && showDetails &&
                    <>
                      <Grid container item xs={4} direction='row' wrap='nowrap'>
                        <Grid item style={{marginRight: 16}}>
                          <Avatar className={classes.primaryColor}>
                            <Icon>assignment_ind</Icon>
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Vendor:</Typography>
                          {poFormValues && poFormValues.vendor && (
                            <>
                              <Typography variant='body2' style={{fontWeight: 500}}
                                gutterBottom>{poFormValues.vendor.name}</Typography>
                              <AddressOneLine addressType={'BILLING'} addresses={poFormValues.vendor.addresses} />
                              <Typography variant='body2' gutterBottom>{poFormValues.vendor.email}</Typography>
                              <Typography variant='body2' gutterBottom>{poFormValues.vendor.phone}</Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container item xs={4} direction='column'>
                        <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Bill to:</Typography>
                        <Typography variant='body2' style={{fontWeight: 500}} gutterBottom>{company.name}</Typography>
                        <AddressOneLine addressType={'BILLING'} addresses={company.addresses} />
                        <Typography variant='body2' gutterBottom>{user.username}</Typography>
                        <Typography variant='body2' gutterBottom>{company.phone}</Typography>
                      </Grid>
                      <Grid container item xs={4} direction='column'>
                        <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                          <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Ship to:</Typography>
                          <Button size={'small'}
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => handleEditShipTo()}
                          >
                            Edit</Button>
                        </Grid>
                        <Typography variant='body2' style={{fontWeight: 500}} gutterBottom>{shipTo.name}</Typography>
                        {shipTo.attention &&
                          <Typography variant='body2' gutterBottom>Attn: {shipTo.attention}</Typography>}
                        <Typography variant='body2' gutterBottom>{shippingAddress}</Typography>
                        <Typography variant='body2' gutterBottom>{shipTo.email}</Typography>
                        <Typography variant='body2' gutterBottom>{shipTo.phone}</Typography>
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
              <Grid container className={classes.entityDetails}>

                <Grid item xs={12} md={fromJob ? 9 : 12}>
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <Typography variant='caption' className={classes.greyText}>Purchase Order Number</Typography>
                      <Typography variant='body1' className={classes.purchaseOrderInfo}> {(poFormValues && poFormValues.number ? <span>{poFormValues.number}</span> : <span>DRAFT</span>)}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant='caption' className={classes.greyText}>Date</Typography>
                      <Typography variant='body1' className={classes.purchaseOrderInfo}>{get(poFormValues, 'createdAt') ? moment(poFormValues.createdAt, 'x').format('DD MMM. YYYY') : '--'}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant='caption' className={classes.greyText}>Creator</Typography>
                      <Typography variant='body1' className={classes.purchaseOrderInfo}>{get(poFormValues, 'createdBy.fullName', '--')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant='caption' className={classes.greyText}>Purchase Order Owner</Typography>
                      <Field
                        name={'ownerId'}
                        component={UserAutocompleteField}
                        className={classes.inputStyles}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant='caption' className={classes.greyText}>Deadline</Typography>
                      <Field
                        data-intercom-target={'purchase-order-deadlineAt-field'}
                        name={'deadlineAt'}
                        component={datePickerField}
                        onChange={(date) => {
                          if (inheritDeadline && !isSameDay(date, job.deadlineAt)) {
                            setInheritDeadline(false);
                          }
                        }}
                        fullWidth
                        className={classes.inputStyles}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant='caption' className={classes.greyText}>Reference Number</Typography>
                      <Field
                        component={renderTextField}
                        name={'customReference'}
                        variant='outlined'
                        size='small'
                        fullWidth
                        className={classes.inputStyles}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={8}>
                    <Typography variant='caption' className={classes.greyText}>Purchase Order Title</Typography>
                    <Field
                      data-intercom-target={'job-title-field'}
                      component={renderTextField}
                      name={'title'}
                      variant='outlined'
                      size='small'
                      fullWidth
                      style={{paddingRight: 8}}
                      placeholder='Give the a descriptive title'
                    />
                  </Grid>
                </Grid>
                {fromJob &&
                  <Grid item xs={12} md={3}>
                    <Grid container>
                      <Grid item sm={0} md={3}>
                        <Divider orientation='vertical' light variant='fullWidth' className={classes.vertDivider} />
                      </Grid>
                      <Grid item sm={12} md={9}>
                        <Typography variant='h6'>Settings</Typography>
                        <Grid container alignItems='stretch'>
                          <Grid item style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 10,
                          }} sm={4} md={12}>

                            <Typography variant='body2' display='inline'>Inherit Deadline
                              <Tooltip
                                placement='top'
                                arrow
                                title={
                                  <Typography variant='caption' display='block'>
                                    The quote deadline will be added as the job deadline.
                                  </Typography>}
                              >
                                <InfoIcon fontSize='small' className={classes.icon} />
                              </Tooltip>
                            </Typography>
                            <Switch
                              checked={inheritDeadline}
                              color='primary'
                              onChange={(e, checked) => {
                                setInheritDeadline(checked);
                                let oldDeadlineAt = null;
                                if (checked) {
                                  if (job.deadlineAt) {
                                    change('deadlineAt', new Date(job.deadlineAt).toISOString());
                                  } else {
                                    change('deadlineAt', '');
                                  }
                                  oldDeadlineAt = poFormValues.deadlineAt;
                                } else {
                                  if (oldDeadlineAt) {
                                    change('deadlineAt', oldDeadlineAt);
                                  } else {
                                    change('deadlineAt', '');
                                  }
                                }
                              }}
                              name='inheritDeadline'
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />

                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid container
                direction='row'
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                className={classes.entityTable}
                spacing={4}
              >
                <Grid item xs={12}>
                  <PurchaseOrderTable />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: 'purchaseOrderForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: (values) => {
    if (!values) {
      return {};
    }
    const errors = {};
    if (!values.vendor) {
      errors.vendor = 'Vendor is required';
    }
    return errors;
  },
})(PurchaseOrderEditPage);

