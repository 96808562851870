import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Column, ExpansionButton, ExpansionPanel, Grid, GridRow, GridSpan, GridTitles, Row} from '../../../componentsLib/Layout';
import {BodyText, CaptionText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';
import {
  asCurrencyRateString,
  asCurrencyString,
  asCurrencyStringCommaSeparated,
  asNumber,
  byLocaleCaseInsensitive,
  bySizeComparison,
  inputNameFromEvent,
  ObjectMap
} from '../../../utils';
import {ImageGallery} from '../../../componentsLib/Basic/ImageGallery';
import {Button, CurrencyRateInput, IntegerInput, WysiwygText} from '../../../componentsLib/Basic';
import {PopupItem} from '../../../componentsLib/Popovers';
import {Select, SelectItem} from '../../../componentsLib/Pickers';
import {Alerts, PurchaseOrderAlert} from '../Components';
import {HoopsPropTypes} from '../../../componentsLib';

registerGlobalStyle('.purchase-order-view', (theme) => ({
  '.grid-titles': {position: 'relative'},
  '.inclusion-select': {paddingInline: theme.spacing(2)},
  '.purchase-order-alert': {
    position: 'absolute',
    transform: 'translateX(-100%)',
    marginTop: -2,
    '&:is(.grid-titles .purchase-order-alert)': {
      transform: 'translateX(-30%)',
      marginTop: 10,
    },
    '&:is(.purchase-order-header .purchase-order-alert)': {
      transform: 'translateX(-30%)',
      marginTop: 20,
    },
  },
  '.expansion-button': {
    '&:is(.variant-view > .expansion-button)': {
      position: 'absolute',
      transform: 'translateX(-100%)',
      alignSelf: 'center',
    }
  },
  '.job-number': {color: theme.colors.text.highlight},
  '.sub-items': {
    '.job-number': {paddingLeft: theme.spacing(2)},
    '&>.text': {
      fontStyle: 'italic',
      color: theme.colors.text.dark,
    },
    '&>.text-input input:not(:focus)': {
      fontStyle: 'italic',
      color: theme.colors.text.dark,
    },
  },
  '.description-row': {
    minHeight: 70,
    paddingBottom: theme.spacing(.5),
    '&>*': {alignSelf: 'end'},
    '&>.text': {fontWeight: theme.fontWeight.dark},
  },
  '.variant-view': {
    '.text-input': {
      '&.right': {justifySelf: 'end'},
      '.suffix': {
        position: 'absolute',
        opacity: 0,
        right: theme.spacing(-1),
        top: 0,
        fontSize: theme.fontSize(18),
        color: theme.colors.text.highlight,
        pointerEvents: 'none',
        transition: theme.transitions.out.opacity,
        zIndex: 1,
      },
      '&:hover .suffix': {
        opacity: 1,
        transition: theme.transitions.in.opacity,
      },
    },
    '.clearable:hover, .clearable:focus-within': {backgroundColor: theme.colors.palette.orangeMild},
  },
  '.purchase-order-header': {
    position: 'relative',
    alignItems: 'center',
    backgroundColor: theme.colors.background.empty,
    border: `1px solid ${theme.colors.border.light}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    '&>div': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      '.busy-spinner': {
        position: 'absolute',
        left: 0,
        width: 'min-content',
        transform: 'translateX(-100%)',
        marginLeft: theme.spacing(-1),
      },
    },
    '.expansion-button': {
      marginRight: theme.spacing(1),
      borderColor: theme.colors.palette.greyLighter,
      color: theme.colors.text.medium,
      '&:hover': {
        borderColor: theme.colors.border.highlight,
        color: theme.colors.text.highlight,
      },
    },
    '.text': {textWrap: 'nowrap'},
    'button:last-child': {justifySelf: 'end'},
    '.progress-bar': {
      position: 'absolute',
      width: '100%',
      bottom: 0,
    },
  },
  '&.purchase-order-expanding-view': {
    border: `1px solid ${theme.colors.border.light}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    '.purchase-order-header': {
      margin: `-${theme.spacing(2)} 0 0 -${theme.spacing(2)}`,
      width: `calc(100% + ${theme.spacing(4)})`,
      borderWidth: '0 0 1px 0',
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
    '&>.expansion-panel': {width: '100%'},
    '&.collapsed': {
      paddingBottom: 0,
      '.purchase-order-header': {
        borderWidth: 0,
        borderRadius: theme.shape.borderRadius,
      },
    },
  },
  '.purchase-order-purchasing-view': {
    width: '100%',
    gridTemplateColumns: '140px 400fr 185fr 185fr 130fr 130fr 160fr 160fr',
    rowGap: theme.spacing(2),
    '.description-row >.text:first-child': {gridColumn: 'span 3'},
    '.product-view': {
      rowGap: 2,
      columnGap: 2,
      paddingBottom: theme.spacing(2),
    },
    '.variants-grid': {
      gridColumn: '2 / -1',
      alignSelf: 'start',
      gridAutoRows: 'min-content',
    },
  },

// ===========================================================================
// THE REST IS OLD AND NEEDS UPDATING
  // width: 1000,
  '.purchase-order-group-view': {
    width: '100%',
    gridTemplateColumns: '140px 400fr 185fr 185fr 130fr 130fr 160fr 160fr',
    gridTemplateRows: 'auto minmax(70px,max-content)',
    rowGap: 2,
    columnGap: 2,
    '.grid-titles': {'&>.text:nth-child(n+5)': {textAlign: 'end'}},
    '.product-preview-image': {
      position: 'relative',
      '&>*': {position: 'absolute'},
    },
    '.product-preview-title': {
      alignSelf: 'end',
      gridColumn: 'span 3',
    },
    '.actions-button': {paddingBlock: '2px 0',},
    '.show-hide-variants-button': {
      display: 'flex',
      margin: 'auto -2px auto auto',
      color: theme.colors.text.mediumIcon,
    },
    '.sub-items': {
      gridColumn: '1 / -1',
      gridRow: 'span var(--row-count)',
      '.text': {
        fontStyle: 'italic',
        color: theme.colors.text.medium,
      },
      '.job-number': {
        color: theme.colors.text.highlight,
        marginLeft: theme.spacing(1),
      },
    },
    '.job-number': {color: theme.colors.text.highlight},

    '.variant-view-summary': {
      verticalAlign: 'bottom',
      '.image-gallery': {height: 70},
    },
  }
}));

const InclusionOptions = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
  NEVER: 'never',
};

export function PurchaseOrderView({purchaseOrder}) {
  const [groupByProduct, setGroupByProduct] = useState(true);

  const handleSwitchViewMode = useCallback(() => {
    setGroupByProduct((prev) => !prev);
  }, []);

  return (
    <Column className={'purchase-order-view'} paperOutline gap>
      <Button actionStandard text={'Switch view mode'} onClick={handleSwitchViewMode}/>
      {groupByProduct &&
        <PurchaseOrderPurchasingView purchaseOrder={purchaseOrder}/>
      }
      {!groupByProduct &&
        <PurchaseOrderAsJobView purchaseOrder={purchaseOrder}/>
      }
    </Column>
  );
}

export function PurchaseOrderVendorActionView({purchaseOrder}) {
  const [showPurchaseOrder, setShowPurchaseOrder] = useState(false);

  return (
    <PurchaseOrderExpandingView className={'purchase-order-vendor-action-view purchase-order-view'} expanded={showPurchaseOrder}>
      <PurchaseOrderActionBar purchaseOrder={purchaseOrder} showPurchaseOrder={showPurchaseOrder} onShowPurchaseOrderChange={setShowPurchaseOrder}/>
      <ExpansionPanel expanded={showPurchaseOrder}>
        <PurchaseOrderPurchasingView purchaseOrder={purchaseOrder}/>
      </ExpansionPanel>
    </PurchaseOrderExpandingView>
  );
}

export function PurchaseOrderPurchasingView({purchaseOrder}) {
  const [expandedVariants, setExpandedVariants] = useState([]);

  const {productRelatedItems} = useMemo(() => {
    const productItems = purchaseOrder
      .getRelatedProductIds()
      .map((relatedProductId) => ({relatedProductId, item: purchaseOrder.getFirstRelatedVariantItem({relatedProductId})}))
      .toSorted((a, b) => byLocaleCaseInsensitive(a.item.product?.title ?? a.item.name, b.item.product?.title ?? b.item.name))
      .map(({relatedProductId}) => {
        const items = purchaseOrder
          .getRelatedVariantItems({relatedProductId})
          .toSorted((a, b) => {
            if ((a.isDecoration() || a.isDecorationSetupCost()) && (b.isDecoration() || b.isDecorationSetupCost())) {
              const aDeco = a.isDecoration() ? a : a.getDecorationItem();
              const bDeco = b.isDecoration() ? b : b.getDecorationItem();

              if (a === bDeco) {
                return -1;
              } else if (b === aDeco) {
                return 1;
              }

              return byLocaleCaseInsensitive(aDeco.name || aDeco.description, bDeco.name || bDeco.description)
                || asNumber(a.jobNumber) - asNumber(b.jobNumber)
                || purchaseOrder.items.indexOf(aDeco) - purchaseOrder.items.indexOf(bDeco);
            } else if (a.isProductVariant() && b.isProductVariant()) {
              return byLocaleCaseInsensitive(a.name || a.description, b.name || b.description)
                || asNumber(a.jobNumber) - asNumber(b.jobNumber)
                || bySizeComparison(a.size, b.size)
                || byLocaleCaseInsensitive(a.color ?? '', b.color ?? '')
                || purchaseOrder.items.indexOf(a) - purchaseOrder.items.indexOf(b);
            } else if (a.type === b.type) {
              return byLocaleCaseInsensitive(a.name || a.description, b.name || b.description)
                || asNumber(a.jobNumber) - asNumber(b.jobNumber)
                || purchaseOrder.items.indexOf(a) - purchaseOrder.items.indexOf(b);
            } else if (a.isProductVariant()) {
              return -1;
            } else if (b.isProductVariant()) {
              return 1;
            } else if (a.isDecoration() || a.isDecorationSetupCost()) {
              return -1;
            } else if (b.isDecoration() || b.isDecorationSetupCost()) {
              return 1;
            } else {
              return 0;
            }
          });
        const relatedItems = items.reduce((map, item) => {
          const relatedVariantId = item.getRelatedVariantId();
          const relatedVariant = map.setInit(relatedVariantId, {relatedVariantId, item, variants: [], jobNumbers: [], priceMismatch: false});
          if (item.isProductVariant()) {
            relatedVariant.variants.push(item);
            if (asCurrencyRateString(item.buyPrice) !== asCurrencyRateString(relatedVariant.variants[0].buyPrice)) {
              relatedVariant.priceMismatch = true;
            }
          }
          if (!relatedVariant.jobNumbers.includes(item.jobNumber)) {
            relatedVariant.jobNumbers.push(item.jobNumber);
          }
          return map;
        }, new ObjectMap()).values();

        return {
          relatedProductId,
          productItems: items,
          relatedItems,
          image: relatedItems[0].item.image,
          name: `${relatedItems[0].item.code} - ${relatedItems[0].item.product?.title || relatedItems[0].item.name || relatedItems[0].item.description}`,
          priceMismatch: relatedItems.some((item) => item.priceMismatch),
        };
      });
    return {
      productRelatedItems: productItems,
      priceMismatch: productItems.some((item) => item.priceMismatch),
    };
  }, [purchaseOrder]);

  useEffect(() => {
    const expand = [];
    productRelatedItems.forEach(({relatedItems}) => {
      relatedItems.forEach((relatedItem) => {
        if (relatedItem.priceMismatch) {
          expand.push(relatedItem.relatedVariantId);
        }
      });
    });
    if (expand.length > 0) {
      setExpandedVariants((prev) => [...prev, ...expand].toUniq());
    }
  }, [productRelatedItems]);

  const handleToggleShowVariants = useCallback((e) => {
    setExpandedVariants((prev) => {
      const variantId = inputNameFromEvent(e);
      const index = prev.indexOf(variantId);
      if (index === -1) {
        return [...prev, variantId];
      } else {
        return prev.toSpliced(index, 1);
      }
    });
  }, []);

  const handleChangeQuantity = useCallback((e, v) => {
    purchaseOrder.getItem(inputNameFromEvent(e))?.setQuantity(v);
  }, [purchaseOrder]);

  const handleChangeBuyPrice = useCallback((e, v) => {
    purchaseOrder.getItem(inputNameFromEvent(e))?.setBuyPrice(v);
  }, [purchaseOrder]);

  const handleClearBuyPrice = useCallback((e) => {
    purchaseOrder.getItem(inputNameFromEvent(e))?.setBuyPrice(null);
  }, [purchaseOrder]);

  return (
    <Grid className={['purchase-order-purchasing-view', !purchaseOrder.number && 'draft']}>
      {productRelatedItems.map((productRelatedItem) => (
        <GridRow key={productRelatedItem.relatedProductId} className={'product-view'} border>
          <GridTitles shaded border>
            {productRelatedItem.priceMismatch &&
              <PurchaseOrderAlert tip={Alerts.PRICE_MISMATCH} />
            }
            <CaptionText />
            <CaptionText text={'Item'} />
            <CaptionText />
            <CaptionText />
            <CaptionText text={'Quantity'} right />
            <CaptionText text={'Rate (Ex\u00A0Tax)'} right />
            <CaptionText text={'Total'} right />
            <CaptionText text={'Action'} right />
          </GridTitles>
          {productRelatedItem.image &&
            <ImageGallery images={[productRelatedItem.image]} clickForFullscreen />
          }
          <GridRow className={'variants-grid'}>
            <GridRow className={'description-row'}>
              <BodyText text={productRelatedItem.name} />
              <BodyText right text={purchaseOrder.summary.quantities[productRelatedItem.relatedProductId]} />
              <BodyText />
              <BodyText
                currency
                right
                text={purchaseOrder.summary.costs[productRelatedItem.relatedProductId] != null
                  && asCurrencyString(purchaseOrder.summary.costs[productRelatedItem.relatedProductId])}
              />
              <InclusionSelect items={productRelatedItem.productItems}/>
            </GridRow>
            <GridRow className={'variant-item-headings'}>
              <CaptionText text={'Job #'} />
              <CaptionText text={'Size'} />
              <CaptionText text={'Color'} />
              <CaptionText text={'Quantity'} right />
              <CaptionText text={'Rate (Ea)'} right />
              <CaptionText text={'Total'} right />
            </GridRow>
            {productRelatedItem.relatedItems.map((relatedItem) => (
              <Fragment key={relatedItem.relatedVariantId}>
                <GridRow className={'variant-view'}>
                  {relatedItem.variants.length > 1 && !relatedItem.priceMismatch &&
                    <ExpansionButton
                      name={relatedItem.relatedVariantId}
                      disabled={relatedItem.priceMismatch}
                      expanded={expandedVariants.includes(relatedItem.relatedVariantId)}
                      onClick={handleToggleShowVariants}
                    />
                  }
                  {relatedItem.variants.length > 1 && relatedItem.priceMismatch &&
                    <PurchaseOrderAlert tip={Alerts.PRICE_MISMATCH} />
                  }
                  <BodyText className='job-number' text={relatedItem.jobNumbers?.join(' ') ?? ''} />
                  <BodyText text={relatedItem.item.size} />
                  <BodyText text={relatedItem.item.color} />
                  {relatedItem.variants.length > 1 &&
                    <BodyText right text={purchaseOrder.summary.quantities[relatedItem.relatedVariantId]} />
                  }
                  {relatedItem.variants.length === 1 &&
                    <IntegerInput
                      value={relatedItem.item.quantity}
                      name={relatedItem.item.itemId}
                      autoSize
                      inplaceEdit
                      right
                      suffix={'edit'}
                      onChange={handleChangeQuantity}
                    />
                  }
                  {(relatedItem.variants.length > 1 || relatedItem.item.forContext) &&
                    <BodyText currency right text={!relatedItem.item.forContext && asCurrencyString(relatedItem.item.buyPrice)} />
                  }
                  {relatedItem.variants.length === 1 && !relatedItem.item.forContext &&
                    <CurrencyRateInput
                      value={relatedItem.item.buyPrice}
                      clearable={relatedItem.item.buyPriceOverride}
                      name={relatedItem.item.itemId}
                      autoSize
                      inplaceEdit
                      right
                      suffix={'edit'}
                      onChange={handleChangeBuyPrice}
                      onClear={handleClearBuyPrice}
                    />
                  }
                  <BodyText
                    currency
                    right
                    text={relatedItem.item.forContext ? '' : asCurrencyString(purchaseOrder.summary.costs[relatedItem.relatedVariantId])}
                  />
                  <InclusionSelect items={relatedItem.variants}/>
                </GridRow>
                {relatedItem.variants.length > 1 &&
                  <ExpansionPanel
                    className={'variant-view sub-items subgrid subgrid-row'}
                    expanded={expandedVariants.includes(relatedItem.relatedVariantId) || relatedItem.priceMismatch}
                  >
                    {relatedItem.variants.map((variant) => (
                      <Fragment key={variant.itemId}>
                        <BodyText className='job-number' text={variant.jobNumber} />
                        <BodyText text={variant.size} />
                        <BodyText text={variant.color} />
                        <IntegerInput
                          value={variant.quantity}
                          name={variant.itemId}
                          autoSize
                          inplaceEdit
                          right
                          suffix={'edit'}
                          onChange={handleChangeQuantity}
                        />
                        <CurrencyRateInput
                          value={variant.buyPrice}
                          clearable={variant.buyPriceOverride}
                          name={variant.itemId}
                          autoSize
                          inplaceEdit
                          right
                          suffix={'edit'}
                          onChange={handleChangeBuyPrice}
                          onClear={handleClearBuyPrice}
                        />
                        <BodyText currency right text={relatedItem.item.forContext ? '' : asCurrencyString(variant.totalCost)} />
                        <InclusionSelect item={variant}/>
                      </Fragment>
                    ))}
                  </ExpansionPanel>
                }
              </Fragment>
            ))}
          </GridRow>
        </GridRow>
      ))}
    </Grid>
  );
}

export function PurchaseOrderExpandingView({className, expanded, children}) {
  return (
    <Column className={[className, 'purchase-order-expanding-view', expanded ? 'expanded' : 'collapsed']} gap>
      {children}
    </Column>
  );
}

PurchaseOrderExpandingView.propTypes = {
  className: HoopsPropTypes.className,
  expanded: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};

function PurchaseOrderActionBar({purchaseOrder, showPurchaseOrder, onShowPurchaseOrderChange}) {
  const priceMismatch = useMemo(() => purchaseOrder.getRelatedVariantIds().some((relatedVariantId) => {
      const items = purchaseOrder.getRelatedVariantItems({relatedVariantId});
      return items.some((item) => asCurrencyRateString(items[0].buyPrice) !== asCurrencyRateString(item.buyPrice));
    }), [purchaseOrder]);

  return (
    <Grid className={'purchase-order-header'} gridTemplateColumns={'min-content repeat(5, 1fr) max-content'}>
      {priceMismatch &&
        <PurchaseOrderAlert tip={Alerts.PRICE_MISMATCH}/>
      }
      <ExpansionButton expanded={showPurchaseOrder} onExpandedChange={onShowPurchaseOrderChange} unstyled/>
      <Row>
        <CaptionText text={'Vendor:'}/>
        <BodyText text={purchaseOrder.vendor.name}/>
      </Row>
      <Row>
        <CaptionText text={'Total Units:'}/>
        <BodyText text={`${purchaseOrder.summary.totalQuantity}`}/>
      </Row>
      <Row>
        <CaptionText text={'Value:'}/>
        <BodyText currency text={asCurrencyStringCommaSeparated(purchaseOrder.subTotal)}/>
      </Row>
      <Row>
        <CaptionText text={'Template:'}/>
        <Select>
          <SelectItem>Item 1</SelectItem>
          <SelectItem>Item 1</SelectItem>
        </Select>
      </Row>
      <Button className={'action-button'} navMain split menu text={'Create PO'}>
        <PopupItem>Item 1</PopupItem>
      </Button>
    </Grid>
  );
}

const InclusionNameMap = {
  list: {
    [null]: ' ',
    [InclusionOptions.INCLUDE]: 'Include All',
    [InclusionOptions.EXCLUDE]: 'Exclude All',
    [InclusionOptions.NEVER]: 'Never All',
  },
  single: {
    [null]: ' ',
    [InclusionOptions.INCLUDE]: 'Include',
    [InclusionOptions.EXCLUDE]: 'Exclude',
    [InclusionOptions.NEVER]: 'Never',
  },
};

function InclusionSelect({item, items}) {
  let value = item
    ? item?.inclusion ?? InclusionOptions.INCLUDE
    : items?.reduce((v, {inclusion}) => v === (inclusion ?? InclusionOptions.INCLUDE) ? v : null, items[0]?.inclusion ?? InclusionOptions.INCLUDE);

  const handleSetInclude = useCallback((_, newValue) => {
    if (item) {
      item.setInclusion(newValue);
    } else if (items) {
      items.forEach((i) => i.setInclusion(newValue));
    }
  }, [item, items]);

  const inclusionNameMap = InclusionNameMap[items?.length > 1 ? 'list' : 'single'];
  return (
    <div className={'inclusion-select'}>
      <Button navMain text={inclusionNameMap[value]} inplaceEdit fillWidth menu>
        <PopupItem value={InclusionOptions.INCLUDE} text={inclusionNameMap[InclusionOptions.INCLUDE]} onClick={handleSetInclude} />
        <PopupItem value={InclusionOptions.EXCLUDE} text={inclusionNameMap[InclusionOptions.EXCLUDE]} onClick={handleSetInclude} />
        <PopupItem value={InclusionOptions.NEVER} text={inclusionNameMap[InclusionOptions.NEVER]} onClick={handleSetInclude} />
      </Button>
    </div>
  );
}

// ===========================================================================
// THE REST IS OLD AND NEEDS UPDATING
export function PurchaseOrderAsJobView({purchaseOrder}) {

  return (
    <>
      {purchaseOrder.getGroupIds().map((groupId) => (
        <PurchaseOrderGroupView key={groupId}>
          {purchaseOrder.getProductVariantIdsInGroup(groupId).map((variantId) => (
            <PurchaseOrderVariantItemsView key={variantId} purchaseOrder={purchaseOrder} groupId={groupId} variantId={variantId} />
          ))}
          {purchaseOrder.getNonProductVariantsInGroup(groupId).map((item) => (
            <PurchaseOrderItemView key={item.itemId} item={item} />
          ))}
        </PurchaseOrderGroupView>
      ))}
    </>
  );
}

function PurchaseOrderGroupView({children}) {
  return (
    <Grid className={'purchase-order-group-view'} border>
      <GridTitles shaded border titles={['', 'Item', '', '', 'Quantity', 'Rate (Ex\u00A0Tax)', 'Total', 'Status']}/>
      {children}
    </Grid>
  );
}

function PurchaseOrderVariantItemsView({purchaseOrder, variantId}) {

  const firstVariantItem = purchaseOrder.getFirstVariantItem(variantId);

  return (
    <>
      <PurchaseOrderVariantItemTitle name={firstVariantItem.name} image={firstVariantItem.image}/>
      <PurchaseOrderVariantHeader/>
      {purchaseOrder.getVariantItems(variantId).map((item) => (
        <VariantItemView key={item.itemId} item={item}/>
      ))}
    </>
  );
}

function VariantItemView({hasItems, showItems, onShowItemsChanged}) {
  return (
    <>
      {hasItems &&
        <Button
          className={'show-hide-variants-button'}
          actionStandard
          prefix={showItems ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          onClick={onShowItemsChanged}
        />
      }
      {!hasItems &&
        <div/>
      }
      <ActionsButton defaultAction={'Ordered'}/>
    </>
  );
}

// function ProductGroupingSummaryView({summary}) {
//   const [showItems, setShowItems] = useState(false);
//
//   const handleShowItemsChanged = useCallback(() => {
//     setShowItems((prev) => !prev);
//   }, []);
//
//   return (
//     <>
//       <VariantItemView item={summary} hasItems={summary.items.length > 1} showItems={showItems} onShowItemsChanged={handleShowItemsChanged}/>
//       {summary.items.length > 1 &&
//         <ExpansionPanel className={'sub-items subgrid subgrid-row'} style={{'--row-count': summary.items.length}} expanded={showItems}>
//           {/*<VariantItemView item={summary.items[0]}/>*/}
//           {summary.items.map((item) => (
//               <VariantItemView key={item.itemId} item={item}/>
//             ))}
//         </ExpansionPanel>
//       }
//     </>
//   );
// }

export function PurchaseOrderItemView({item}) {
  return (
    <>
      <div>
        {item.image &&
          <ImageGallery images={[item.image]} clickForFullscreen />
        }
      </div>
      <GridSpan colSpan={4}>
        <WysiwygText text={item.description}/>
      </GridSpan>
      <BodyText currency right text={asCurrencyString(item.buyPrice)}/>
      <BodyText currency right text={asCurrencyString(item.totalCost)}/>
      <ActionsButton defaultAction={'Ordered'}/>
    </>
  );
}

function PurchaseOrderVariantItemTitle({image, name}) {
  return (
    <>
      {image &&
        <ImageGallery className={'product-preview-image'} images={[image]} clickForFullscreen />
      }
      <BodyText className={'product-preview-title'} dark text={name}/>
      <GridSpan colFill/>
    </>
  );
}

function PurchaseOrderVariantHeader() {
  return (
    <>
      <CaptionText />
      <CaptionText text={'Job'}/>
      <CaptionText text={'Size'} />
      <CaptionText text={'Color'} />
      <CaptionText right text={'Quantity'} />
      <CaptionText right text={'Rate (Ea)'} />
      <CaptionText right text={'Total'} />
      <CaptionText />
    </>
  );
}

function ActionsButton({defaultAction}) {
  return (
    <Button className={'actions-button'} split menu navMain text={defaultAction}>
      <PopupItem>Item 1</PopupItem>
      <PopupItem>Item 2</PopupItem>
      <PopupItem>Return to Queue</PopupItem>
    </Button>
  );
}
