import React from 'react';
import {Switch} from 'react-router-dom';
import {useMountEffect} from '../../../hooks';
import {ErrorBoundaryRoute} from '../../../componentsHoops/Errors';
import JobEditPage from '../../../componentsOld/jobs/JobEditPage';
import {ClassicJobBoardPage} from '../../../pages/Job';

export function JobsLegacyRoutes() {
  useMountEffect(() => {
    document.title = 'Jobs | Hoops';
  });

  return (
    <Switch>
      <ErrorBoundaryRoute path='/jobs/new' exact>
        <JobEditPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path={['/jobs', '/jobs/sales/:salesDocNumber']} exact>
        <ClassicJobBoardPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path='/jobs/:_id/edit' exact>
        <JobEditPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path='/jobs/:jobId'>
        <ClassicJobBoardPage/>
      </ErrorBoundaryRoute>
    </Switch>
  );
}
