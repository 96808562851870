import React, {useCallback, useMemo, useState} from 'react';
import {useCompany} from '../../../hooks';
import {Column, ExpansionButton, ExpansionPanel, Grid, Page, usePageContext} from '../../../componentsLib/Layout';
import {usePurchasingQueueState} from '../State';
import {BodyText, CaptionText, HeadingText} from '../../../componentsLib/Text';
import {BusySpinner} from '../../../componentsLib/Basic';
import {asCurrencyStringCommaSeparated} from '../../../utils';
import {PurchaseOrderExpandingView, PurchaseOrderPurchasingView} from '../Views';
import {HoopsPropTypes} from '../../../componentsLib';
import {ProgressBar} from '../../../componentsLib/Progress';

export function PurchasingQueuePage() {
  const queueState = usePurchasingQueueState();
  const {isLoading, vendors} = queueState;
  const {company} = useCompany();

  const context = useMemo(() => ({
    company,
    ...queueState,
  }), [company, queueState]);

  return (
    <Page testId={'purchasing-queue-page'} className={'purchasing-queue-page'} context={context} pad title={'Purchasing Queue'}>
      {isLoading &&
        <BusySpinner/>
      }
      {!isLoading &&
        <Column className={'purchase-order-view'} gap>
          <HeadingText x20 dark text={'Needs to be added to a PO'}/>
          {vendors.keys().map((vendorId) => (
            <PurchaseOrderQueueView
              key={vendorId}
              vendorId={vendorId}
            />
          ))}
        </Column>
      }
    </Page>
  );
}

function PurchaseOrderQueueView({vendorId}) {
  const {loadJobs, vendors} = usePageContext();
  const [expanded, setExpanded] = useState(false);

  const handleExpandedChange = useCallback((_expanded) => {
    setExpanded(_expanded);
    if (_expanded) {
      loadJobs(vendorId);
    }
  }, [loadJobs, vendorId]);

  const vendorInfo = vendors[vendorId];

  return (
    <PurchaseOrderExpandingView className={'purchase-order-view'} expanded={expanded}>
      <PurchaseOrderHeader expanded={expanded} onExpandedChange={handleExpandedChange}>
        <div>
          <CaptionText text={'Vendor:'}/>
          <BodyText text={vendorInfo.vendorName}/>
        </div>
        <div>
          <CaptionText text={'Total Units:'}/>
          <BodyText text={`${vendorInfo.quantity}`}/>
        </div>
        <div>
          <CaptionText text={'Value:'}/>
          {vendorInfo.draftPurchaseOrder && vendorInfo.loadedJobsCount === vendorInfo.jobIds.length &&
            <BodyText
              currency
              text={asCurrencyStringCommaSeparated(vendorInfo.draftPurchaseOrder.subTotal)}
            />
          }
          {(!vendorInfo.draftPurchaseOrder || vendorInfo.loadedJobsCount !== vendorInfo.jobIds.length) &&
            <>
              <BodyText
                currency
                text={asCurrencyStringCommaSeparated(vendorInfo.totalCost)}
              />
              <CaptionText>&nbsp;(Estimated)</CaptionText>
            </>
          }
        </div>
        <div>
          {vendorInfo.isLoadingJobs &&
            <BusySpinner/>
          }
          <CaptionText text={'Jobs:'}/>
          {vendorInfo.loadedJobsCount > 0 && vendorInfo.loadedJobsCount < vendorInfo.jobIds.length &&
            <BodyText text={`${vendorInfo.loadedJobsCount} of ${vendorInfo.jobIds.length}`}/>
          }
          {(vendorInfo.loadedJobsCount === 0 || vendorInfo.loadedJobsCount === vendorInfo.jobIds.length) &&
            <BodyText text={`${vendorInfo.jobIds.length}`}/>
          }
        </div>
        {vendorInfo.isLoadingJobs &&
          <ProgressBar/>
        }
      </PurchaseOrderHeader>
      <ExpansionPanel expanded={expanded}>
        {vendorInfo.draftPurchaseOrder &&
          <PurchaseOrderPurchasingView purchaseOrder={vendorInfo.draftPurchaseOrder}/>
        }
      </ExpansionPanel>
    </PurchaseOrderExpandingView>
  );
}

function PurchaseOrderHeader({expanded, onExpandedChange, children}) {

  return (
    <Grid className={'purchase-order-header'} gridTemplateColumns={'min-content repeat(4, 1fr)'}>
      <ExpansionButton expanded={expanded} unstyled onExpandedChange={onExpandedChange}/>
      {children}
    </Grid>
  );
}

PurchaseOrderHeader.propTypes = {
  expanded: HoopsPropTypes.bool,
  onExpandedChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
