import {bind, listToMap} from '../utils';

export class Vendor {
  constructor(that) {
    if (that) {
      Object.assign(this, that);
    }
    bind(this);
  }

  static fromApi(apiVendor) {
    return new Vendor({...apiVendor,});
  }

  static fromApiMap(apiVendors) {
    if (!Array.isArray(apiVendors)) {
      apiVendors = [apiVendors];
    }
    return listToMap(apiVendors.map((vendor) => Vendor.fromApi(vendor)));
  }
}
