import {useCallback, useEffect, useMemo, useState} from 'react';
import {useFetchJobs, useGetPurchaseOrderQueue} from '../../../hooks/api';
import {byLocaleCaseInsensitive, ObjectMap} from '../../../utils';
import {SalesDoc} from '../../SalesDoc/Models/SalesDoc';
import {PurchaseOrder} from '../Models';
import {useUser} from '../../../hooks';

// The maximum number of jobs to request in one request
const MAX_JOBS_TO_REQUEST = 25;

export function usePurchasingQueueState() {
  const {data: {queue}, isLoading} = useGetPurchaseOrderQueue();
  const user = useUser();
  const [vendorLoadQueue, setVendorLoadQueue] = useState([]);
  const {query: fetchJobsApi, isLoading: isLoadingQueue} = useFetchJobs();
  const [draftPOs, setDraftPOs] = useState({drafts: new ObjectMap()});
  const [loadedJobs, setLoadedJobs] = useState({jobs: new ObjectMap(), applied: []});

  const drafts = draftPOs.drafts;
  const jobs = loadedJobs.jobs;

  const vendors = useMemo(() =>
      (queue ?? [])
        .toSorted((a, b) => byLocaleCaseInsensitive(a.vendorName, b.vendorName))
        .map(({_id, ...props}) => ({
          vendorId: _id,
          ...props,
          draftPurchaseOrder: draftPOs.drafts[_id],
          isLoadingJobs: vendorLoadQueue.includes(_id),
          loadedJobsCount: loadedJobs.jobs.keys().reduce((count, jobId) => props.jobIds.includes(jobId) ? count + 1 : count, 0),
        }))
        .toMappedBy('vendorId'),
    [draftPOs, loadedJobs, queue, vendorLoadQueue]);

  const loadJobs = useCallback((vendorId) => {
    const vendorInfo = queue.find((item) => item._id === vendorId);
    if (vendorInfo && vendorInfo.jobIds.some((jobId) => !jobs.has(jobId))) {
      setVendorLoadQueue((prev) => [...prev, vendorId].toUniqBy((a) => a));
    }
  }, [jobs, queue]);

  const notifyChangePO = useCallback((po) => setDraftPOs((prev) => {
    prev.drafts[po.vendorId] = po;
    return {...prev};
  }), []);

  // This effect triggers any time the vendor load queue is changed and will fetch the jobs
  useEffect(() => {
    (async () => {
      if (!isLoadingQueue && vendorLoadQueue.length > 0) {
        const vendorId = vendorLoadQueue[0];
        const vendor = vendors[vendorId];
        const jobIds = vendor?.jobIds.filter((jobId) => jobs[jobId] === undefined).slice(0, MAX_JOBS_TO_REQUEST);
        let salesDocs;
        if (jobIds?.length > 0) {
          const res = await fetchJobsApi({data: {ids: jobIds}});
          salesDocs = res.salesDocs;
          if (salesDocs?.length > 0) {
            salesDocs.forEach((salesDoc) => {
              jobs[salesDoc._id] = SalesDoc.fromApi(salesDoc);
            });
            setLoadedJobs((prev) => ({...prev}));
          }
        }
        if (!salesDocs?.length) {
          setVendorLoadQueue((prev) => {
            const pos = prev.indexOf(vendorId);
            return pos >= 0 ? prev.toSpliced(pos, 1) : prev;
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedJobs, vendorLoadQueue]);

  // This effect triggers any time the loaded jobs changes and will load new jobs into POs
  useEffect(() => {
    const applied = [];
    loadedJobs.jobs.values().forEach((job) => {
      if (!loadedJobs.applied.includes(job._id) && !applied.includes(job._id)) {
        applied.push(job._id);
        vendors.values().forEach((vendor) => {
          if (vendor.jobIds.includes(job._id)) {
            if (!drafts.has(vendor.vendorId)) {
              drafts[vendor.vendorId] = PurchaseOrder.fromJob(job, {vendorId: vendor.vendorId, owner: user}).setNotify(notifyChangePO);
            } else {
              drafts[vendor.vendorId] = drafts[vendor.vendorId].addJob(job);
            }
          }
        });
      }
    });
    if (applied.length > 0) {
      setDraftPOs((prev) => ({...prev}));
      setLoadedJobs((prev) => ({...prev, applied: [...prev.applied, ...applied]}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedJobs]);

  return useMemo(() => ({
    isLoading,
    jobs,
    loadJobs,
    vendors,
  }), [isLoading, jobs, loadJobs, vendors]);
}
