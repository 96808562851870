// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {get, pick} from 'lodash';
import {
  GQL_CREATE_JOB,
  GQL_CREATE_JOB_ITEM_VARIANT, GQL_DELETE_JOB, GQL_EMAIL_JOB, GQL_GET_JOB,
  GQL_GET_JOBS, GQL_GET_JOB_ITEM_VARIANT, GQL_UPDATE_JOB, GQL_UPDATE_JOB_ITEM, GQL_UPDATE_JOB_ITEM_VARIANT
} from '../queries/jobs';
import {GQL_BULK_CREATE_PROOF} from '../queries/proofs';
import EmailTemplateService from './EmailTemplateService';
import {client} from './GraphQLService';
import {toFloat} from './ItemBuilderServices';

export default class JobsService {
  static _parseJobInput(jobData) {
    const keys = [
      'number',
      'title',
      'jobOwnerId',
      'description',
      'status',
      'deadlineAt',
      'contactId',
      'customerId',
      'quoteId',
      'departmentalStatuses',
      'customReference',
      'inheritFiles',
      'inheritTask',
      'linkComments',
      'shipTo',
      'billTo',
    ];
    const job = pick(jobData, keys);
    const deadlineAt = job.deadlineAt ? job.deadlineAt : undefined;

    let items;
    if (jobData.items) {
      items = jobData.items
        .filter((item) => item.quantity)
        .map((item) => JobsService._parseJobItemInput(item));
    }

    return {
      ...job,
      deadlineAt,
      items
    };
  }

  static _parseProofInput({fileId, jobId, products = [], salesDocId}) {
    return {
      fileId: fileId,
      jobId: jobId,
      productIds: products.map((product) => product._id),
      salesDocId: salesDocId
    };
  }

  static _parseJobItemInput(jobItem) {
    const keys = [
      '_id',
      'imageUrl',
      'description',
      'status',
      'configuredProductId',
    ];

    let item = pick(jobItem, keys);
    item.productId = jobItem.product ? jobItem.product._id : undefined;

    const quantity = jobItem.quantity.toString();
    return {
      ...item,
      quantity,
      jobItemVariantId: (jobItem.jobItemVariant ? jobItem.jobItemVariant._id : null)
    };
  }

  static _parseJobItemVariantInput(variantInput) {
    let variants;
    let decorations;
    let additionalItems;

    if (variantInput.variants) {
      variants = variantInput.variants.map((variant) => {
        let result = pick(variant, [
          '_id',
          'productId',
          'productCode',
          'productName',
          'position',
          'colour',
          'size',
          'status',
          'buyPrice',
          'sellPrice',
          'productVariantId',
          'vendorId',
        ]);

        // Todo: this logic needs cleanup. Essentially we test of productVariantId is a MONGO reference and then change the size
        const productVariantId = get(variant, 'productVariant._id', null);
        if (productVariantId && productVariantId.length !== 24) {
          result.size = productVariantId;
          delete result.productVariantId;
        } else if (productVariantId) {
          result.productVariantId = productVariantId;
        } else {
          delete result.productVariantId;
        }

        return {
          ...result,
          productCode: variant.productCode ? variant.productCode : get(variantInput, 'product.code', null),
          productName: variant.productName ? variant.productName : get(variantInput, 'product.title', null),
          quantity: variant.quantity.toString(10),
          buyPrice: parseFloat(variant.buyPrice),
          sellPrice: parseFloat(variant.sellPrice),
          vendorId: variant.vendorId ? variant.vendorId : get(variantInput, 'product.vendor._id', null)
        };
      });
    }

    if (variantInput.decorations) {
      decorations = variantInput.decorations.map((decoration) => ({
        _id: decoration._id,
        description: decoration.description,
        decorationId: (decoration.decoration ? decoration.decoration._id : decoration.decorationId),
        vendorId: (decoration.vendor ? decoration.vendor._id : decoration.vendorId),
        buyPrice: parseFloat(decoration.buyPrice),
        sellPrice: parseFloat(decoration.sellPrice),
        setupPrice: parseFloat(decoration.setupPrice),
        colour: decoration.colour,
        size: decoration.size,
        position: decoration.position,
        quantity: toFloat(decoration.quantity)
      }));
    }

    if (variantInput.additionalItems) {
      additionalItems = variantInput.additionalItems.map((additionalItem) => ({
        _id: additionalItem._id,
        description: additionalItem.description ? additionalItem.description : additionalItem.name,
        vendorId: (additionalItem.vendor ? additionalItem.vendor._id : additionalItem.vendorId),
        buyPrice: parseFloat(additionalItem.buyPrice),
        sellPrice: parseFloat(additionalItem.sellPrice)
      }));
    }

    return {
      variants,
      decorations,
      additionalItems
    };
  }

  static getJobs({fetchPolicy = 'network-only', ...variables}) {
    return client.query({
      query: GQL_GET_JOBS,
      fetchPolicy,
      variables: {...variables}
    })
      .then(({data: {jobs}}) => jobs);
  }

  static getJob(id) {
    return client.query({
      query: GQL_GET_JOB,
      variables: {_id: id},
      fetchPolicy: 'no-cache',
    })
      .then(({data: {job}}) => job);
  }

  static createJob(jobData) {
    return client.mutate({
      mutation: GQL_CREATE_JOB,
      variables: {job: JobsService._parseJobInput(jobData)}
    })
      .then(({data: {createJob: job}}) => job);
  }

  static updateJob(_id, data) {
    const job = JobsService._parseJobInput(data);
    return client.mutate({
      mutation: GQL_UPDATE_JOB,
      variables: {
        _id,
        job
      }
    })
      .then(({data: {updateJob: job}}) => job);
  }

  static updateJobItem(_id, data) {
    return client.mutate({
      mutation: GQL_UPDATE_JOB_ITEM,
      variables: {
        _id,
        jobItem: JobsService._parseJobItemInput(data)
      }
    })
      .then(({data: {updateJobItem: job}}) => job);
  }

  static deleteJob(_id) {
    return client.mutate({
      mutation: GQL_DELETE_JOB,
      variables: {_id,}
    })
      .then(({data: {deleteJob: job}}) => job);
  }

  static createJobItemVariant(data) {
    return client.mutate({
      mutation: GQL_CREATE_JOB_ITEM_VARIANT,
      variables: {variant: this._parseJobItemVariantInput(data)}
    })
      .then(({data: {createJobItemVariant: jobItem}}) => jobItem);
  }
  static updateJobItemVariant(_id, data) {
    return client.mutate({
      mutation: GQL_UPDATE_JOB_ITEM_VARIANT,
      variables: {
        _id,
        variant: this._parseJobItemVariantInput(data)
      }
    })
      .then(({data: {updateJobItemVariant: jobItem}}) => jobItem);
  }
  static getJobItemVariant(_id, options) {
    return client.query({
      query: GQL_GET_JOB_ITEM_VARIANT,
      variables: {_id,},
      ...options
    })
      .then(({data: {jobItemVariant}}) => jobItemVariant);
  }

  static bulkCreateProof(data) {
    return client.mutate({
      mutation: GQL_BULK_CREATE_PROOF,
      variables: {data: data.map(JobsService._parseProofInput)}
    });
  }

  static emailJob(jobId, formValues) {
    return client.mutate({
      mutation: GQL_EMAIL_JOB,
      variables: {
        _id: jobId,
        ...(formValues.viaEmail && {emailOptions: EmailTemplateService.cleanEmailContent(formValues)}),
        ...(formValues.viaSms && {smsOptions: EmailTemplateService.cleanSmsContent(formValues)}),
      }
    });
  }
}

