import React from 'react';
import {Switch} from 'react-router-dom';
import {useMountEffect} from '../../../hooks';
import {ErrorBoundaryRoute} from '../../../componentsHoops/Errors';
import JobEditPage from '../../../componentsOld/jobs/JobEditPage';
import {ClassicJobBoardPage} from '../../../pages/Job';

export function JobsClassicRoutes() {
  useMountEffect(() => {
    document.title = 'Jobs | Hoops';
  });

  return (
    <Switch>
      <ErrorBoundaryRoute path='/classic-jobs/new' exact>
        <JobEditPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path={['/classic-jobs', '/classic-jobs/sales/:salesDocNumber']} exact>
        <ClassicJobBoardPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path='/classic-jobs/:_id/edit' exact>
        <JobEditPage/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute path='/classic-jobs/:jobId'>
        <ClassicJobBoardPage/>
      </ErrorBoundaryRoute>
    </Switch>
  );
}
