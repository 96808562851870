import {uniq} from 'lodash';
import {
  asCurrencyRateRounded,
  asCurrencyRounded,
  asNumber,
  bind,
  ObjectMap,
  unbind,
  valueFromEvent
} from '../../../utils';
import {PurchaseOrderSummary} from './PurchaseOrderSummary';
import {Decoration, ItemIdMap, ItemizedDocument, ItemizedDocumentItem, Product} from '../../../models';
import {BuyPriceCalculator} from './BuyPriceCalculator';

export class PurchaseOrder extends ItemizedDocument {
  static Type = {
    PURCHASE_ORDER: 'purchaseOrder',
    TEMPLATE: 'template',
  };

  _notify;
  draftId;
  summary;

  _id;
  documentType = PurchaseOrder.Type.PURCHASE_ORDER;

  docTypeName = 'Purchase Order';
  docTypeDescription;
  docTemplateName = '';
  docTemplateId = null;

  items;
  decorations;
  products;

  createdAt;
  createdBy;
  createdById;
  updatedAt;
  updatedBy;
  updatedById;

  billingAddress;
  company;
  companyId;
  companyTradingEntityId;
  contact;
  contactId;
  deadline;
  number;
  owner;
  ownerId;
  reference;
  rounding = 2;
  shippingAddress;
  title;
  vendor;
  vendorId;

  subTotal;
  taxTotal;
  total;

  template;

  static emptyTemplate = {
    additionalCostDescription: '{{name}}',
    decorationDescription: '{{name}}',
    productDescription: '{{name}}',
    statusAtCreateFieldName: undefined,
    statusAtCreateLabel: undefined,
    tax: null,
  };

  constructor(that) {
    super();

    if (that) {
      Object.assign(this, that);
      this.template = {
        ...PurchaseOrder.emptyTemplate,
        ...that.template,
      };
    }

    this.draftId = this.draftId ?? this._id ?? PurchaseOrder.newDraftId();

    this.decorations = this.decorations ?? new ObjectMap();
    this.products = this.products ?? new ObjectMap();
    this.summary = this.summary ?? new PurchaseOrderSummary();
    this.priceCalculator = this.priceCalculator ?? new BuyPriceCalculator();
    this.template = this.template ?? {...PurchaseOrder.emptyTemplate};
    this.vendor = this.vendor ?? {};

    const now = new Date();
    this.createdAt = this.createdAt ?? now;
    this.updatedAt = now;

    bind(this);

    if (!this.items || !Array.isArray(this.items)) {
      this.items = [];
    }

    // The items need to have a reference to the doc, so they update the list as needed
    this.items.forEach((item) => item.doc = this);
  }

  initUser(user) {
    this.createdBy = this.createdBy ?? user;
    this.createdById = this.createdById ?? user._id;
    this.updatedBy = this.updatedBy ?? user;
    this.updatedById = this.updatedById ?? user._id;
    this.owner = this.owner ?? user;
    this.ownerId = this.ownerId ?? user._id;
    return this;
  }

  // Return the PurchaseOrder in the format expected by the back end
  forApi() {
    const serialized = this.#serialize();

    Object.assign(serialized, {
      contact: this.contact && {
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        email: this.contact.email,
        phone: this.contact.phone,
      },
      owner: {
        firstName: this.owner.firstName,
        lastName: this.owner.lastName,
        username: this.owner.username,
      },
      decorations: uniq(this.items.map(({decoration, quantity}) => quantity && decoration).filter(Boolean)).map(({name, category}) => ({name, category})),
      products: uniq(this.items.map(({product, quantity}) => quantity && product).filter(Boolean)).map(({title, code, brand}) => ({title, code, brand})),
      items: serialized.items.filter(({type}) => !type.includes('Placeholder')),
    });

    return serialized;
  }

  // Convert the PurchaseOrder  from the back end format to what we use in the front end
  static fromApi(apiPurchaseOrder, {priceCalculator} = {}) {
    if (!apiPurchaseOrder) {
      return apiPurchaseOrder;
    }

    const newPurchaseOrder = new PurchaseOrder({
      ...apiPurchaseOrder,
      priceCalculator,
      decorations: {},
      products: {},
      summary: null,
      items: apiPurchaseOrder.items.map((item) => new PurchaseOrderItem({
        ...item,
        buyPrice: asCurrencyRateRounded(item.buyPrice),
        quantity: asNumber(item.quantity),
        totalCost: asCurrencyRateRounded(item.totalCost),
      }))
    })
      .addDecorationsFromApi(apiPurchaseOrder.decorations)
      .addProductsFromApi(apiPurchaseOrder.products);

    return newPurchaseOrder.recalculate();
  }

  static fromJob(job, {vendorId, owner}) {
    return new PurchaseOrder({
      companyTradingEntityId: job.companyTradingEntityId,
      owner: owner ?? job.owner,
      ownerId: owner?._id ?? job.ownerId,
      reference: job.reference,
      rounding: job.getRounding(),
      title: job.number
        ? `PO related to ${job.customer.name} - ${job.docTypeName} #${job.number} | Job ${job.jobNumber}`
        : `PO related to ${job.customer.name} - Job ${job.jobNumber}`,
      vendor: job.vendors[vendorId],
      vendorId,
    }).addJob(job);
  }

  addJob(job) {
    const items = job.items.filter((item) => item.vendorId === this.vendorId
      || (item.isProductVariant()
        && job.items.some((other) => item.groupId === other.groupId && other.vendorId === this.vendorId && (other.isDecoration() || other.isAdditionalCost())))
    );

    const idMap = new ItemIdMap();

    const products = [];
    const decorations = [];
    items.forEach((item) => {
      if (item.product && !this.products.has(item.product._id)) {
        products.push([item.product._id, item.product]);
      }
      if (item.decoration && !this.decorations.has(item.decoration._id)) {
        decorations.push([item.decoration._id, item.decoration]);
      }
    });

    return this.copyWith({
      deadline: this.deadline == null || (job.deadline != null && this.deadline.getTime() > job.deadline.getTime()) ? job.deadline : this.deadline,
      products: products.length > 0 ? new ObjectMap(this.products).insertBy(products, '_id') : this.products,
      decorations: decorations.length > 0 ? new ObjectMap(this.decorations).insertBy(decorations, '_id') : this.decorations,
      title: this.items.some((item) => item.jobId != null && item.jobId !== job._id) ? 'PO related to multiple Jobs' : this.title,
      items: [
        ...this.items,
        ...job.items
          .filter((item) => items.includes(item))
          .map((item) => new PurchaseOrderItem({
            type: item.type,
            itemId: idMap.map(item.itemId),
            groupId: idMap.map(item.groupId),
            variantId: idMap.map(item.variantId),
            jobId: job._id,
            jobItemId: item.itemId,
            jobNumber: job.jobNumber,

            buyPrice: item.buyPrice,
            buyPriceOverride: item.buyPriceOverride,
            code: item.code,
            color: item.color,
            decoration: item.decoration,
            decorationId: item.decorationId,
            description: item.description,
            forContext: item.vendorId !== this.vendorId,
            image: item.image,
            name: item.name,
            position: item.position,
            product: item.product,
            productId: item.productId,
            quantity: item.quantity,
            size: item.size,
            totalCost: asCurrencyRounded(item.buyPrice * item.quantity),
          }))
      ],
    });
  }

  #serialize() {
    return {
      ...unbind(this),
      _notify: undefined,
      draftId: undefined,
      summary: undefined,
      priceCalculator: undefined,
      defaultTax: undefined,
      decorations: undefined,
      products: undefined,
      vendor: undefined,

      contact: undefined,
      owner: undefined,

      createdBy: undefined,
      updatedBy: undefined,

      items: this.items.map((item) => ({
        ...unbind(item),
        // These fields are used by the front end in calculations, but are not part of the model
        doc: undefined,
        decoration: undefined,
        inclusion: undefined,
        isAveragedPrice: undefined,
        product: undefined,
      })),
    };
  }

  // Add a decoration or array of decorations from the back end
  addDecorationsFromApi(apiDecorations) {
    const decorationsMap = Decoration.fromApiMap(apiDecorations);
    return this.copyWith({
      decorations: {...this.decorations, ...decorationsMap},
      items: this.items.map((item) => decorationsMap[item.decorationId] ? item.copyWith({
        decoration: decorationsMap[item.decorationId],
        productId: decorationsMap[item.decorationId].productId,
        product: this.products[decorationsMap[item.decorationId].productId],
      }) : item),
    });
  }

  // Add a product or array of products from the back end
  addProductsFromApi(apiProducts) {
    const productsMap = Product.fromApiMap(apiProducts);
    return this.copyWith({
      products: {...this.products, ...productsMap},
      items: this.items.map((item) => productsMap[item.productId] ? item.copyWith({
        product: productsMap[item.productId],
        image: item.image ?? productsMap[item.productId]?.primaryImage,
      }) : item),
    });
  }

  recalculate() {
    if (this._pauseNotify) {
      return this;
    }

    const items = this.items.map((item) => item.copyWith({}));
    const summary = new PurchaseOrderSummary();
    summary.summarizeQuantities(items);
    this.priceCalculator.calculate({summary, items, rounding: this.getRounding()});
    summary.summarizePrices(items);
    summary.summarizeTotalsAndTaxes(items);

    return this
      .pauseNotify()
      .copyWith({items, summary, ...summary.totalsAndTaxes})
      .resumeNotify();
  }

  // Returns all items that are related to a product, or a product variant
  getFirstRelatedVariantItem({relatedProductId, relatedVariantId}) {
    if (relatedProductId) {
      return this.items.find((item) => relatedProductId === item.getRelatedProductId());
    } else if (relatedVariantId) {
      return this.items.find((item) => relatedVariantId === item.getRelatedVariantId());
    } else {
      return [];
    }
  }

  getRelatedProduct({relatedProductId, relatedVariantId}) {
    if (relatedProductId) {
      return this.items.find((item) => relatedProductId === item.getRelatedProductId())?.product;
    } else if (relatedVariantId) {
      return this.items.find((item) => relatedVariantId === item.getRelatedVariantId())?.product;
    } else {
      return [];
    }
  }

  // Returns a list of IDs that can be used to find all variants associated with the same product across jobs.
  // Products that have decorations associated with them do not relate to one another.
  getRelatedProductIds() {
    return this.items.map((item) => item.getRelatedProductId()).toUniq();
  }

  // Returns a list of IDs that can be used to find all variants that are related to the same variant of a product.
  getRelatedVariantIds() {
    return this.items.map((item) => item.getRelatedVariantId()).toUniq();
  }

  // Returns all items that are related to a product, or a product variant
  getRelatedVariantItems({relatedProductId, relatedVariantId}) {
    if (relatedProductId) {
      return this.items.filter((item) => relatedProductId === item.getRelatedProductId());
    } else if (relatedVariantId) {
      return this.items.filter((item) => relatedVariantId === item.getRelatedVariantId());
    } else {
      return [];
    }
  }

  getRounding() {
    return this.rounding;
  }
}

export class PurchaseOrderItem extends ItemizedDocumentItem {
  doc;

  type;
  itemId;
  groupId;
  variantId;

  jobId;
  jobItemId;
  jobNumber;

  buyPrice = 0;
  buyPriceOverride = false;
  code;
  color = null;
  decoration;
  decorationId;
  description = null;
  forContext;
  image;
  inclusion;
  isAveragedPrice;
  name;
  position = null;
  product;
  productId;
  quantity;
  size = null;
  tax;
  totalCost;

  constructor(that) {
    super();

    // For the most part, when we are copying another item we want to copy the IDs as well, simply
    // because we do so much copying to maintain immutability, but on occasion we need a new ID, in which
    // case, the object being copied must not have an ID.
    if (that) {
      Object.assign(this, that);
    }
    this.itemId = this.itemId ?? PurchaseOrder.newItemId();
    this.groupId = this.groupId ?? PurchaseOrder.newGroupId();

    bind(this);
  }

  getProductId() {
    if (!this.isProductVariant()) {
      return `pid:${this.itemId}`;
    } else if (this.product) {
      return `pid:${this.productId}`;
    } else {
      return `pid:${this.variantId}`;
    }
  }

  getRelatedProductId() {
    if (!this.isProductVariant()) {
      return `rpid:${this.itemId}`.toLowerCase();
    } else if (this.product?.decorations?.length) {
      return `rpid:${this.productId}:${this.groupId}`.toLowerCase();
    } else if (this.product) {
      return `rpid:${this.productId}`.toLowerCase();
    } else if (this.code) {
      return `rpid:code-${this.code}`.toLowerCase();
    } else {
      return `rpid:${this.variantId}`.toLowerCase();
    }
  }

  getRelatedVariantId() {
    if (!this.isProductVariant()) {
      return `rvid:${this.itemId}`.toLowerCase();
    } else if (this.product?.decorations?.length) {
      return `rvid:${this.productId}:${this.color || ''}:${this.size || ''}:${this.groupId}`.toLowerCase();
    } else if (this.product) {
      return `rvid:${this.productId}:${this.color || ''}:${this.size || ''}`.toLowerCase();
    } else if (this.code) {
      return `rvid:code-${this.code}:${this.color || ''}:${this.size || ''}`.toLowerCase();
    } else {
      return `rvid:${this.variantId}:${this.color || ''}:${this.size || ''}`.toLowerCase();
    }
  }

  setBuyPrice(...value) {
    value = valueFromEvent(...value);
    return this.doc.updateItem(this, {buyPrice: value, buyPriceOverride: value != null});
  }

  setInclusion(...value) {
    value = valueFromEvent(...value);
    return this.doc.replaceItem(this, this.copyWith({inclusion: value}));
  }

  setQuantity(...value) {
    return this.doc.updateItem(this, {quantity: valueFromEvent(...value)});
  }
}
