import {useCallback, useMemo} from 'react';
import {useGetPurchaseOrdersTypeNames, useListPurchaseOrders, useUpdatePurchaseOrder} from '../../../hooks/api';
import {useUpdateField} from '../../../componentsLib/PowerGrid';

export function usePurchaseOrderListState({customerId, filters, page, pageSize, params, search, sort}) {
  const query = useMemo(() => ({...(customerId ? {customerId} : {}),}), [customerId]);
  const {data: {purchaseOrderTypeNames}, isLoading: isDocTypeNamesLoading} = useGetPurchaseOrdersTypeNames();
  const {data: {purchaseOrders, total}, isLoading, refetch, updateCache}
    = useListPurchaseOrders({filters, page: page + 1, pageSize, query, search, sort, ...params});
  const {update: updatePurchaseOrderApi, isSaving} = useUpdatePurchaseOrder();
  const updateCachedRows = useCallback(({rows}) => updateCache({purchaseOrders: rows}), [updateCache]);
  const {updateField} = useUpdateField({
    entity: 'purchaseOrder',
    rows: purchaseOrders,
    refetchRows: refetch,
    updateCachedRows,
    updateApi: updatePurchaseOrderApi,
  });

  return useMemo(() => ({
    purchaseOrders: purchaseOrders?.map((row) => ({id: row._id, ...row})) ?? [],
    purchaseOrderTypeNames,
    isLoading: isLoading || isDocTypeNamesLoading,
    isSaving,
    refetch,
    updateField,
    total: total ?? 0,
  }), [purchaseOrders, purchaseOrderTypeNames, isLoading, isDocTypeNamesLoading, isSaving, refetch, updateField, total]);
}
