import {Avatar, Grid, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem} from '@material-ui/core';
import {PowerSettingsNew as LogoutIcon, Security as ChangePasswordIcon} from '@material-ui/icons';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../actions/action-types';
import ChangePasswordDialog from './ChangePasswordDialog';
import {UserEditModal} from './userDashboard/UserEditModal';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  menuWrapper: {padding: 0},
  greenAvatar: {
    backgroundColor: theme.colors.green,
    boxShadow: `0 0 0 4px ${theme.palette.secondary.light}`,
  },
  listItemIcon: {
    marginTop: 4,
    marginBottom: 4,
  },
  badgeContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '20px',
    width: '90px',
    borderRadius: '10px',
    color: theme.colors.white,
    fontSize: '12px'
  },
  navLink: {
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    outline: 'none',
    color: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '18px 26px 18px 18px',
    '&:is(.app-side-nav-collapsed .MuiList-root)': {paddingInline: '12px 0'},
    transition: '0.2s padding ease'
  },
}));

const ProfileDropdown = ({collapsed}) => {
  const classes = useStyles(collapsed);
  const dispatch = useDispatch();
  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openUserEdit, setOpenUserEdit] = useState(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const initials = () => {
    if (firstName && lastName) {
      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }
    return '';
  };

  const closeChangePasswordDialog = () => {
    setShowChangePasswordDialog(false);
  };

  const openChangePasswordDialog = () => {
    setShowChangePasswordDialog(true);
    handleClose();
  };

  const {firstName, lastName} = user;
  return (
    <div data-intercom-target={'profile-dropdown'} >
      <UserEditModal open={openUserEdit} user={user} onClose={() => setOpenUserEdit(false)} title='Edit Profile' />
      <List component={'nav'} className={classes.navLink}>
        <ListItem button style={{padding: 0, width: 'auto', backgroundColor: 'transparent'}} onClick={handleClick} >
          <ListItemIcon className={classes.listItemIcon}>
            <Avatar className={classes.greenAvatar}>{initials()}</Avatar>
          </ListItemIcon>
          <ListItemText
            primary={
              <Grid container justifyContent='space-between' alignItems='center' direction='row' style={{width: 150}}>
                <Grid item>
                  {firstName && <span>{firstName[0]}. {lastName}</span>}
                </Grid>
                <Grid item>
                  <IconButton onClick={handleClick}>
                    <Icon>keyboard_arrow_down</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            }
            secondaryTypographyProps={{className: classes.badgeContainer}}
          />

        </ListItem>
      </List>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuItem}
          onClick={() => setOpenUserEdit(true)}>
          <ListItemIcon className={classes.icon}>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.primary}}
            primary='Edit Profile'
          />
        </MenuItem>
        <MenuItem className={classes.menuItem}
          onClick={openChangePasswordDialog.bind(this)}>
          <ListItemIcon className={classes.icon}>
            <ChangePasswordIcon />
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.primary}}
            primary='Change Password'
          />
        </MenuItem>
        <MenuItem className={classes.menuItem}
          onClick={() => {
            dispatch(logout())
              .then(() => window.location.assign('/login'));
          }}>
          <ListItemIcon className={classes.icon}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.primary}}
            primary='Logout'
          />
        </MenuItem>
      </Menu>
      <ChangePasswordDialog open={showChangePasswordDialog} handleClose={closeChangePasswordDialog.bind(this)} />
    </div>
  );
};

export default ProfileDropdown;
