import {gql} from 'apollo-boost';

export const GQL_BULK_CREATE_PROOF = gql`
    mutation createProof($data: [ProofInput]!) {
        bulkCreateProof(data: $data) {
            _id
            createdBy {
                username
            }
            jobId
            job {
                number
            }
            salesDocId
            salesDoc {
                jobNumber
            }
            fileId
            file {
                filename
            }
            products {
                description
            }

        }
    }
`;

export const GQL_GET_PROOF = gql`
  query getProof($_id:String!) {
    proof(_id: $_id) {
      _id
      notes {
        content
        createdAt
          createdBy {
              fullName
          }
        assignee{
          _id
          firstName
          lastName
          fullName
        }
      }
    }
  }
`;

export const GQL_GET_JOB_PROOFS = gql`
    query getProofs($jobId:String!, $tokenId:String) {
        proofs(jobId: $jobId, tokenId: $tokenId) {
            _id
            jobId
            salesDocId
            fileId
            status
            createdAt
            products {
                description,
                quantity
                jobItemVariant {
                    variants {
                        colour,
                        size,
                        quantity
                    }
                }
            }
            file {
                filename,
                url,
                contentType
            },
            createdBy {
                fullName
            }
            job {
                company {
                    companyTradingEntities {
        _id
                        name,
                        email,
                        phone,
                        logo,
                        addresses {
                            _id,
                            formattedAddress
                        }
                    }
                    proofTerms
                    addresses {
                        _id,
                        formattedAddress
                    }
                }
                customer {
                    name
                    companyTradingEntityId
                    settings {
                        whitelabelProofPortal
                    }
                }
            }
        }
    }
`;

export const GQL_GET_CUSTOMER_PROOFS = gql`
    query getCustomerProofs($customerId:String!){
      proofs(customerId: $customerId) {
            _id
            jobId
            customerId
            fileId
            status
            createdAt
            productIds
            products {
                description
            }
            file {
                filename,
                url,
            },
            createdById
            createdBy {
                fullName
            }
            job {
                company {
                    companyTradingEntities {
        _id
                        name,
                        email,
                        phone,
                        logo,
                        addresses {
                            _id,
                            formattedAddress
                        }
                    }
                    proofTerms
                    addresses {
                        _id,
                        formattedAddress
                    }
                }
                customer {
                    name
                    companyTradingEntityId
                    settings {
                        whitelabelProofPortal
                    }
                }
            }
        }
    }
`;

export const GQL_ADD_PROOF_NOTE = gql`
    mutation addProofNote($proofId:String!, $noteData:ProofNoteInput!){
      addProofNote(proofId:$proofId, data: $noteData){
        _id,
        content,
        assigneeId
      }
    }
`;

export const GQL_REJECT_PROOF = gql`
  mutation rejectProof($_id:String!, $data: ProofNoteInput!, $token: String) {
    rejectProof(_id: $_id, data: $data, token: $token) {
      _id
      status
    }
  }
`;

export const GQL_APPROVE_PROOF = gql`
  mutation approveProof($_id:String!, $token: String) {
    approveProof(_id: $_id, token: $token) {
      _id
      job {
          number
      }
      salesDoc {
        jobNumber
      }
    }
  }
`;

export const  GQL_UPDATE_PROOF = gql`
  mutation updateProof($_id: String!, $proofData: ProofUpdateInput!) {
      updateProof(_id: $_id, data: $proofData) {
          _id
          jobId
          job {
              number
          }
          status
          products {
              description
          }
          file {
              filename,
              url,
          },
          createdBy {
              fullName
          }
          createdAt
      }
  }
`;

export const GQL_DELETE_PROOF = gql`
    mutation delete($_id: String!) {
        deleteProof(_id: $_id) {
            _id
            file {
                filename
            }
            job {
                _id
                number
            }
            salesDoc {
              jobNumber
            }
        }
    }`;

export const GQL_EMAIL_PROOFS = gql`
    mutation sendProof($_id: String!, $emailOptions: EmailOptionsInput, $smsOptions: SMSOptions) {
        sendProof(_id: $_id, emailOptions: $emailOptions, smsOptions: $smsOptions) {
            emailStatus,
            smsStatus,
            message
        }
    }
`;
