import React from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.text.text-caption', (theme) => ({
  '&.medium': {fontSize: '0.875rem'},
  '&:has(+*)': {marginRight: theme.spacing(1),},
  '&.right': {
    textAlign: 'end',
    marginLeft: 'auto',
    marginRight: 0,
  },
  color: theme.colors.text.medium,
  fontWeight: theme.fontWeight.light,
  lineHeight: 1.25,
}));

export function CaptionText({className, medium, right, small, text, children}) {
  return (
    <span
      className={classNames([
        className,
        'text text-caption',
        medium && 'medium',
        right && 'right',
        small && 'small',
      ])}
    >{text ?? children}</span>
  );
}

CaptionText.propTypes = {
  className: HoopsPropTypes.className,
  medium: HoopsPropTypes.bool,
  right: HoopsPropTypes.bool,
  small: HoopsPropTypes.bool,
  text: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
