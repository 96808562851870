import React, {useRef} from 'react';
import {CellContainer} from './CellContainer';
import {ChipList} from '../../Chips/ChipList';
import {colors, registerGlobalStyle} from '../../../theme';

registerGlobalStyle('.power-grid .chip-cell', (theme) => ({
  '.chip-list': {width: '100%'},
  '.chip': {
    backgroundColor: colors.palette.greyLighter,
    '.text': {color: theme.colors.text.dark}
  }
}));

export function ChipCell({value}) {
  const cellRef = useRef(null);

  return (
    <CellContainer className={'chip-cell'} ref={cellRef}>
      <ChipList chips={value?.filter(Boolean)} singleRow resizeContainer={cellRef.current} />
    </CellContainer>
  );
}
