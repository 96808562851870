import React, {isValidElement} from 'react';
import {Checkbox} from '@mui/material';
import {BodyText, CaptionText} from '../Text';
import {Column, Row} from '../Layout';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {Radio, RadioGroup} from './Radio';
import {Switch} from './Switch';

registerGlobalStyle('.descriptive-group', (theme) => ({
  '&:not(:first-child)': {marginTop: theme.spacing(1),},
  '.text-caption': {
    fontSize: '.875rem',
    lineHeight: 1.66,
    fontWeight: theme.fontWeight.normal,
    color: theme.colors.text.main,
  },
  '&>.text-caption': {fontWeight: theme.fontWeight.dark,},
  '.text-body': {
    fontSize: '.875rem',
    fontWeight: theme.fontWeight.normal,
    color: theme.colors.text.medium,
  },
  '&>.descriptive-row': {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(2),
  },
  '.descriptive-item': {
    alignItems: 'start',
    '&>.MuiRadio-root': {paddingTop: 0,}
  },
  '.descriptive-switch': {
    '.column .row': {
      alignItems: 'center',
      '&+.text': {marginTop: -8},
    }
  },
}));

export function DescriptiveGroup({className, caption, children}) {
  return (
    <Column className={[className, 'descriptive-group']}>
      <CaptionText>{caption}</CaptionText>
      {isValidElement(children) && children.type.name === RadioGroup.prototype.constructor.name &&
        children
      }
      {!(isValidElement(children) && children.type.name === RadioGroup.prototype.constructor.name) &&
        <Row className={'descriptive-row'}>
          {children}
        </Row>
      }
    </Column>
  );
}

DescriptiveGroup.propTypes = {
  className: HoopsPropTypes.className,
  caption: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

export function DescriptiveRadioGroup({className, caption, onChange, value, children}) {
  return (
    <DescriptiveGroup className={className} caption={caption}>
      <RadioGroup className={'descriptive-row row'} row value={value} onChange={onChange}>
        {children}
      </RadioGroup>
    </DescriptiveGroup>
  );
}

DescriptiveRadioGroup.propTypes = {
  className: HoopsPropTypes.className,
  caption: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  value: HoopsPropTypes.boolOrString,
  children: HoopsPropTypes.children,
};

export function DescriptiveRadio({className, caption, value, disabled, children}) {
  return (
    <Row className={[className, 'descriptive-radio descriptive-item', disabled && 'disabled']} tag={'label'}>
      <Radio value={value} disabled={disabled}/>
      <Column>
        <CaptionText>{caption}</CaptionText>
        {!isValidElement(children) && !Array.isArray(children) &&
          <BodyText>
            {children}
          </BodyText>
        }
        {isValidElement(children) &&
          children
        }
        {Array.isArray(children) && children.map((child, index) => {
          if (typeof child === 'string') {
            return (<BodyText key={index}>{child}</BodyText>);
          } else {
            return child;
          }
        })}
      </Column>
    </Row>
  );
}

DescriptiveRadio.propTypes = {
  className: HoopsPropTypes.className,
  caption: HoopsPropTypes.string,
  disabled: HoopsPropTypes.bool,
  value: HoopsPropTypes.boolOrString,
  children: HoopsPropTypes.children,
};

export function DescriptiveCheckbox({className, caption, value, children}) {
  return (
    <Row className={[className, 'descriptive-checkbox descriptive-item']} tag={'label'}>
      <Checkbox value={value}/>
      <Column>
        <CaptionText>{caption}</CaptionText>
        <BodyText>
          {children}
        </BodyText>
      </Column>
    </Row>
  );
}

DescriptiveCheckbox.propTypes = {
  className: HoopsPropTypes.className,
  caption: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

export function DescriptiveSwitch({className, caption, checked, disabled, rightUnder, onChange, children}) {
  return (
    <Row className={[className, 'descriptive-switch descriptive-item', disabled && 'disabled']} tag={'label'}>
      {!rightUnder &&
        <>
          <Switch checked={checked} disabled={disabled} onChange={onChange}/>
          <Column>
            <CaptionText>{caption}</CaptionText>
            <BodyText>
              {children}
            </BodyText>
          </Column>
        </>
      }
      {rightUnder &&
        <>
          <Column>
            <Row>
              <CaptionText>{caption}</CaptionText>
              <Switch checked={checked} disabled={disabled} onChange={onChange}/>
            </Row>
            <BodyText>
              {children}
            </BodyText>
          </Column>
        </>
      }
    </Row>
  );
}

DescriptiveSwitch.propTypes = {
  className: HoopsPropTypes.className,
  caption: HoopsPropTypes.string,
  checked: HoopsPropTypes.bool,
  disabled: HoopsPropTypes.bool,
  rightUnder: HoopsPropTypes.bool,
  onChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

