// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Grid, Link as MuiLink, makeStyles, Typography} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {get} from 'lodash';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import {kanban} from '../../ui/theme/kanban';
import HoopsQueryTableCellDateTime from './HoopsQueryTableCellDateTime';

const useStyles = makeStyles((theme) => (
    {...kanban,}
));

export const HoopsQueryKanbanCardPurchaseOrder = ({item = {}}) => {
    const classes = useStyles();
    const now = moment();
    const dueDate = get(item, 'deadlineAt');
    const daysToDueDate = now.diff(dueDate, 'days');

    // handle due date styling
    const dueDateClass = (param) => {
        if (param > -7 && param < 0) {return classes.orangeText;}
        if (param > 0) {return classes.redText;}
        return classes.greyText;
    };

    return (
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={12} className={classes.itemTitle}>
                <Typography variant='body2' style={{paddingBottom: 8}}>
                    <MuiLink component={Link} underline={'none'} color={'primary'} to={`/classic-purchase-orders/${item._id}/view`}>
                        {item.number}
                        {item.title && ` - ${item.title}`}
                    </MuiLink>
                </Typography>
            </Grid>

            {
                item.linkedJobs && item.linkedJobs.map((job, jIdx) => (
                        <Grid item xs={12} className={classes.itemTextObject} key={jIdx}>
                            <Typography variant='caption' key={jIdx}>
                                Job:
                                <MuiLink rowdata-intercom-target={'job-link'} component={Link} underline={'none'} color={'primary'} style={{paddingLeft: '8px'}}
                                    to={`/jobs/${job._id}')}/details`}>
                                    {job.number}
                                </MuiLink>

                                {job.quote &&
                                    <>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        Quote:
                                        <MuiLink rowdata-intercom-target={'job-link'} component={Link} underline={'none'} color={'primary'} style={{paddingLeft: '8px'}}
                                            to={`/quotes/${job.quote._id}/view`}>
                                            {job.quote.number}
                                        </MuiLink>
                                    </>
                                }
                            </Typography>
                        </Grid>
                    ))
            }

            {
                item.masterPurchaseOrder &&
                <Grid item xs={12} className={classes.itemTextObject}>
                    <Typography variant='caption' display='block'>
                        Merged PO:
                        <MuiLink rowdata-intercom-target={'job-link'} component={Link} underline={'none'} color={'primary'} style={{paddingLeft: '8px'}}
                            to={`/purchase-order/${get(item, 'masterPurchaseOrder._id')}/view`}>
                            {get(item, 'masterPurchaseOrder.number')}
                        </MuiLink>
                    </Typography>
                </Grid>
            }

            <Grid item xs={6}>
                <Typography variant='caption' display='block'>{get(item, 'vendor.name')}</Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'end'}}>
                {/* Show chip if we're past the due date */}
                {/* List the dueDate if it is set */}
                {dueDate &&
                    <>
                        <AccessTimeIcon style={{fontSize: 13, top: 2, position: 'relative', marginRight: 4}} className={dueDateClass(daysToDueDate)} />
                        <HoopsQueryTableCellDateTime value={dueDate} showTime={false} variant='caption' className={dueDateClass(daysToDueDate)} />
                    </>
                }
            </Grid>
        </Grid >
    );
};

export default HoopsQueryKanbanCardPurchaseOrder;
