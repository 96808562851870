import classNames from 'classnames';
import React, {forwardRef} from 'react';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.column', (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  alignSelf: 'stretch',
  '&.column-align-center': {alignItems: 'center'},
  '&.column-align-stretch': {alignItems: 'stretch'},
  '&.column-align-right': {alignItems: 'flex-end'},
  '&.column-gap': {rowGap: theme.spacing(2),},
  '&.column-grow': {flexGrow: 1},
  '&.column-justify-center': {justifyContent: 'center'},
  '&.column-justify-space-evenly': {justifyContent: 'space-evenly'},
  '&.column-margin': {margin: theme.spacing(2),},
  '&.column-paper': {padding: 0},
  '&.column-outline': {
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.colors.border.light}`,
    backgroundColor: theme.colors.background.paper,
  },
  '&.column-pad': {padding: theme.spacing(2),},
  '&.column-space': {padding: theme.spacing(2), rowGap: theme.spacing(2),},
  '&.column-fill-width': {
    flex: '1 1 0',
    minWidth: 0,
  },
  '&.column-fit-width': {minWidth: 0,},
  '&.column-scroll': {overflow: 'auto',},
}));

export const Column = forwardRef(
function Column(
  {
    className,
    alignCenter,
    alignStretch,
    alignRight,
    fillWidth,
    fitWidth,
    gap,
    grow,
    justifyCenter,
    justifySpaceEvenly,
    margin,
    pad,
    paper,
    paperOutline,
    scroll,
    space,
    style,
    tag,
    onClick,
    onMouseDown,
    children
  }, ref)
{
  className = classNames([
    className,
    'column',
    alignCenter && 'column-align-center',
    alignStretch && 'column-align-stretch',
    alignRight && 'column-align-right',
    fillWidth && 'column-fill-width',
    fitWidth && 'column-fit-width',
    gap && 'column-gap',
    grow && 'column-grow',
    justifyCenter && 'column-justify-center',
    justifySpaceEvenly && 'column-justify-space-evenly',
    margin && 'column-margin',
    pad && 'column-pad',
    paper && 'column-paper paper',
    paperOutline && 'column-outline',
    scroll && 'column-scroll scroll',
    space && 'column-space',
  ]);

  const Tag = tag ?? 'div';

  return <Tag className={className} ref={ref} onClick={onClick} onMouseDown={onMouseDown} style={style}>{children}</Tag>;
});

Column.propTypes = {
  className: HoopsPropTypes.className,
  alignCenter: HoopsPropTypes.bool,
  alignStretch: HoopsPropTypes.bool,
  alignRight: HoopsPropTypes.bool,
  fillWidth: HoopsPropTypes.bool,
  fitWidth: HoopsPropTypes.bool,
  gap: HoopsPropTypes.bool,
  grow: HoopsPropTypes.bool,
  justifyCenter: HoopsPropTypes.bool,
  justifySpaceEvenly: HoopsPropTypes.bool,
  margin: HoopsPropTypes.bool,
  pad: HoopsPropTypes.bool,
  paper: HoopsPropTypes.bool,
  paperOutline: HoopsPropTypes.bool,
  scroll: HoopsPropTypes.bool,
  space: HoopsPropTypes.bool,
  style: HoopsPropTypes.object,
  tag: HoopsPropTypes.string,
  onClick: HoopsPropTypes.func,
  onMouseDown: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
