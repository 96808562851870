import React from 'react';
import {Switch} from 'react-router-dom';
import {useMountEffect} from '../../../hooks';
import PurchaseOrderEditPage from '../../../componentsOld/purchase-orders/PurchaseOrderEditPage';
import PurchaseOrderManyViewPage from '../../../componentsOld/purchase-orders/PurchaseOrderManyViewPage';
import {PurchaseOrder} from '../../../componentsOld/purchase-orders/SinglePurchaseOrder/PurchaseOrder';
import {ErrorBoundaryRoute} from '../../../componentsHoops/Errors';

export function PurchaseOrdersLegacyRoutes() {
  useMountEffect(() => {
    document.title = 'Purchase Orders | Hoops';
  });

  return (
    <Switch>
      <ErrorBoundaryRoute path='/classic-purchase-orders' exact component={PurchaseOrderManyViewPage}/>
      <ErrorBoundaryRoute path='/classic-purchase-orders/new' exact component={PurchaseOrderEditPage}/>
      <ErrorBoundaryRoute path='/classic-purchase-orders/:orderId/view' component={PurchaseOrder} />
      <ErrorBoundaryRoute path='/classic-purchase-orders/:orderId/edit' component={PurchaseOrderEditPage} />
    </Switch>
  );
}
