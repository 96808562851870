import React, {useCallback, useEffect, useState} from 'react';
import {Column, Row, Table, TableCell, TableRow} from '../../../componentsLib/Layout';
import {Button, ImageThumbnail} from '../../../componentsLib/Basic';
import {registerGlobalStyle} from '../../../theme';
import {useGetJob, useGetSalesDocProofs, useSendProofs} from '../../../hooks/api';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {formatDateShort} from '../../../utils';
import ChipSelect from '../../../componentsOld/jobs/ChipSelect';
import ProofsTableActionCell from '../../../componentsOld/jobs/SingleJob/JobProofs/ProofsTableActionCell';
import {PROOF_STATUSES} from '../../../constants/status';
import AddExistingProofModal from '../../proofs/addExistingProofModal/AddExistingProofModal';
import {stripHTML} from '../../../utils/stripHtml';
import {useDispatch, useSelector} from 'react-redux';
import {SET_JOB} from '../../../actions/jobs';
import {openProofUploadModal} from '../../../actions/modals';
import {truncate} from 'lodash';
import {SendDialog} from '../../../componentsHoops/Modals';
import {TabHeading} from '../../../componentsLib/Basic/TabbedContent';

registerGlobalStyle('.job-proofs-tab', () => ({'.row-divider': {marginBlock: 'unset'}}));

registerGlobalStyle('.proofs-table', (theme) => ({
  width: '100%',
  'td': {border: 'none'},
  'td, th': {padding: theme.spacing(1)},
  'td:nth-of-type(1), th:nth-of-type(1)': {textAlign: 'center', width: 64},
  'td:nth-of-type(2), th:nth-of-type(2)': {textAlign: 'start', width: '30%'},
  'td:nth-of-type(3), th:nth-of-type(3)': {textAlign: 'start', width: 200},
  'td:nth-of-type(4), th:nth-of-type(4)': {textAlign: 'start'},
  'td:nth-of-type(5), th:nth-of-type(5), td:nth-of-type(6), th:nth-of-type(6)': {textAlign: 'center'},
  'td:nth-of-type(7), th:nth-of-type(7)': {textAlign: 'center', width: 140},
}));

export function JobProofsTab({salesDoc}) {
  const {data: {proofs: proofsApi}, refetch} = useGetSalesDocProofs(salesDoc._id);
  const {data: job} = useGetJob(salesDoc?.jobId);
  const [isAddExistingModalOpen, setIsAddExistingModalOpen] = useState(false);
  const hasClassicJob = !!salesDoc.jobId;
  const dispatch = useDispatch();
  const stateProofs = useSelector((state) => state?.proofReducer.proofs);
  const [proofs, setProofs] = useState([]);
  const [showSend, setShowSend] = useState(false);

  // this is necessary for the legacy proof modals that access the job data using redux
  useEffect(() => {
    dispatch({
      type: SET_JOB,
      payload: hasClassicJob ? job : salesDoc
    });
  }, [dispatch, hasClassicJob, job, salesDoc]);

  // this effect fetches the proofs if the legacy proof modals have been updated proofs using redux
  useEffect(() => {
    setProofs(stateProofs);
  }, [stateProofs]);

  // This will init the proofs on first load
  useEffect(() => {
    setProofs(proofsApi);
  }, [proofsApi]);

  const handleOpenSendModal = useCallback(() => {
    setShowSend(true);
  }, []);

  const handleCloseSend = useCallback(() => {
    setShowSend(false);
  }, []);

  const handleUploadClick = () => {
    dispatch(openProofUploadModal());
  };

  const awaitingApprovalData = proofs?.filter(({status}) => status === 'UPLOADED');
  const approvedData = proofs?.filter(({status}) => status === 'APPROVED');
  const rejectedData = proofs?.filter(({status}) => status === 'REJECTED');

  return (
    <Column className={'job-proofs-tab'} paper fillWidth pad gap>
      <TabHeading decorator={'image'} heading={'Proofs'}>
        <Button navPrimary onClick={handleOpenSendModal} disabled={proofs?.length === 0}>Send</Button>
        {!salesDoc.shopper && salesDoc.customerId &&
          <Button navPrimary onClick={() => setIsAddExistingModalOpen(true)}>Use Previous Proofs</Button>
        }
        <Button navPositive onClick={handleUploadClick}>Upload Proof</Button>
      </TabHeading>

      {proofs &&
        <Column fillWidth gap>
          <HeadingText text={`Awaiting Approval (${awaitingApprovalData.length})`} />
          {awaitingApprovalData.length > 0 && <ProofTableLayout proofs={awaitingApprovalData} />}
          <Row divider />
          <HeadingText text={`Approved (${approvedData.length})`} />
          {approvedData.length > 0 && <ProofTableLayout proofs={approvedData} />}
          <Row divider />
          <HeadingText text={`Rejected (${rejectedData.length})`} />
          {rejectedData.length > 0 && <ProofTableLayout proofs={rejectedData} />}
        </Column>
      }

      {isAddExistingModalOpen &&
        <AddExistingProofModal
          updateJobBoard={refetch}
          updateJobProof={refetch}
          existingAssociatedFiles={[{}]}
          salesDoc={salesDoc}
          customerId={salesDoc.customer._id}
          title='Previous Proofs'
          subTitle='Any proof linked with this customer will display here and can be reused on this job. The proof image will need to be sent for approval again.'
          isOpen={isAddExistingModalOpen}
          onCloseModal={() => setIsAddExistingModalOpen(false)}
        />
      }
      {showSend &&
        <SendProofsModal salesDoc={salesDoc} onClose={handleCloseSend} />
      }
    </Column>
  );
}

const ProofTableLayout = ({proofs}) => (
  <Table
    className={'proofs-table'}
    labels={['Preview', 'File Name', 'Linked Products', 'Uploaded By', 'Date', 'Status', 'Action',]}
  >
    {proofs.map((proof) => (
      <TableRow key={proof._id}>
        <TableCell>
          <ImageThumbnail imageUrl={proof.file.url} hoverZoom />
        </TableCell>
        <TableCell text={proof.file.filename} />
        <TableCell>
          {proof.products.map((product) => (
            <BodyText key={product?._id} text={truncate(stripHTML(product?.description))} />
          ))}
        </TableCell>
        <TableCell text={proof.createdBy.fullName} />
        <TableCell>
          {formatDateShort(new Date(proof.createdAt))}
        </TableCell>
        <TableCell>
          <ChipSelect disabled activeOption={proof.status} options={PROOF_STATUSES} />
        </TableCell>
        <TableCell>
          <ProofsTableActionCell proof={proof} />
        </TableCell>
      </TableRow>
    ))}
  </Table>
);

function SendProofsModal({salesDoc, onClose}) {
  const {proofSend: sendApi} = useSendProofs();

  const handleSend = useCallback(async (sendOptions) => {
    if (sendOptions.email?.to || sendOptions.sms?.phone) {
      const to = sendOptions.email?.to && sendOptions.sms?.phone
        ? `${sendOptions.email.to} and ${sendOptions.sms.phone}`
        : sendOptions.email?.to ?? sendOptions.sms?.phone;
      await sendApi({id: salesDoc._id, ...sendOptions}, {successMessage: `Sent Proofs to ${to}`});
    }
  }, [salesDoc._id, sendApi]);

  return (
    <SendDialog
      entity={'proof'}
      title={'Send Proof'}
      onClose={onClose}
      onSend={handleSend}
      to={salesDoc.contact?.email || salesDoc.customer?.email}
      replyTo={salesDoc.owner?.username}
      phone={salesDoc.contact?.phone || salesDoc.customer?.phone}
    />
  );
}
