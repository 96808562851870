import {useCallback, useMemo} from 'react';
import {cloneDeep, set} from 'lodash';

export function useUpdateField({rows: _rows, updateCachedRows, entity, updateApi, refetchRows}) {
  const updateField = useCallback(async ({id, field, value, props}) => {
    const index = _rows?.findIndex((row) => row._id === id);
    if (index >= 0) {
      const rows = [..._rows];
      const row = cloneDeep(rows[index]);
      props = props ?? {[field]: value};
      Object.entries(props).forEach(([propField, propValue]) => set(row, propField, propValue));
      rows[index] = row;
      updateCachedRows({rows});
    }
    const res = await updateApi({id, [entity]: props});
    await refetchRows();
    return res;
  }, [_rows, updateApi, entity, refetchRows, updateCachedRows]);

  return useMemo(() => ({updateField}), [updateField]);
}
