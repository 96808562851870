import {bind, listToMap} from '../utils';
import {Product} from './Product';

export class Decoration {
  constructor(that) {
    if (that) {
      Object.assign(this, that);
    }
    bind(this);
  }

  static fromApi(apiDecoration) {
    return new Decoration({
      ...apiDecoration,
      product: apiDecoration.product ? Product.fromApi(apiDecoration.product) : undefined,
    });
  }

  static fromApiMap(apiDecorations) {
    if (!Array.isArray(apiDecorations)) {
      apiDecorations = [apiDecorations];
    }
    return listToMap(apiDecorations.map((decoration) => Decoration.fromApi(decoration)));
  }
}
