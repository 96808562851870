import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {Tabs} from './Tabs';
import {HoopsPropTypes} from '../utils';
import {Column} from '../Layout';
import {useParams} from 'react-router-dom';
import {generatePath, useHistory, useRouteMatch} from 'react-router';
import {ErrorBoundary} from '../../componentsHoops/Errors';
import {Decorator} from './Decorator';
import {HeadingText} from '../Text';
import {Avatar} from '@mui/material';

registerGlobalStyle('.tabbed-content', (theme) => ({
  flexGrow: 1,
  '.tabs.row': {
    padding: 0,
    background: 'none',
  },
  '.tab-panel': {
    padding: 0,
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',
    flexGrow: 1,
    alignSelf: 'stretch',
  },
}));

registerGlobalStyle('.tab-heading', (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'stretch',
  columnGap: theme.spacing(1),
  '.decorator-avatar': {background: theme.colors.background.contrast.main}
}));

export const TabbedContent = ({className, routeParam, value, children}) => {
  const visibleChildren = children.filter((child) => child.props.visible !== false);
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();
  if (!value && routeParam) {
    const index = visibleChildren.findIndex(({props}) => props.routeValue === params[routeParam]);
    if (index >= 0) {
      value = index;
    }
  }
  const [selectedTabIndex, setSelectedTabIndex] = useState(value || 0);

  const handleChange = useCallback((tabIndex) => {
    setSelectedTabIndex(tabIndex);
    if (routeParam && visibleChildren[tabIndex]?.props.routeValue) {
      if (routeParam in params) {
        history.replace(generatePath(match.path, {...params, [routeParam]: visibleChildren[tabIndex].props.routeValue}));
      } else {
        history.replace(`${match.url}/${visibleChildren[tabIndex].props.routeValue}`);
      }
    }
  }, [history, match.path, match.url, params, routeParam, visibleChildren]);

  return (
    <Column className={[className, 'tabbed-content']}>
      <Tabs
        value={selectedTabIndex}
        onChange={handleChange}
        tabs={visibleChildren.map((child) => child.props.label)}
      />
      <ErrorBoundary>
        {visibleChildren[selectedTabIndex]}
      </ErrorBoundary>
    </Column>
  );
};

TabbedContent.propTypes = {
  className: HoopsPropTypes.className,
  value: HoopsPropTypes.number,
  children: HoopsPropTypes.children
};

export const TabPanel = ({className, children}) => (<div className={classNames([className, 'tab-panel'])}>{children}</div>);

TabPanel.propTypes = {
  className: HoopsPropTypes.className,
  children: HoopsPropTypes.children
};

export const TabHeading = ({className, heading, decorator, children}) => (
  <div className={classNames([className, 'tab-heading'])}>
    <Avatar className={'decorator-avatar'}>
      <Decorator decorator={decorator} />
    </Avatar>
    <HeadingText text={heading} />
    {children}
  </div>
);

TabHeading.propTypes = {
  className: HoopsPropTypes.className,
  heading: HoopsPropTypes.string,
  decorator: HoopsPropTypes.decorator,
  children: HoopsPropTypes.children,
};
