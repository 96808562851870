import React from 'react';
import {Column, Page, PageHeader} from '../../../componentsLib/Layout';
import {HeadingText} from '../../../componentsLib/Text';

export const PurchaseOrderGuestViewPage = () => (
  <Page testId={'purchaseorder-page'} className={'purchaseorder-page'} pad>
    <Column>
      <PageHeader>
        <Column fillWidth>
          <HeadingText>Purchase Order Guest View - Coming Soon</HeadingText>
        </Column>
      </PageHeader>
    </Column>
  </Page>
);
