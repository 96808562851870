import {useCallback, useEffect, useMemo, useState} from 'react';
import {useGetPurchaseOrder, usePurchaseOrderCache} from '../../../hooks/api';
import {PurchaseOrder} from '../Models';

export function usePurchaseOrderState({idOrNumber}) {
  const {data: {purchaseOrder: apiPurchaseOrder}, isLoading: purchaseOrderLoading, refetch: refetchPurchaseOrder} = useGetPurchaseOrder(idOrNumber);
  const {updateCache: updatePurchaseOrderCache} = usePurchaseOrderCache();
  const [purchaseOrder, _setPurchaseOrder] = useState(null);

  // Whenever the PurchaseOrder is set, it will be bound to the purchaseOrder state
  const setPurchaseOrder = useCallback((newPurchaseOrder) => {
    _setPurchaseOrder(newPurchaseOrder.setNotify(setPurchaseOrder));
  }, []);

  // This effect handles the initial load, loading a PurchaseOrder from the server
  useEffect(() => {
    if (apiPurchaseOrder) {
      const newPurchaseOrder = PurchaseOrder.fromApi(apiPurchaseOrder);
      _setPurchaseOrder(newPurchaseOrder.setNotify(setPurchaseOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiPurchaseOrder]);

  // Update the purchaseOrder in the cache
  const updateCache = useCallback((res) => {
    updatePurchaseOrderCache({id: idOrNumber, purchaseOrder: {...apiPurchaseOrder, ...res.purchaseOrder}});
  }, [apiPurchaseOrder, idOrNumber, updatePurchaseOrderCache]);

  return useMemo(() => ({
    loading: purchaseOrderLoading,
    purchaseOrder,
    refetchPurchaseOrder,
    updatePurchaseOrderCache: updateCache,
  }), [refetchPurchaseOrder, purchaseOrder, purchaseOrderLoading, updateCache]);
}
