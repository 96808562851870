import React, {useCallback, useState} from 'react';
import {Button, Decorator, Link} from '../../../componentsLib/Basic';
import {PopupItem, PopupMenuList, usePopupMenuContext} from '../../../componentsLib/Popovers';
import {BodyText} from '../../../componentsLib/Text';
import {SendPurchaseOrderModal} from '../../../componentsHoops/Modals/SendPurchaseOrderModal';

export function PurchaseOrderActionMenu({purchaseOrder}) {
  const {closeMenu} = usePopupMenuContext();
  const [showSend, setShowSend] = useState(false);

  const handleShowSend = useCallback(async () => {
    setShowSend(true);
  }, []);

  const handleCloseSend = useCallback(() => {
    setShowSend(false);
    closeMenu();
  }, [closeMenu]);

  return (
    <PopupMenuList className={'purchase-order-action-menu'}>
      <PopupItem disabled >
        <Link to={'#'}>
          <Decorator>edit</Decorator>
          <BodyText>Edit</BodyText>
        </Link>
      </PopupItem>
      <PopupItem prefix='send' text='Send' onClick={handleShowSend} keepOpenHidden={true} />
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>description</Decorator>
          <BodyText>PDF</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>delete</Decorator>
          <BodyText>Delete</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>description</Decorator>
          <BodyText>Send to Xero</BodyText>
        </Link>
      </PopupItem>

      {showSend &&
        <SendPurchaseOrderModal purchaseOrder={purchaseOrder} onClose={handleCloseSend} />
      }
    </PopupMenuList>
  );
}

export const PurchaseOrderActionMenuButton = ({purchaseOrder, updateField, refetchPurchaseOrders}) => (
  <Button menu navStandard text={'Actions'} placement={'bottom-end'}>
    <PurchaseOrderActionMenu purchaseOrder={purchaseOrder} updateField={updateField} refetchPurchaseOrders={refetchPurchaseOrders} />
  </Button>
);

