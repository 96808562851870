import React, {useCallback, useEffect, useState} from 'react';
import {Column, Row, RowRight} from '../../../componentsLib/Layout';
import {registerGlobalStyle} from '../../../theme';
import {PurchasingView, PurchasingViewMode} from '../Views';
import {PurchaseOrder} from '../../Purchasing/Models';
import {Button} from '../../../componentsLib/Basic';
import {useUser} from '../../../hooks';

registerGlobalStyle('.purchasing-tab', (theme) => ({
  '.comment-title .text-body': {
    color: theme.colors.text.medium,
    lineHeight: 1.5,
    '&:first-child': {fontWeight: theme.fontWeight.bold},
    '&:last-child': {fontWeight: theme.fontWeight.light},
  },
  'textarea': {outline: 'none'},
  '.comment-entry .mentions:focus-within textarea': {borderColor: theme.colors.border.highlight},
}));

export function JobPurchasingTab({job}) {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const user = useUser();

  const notifyUpdatePurchaseOrder = useCallback((purchaseOrder) => {
    setPurchaseOrders((prev) => {
      const index = prev.findIndex((po) => po.draftId === purchaseOrder.draftId);
      if (index >= 0) {
        return prev.toSpliced(index, 1, purchaseOrder);
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    const pos = job?.vendors.keys().map((vendorId) => PurchaseOrder.fromJob(job, {vendorId, owner: user}).setNotify(notifyUpdatePurchaseOrder));
    if (pos?.length) {
      setPurchaseOrders(pos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const viewMode = PurchasingViewMode.AS_PO_TIMELINE;

  return (
    <Column className={'purchasing-tab'} paper fillWidth pad gap>
      <Row fillWidth alignCenter>
        <Button className={'settings-button'} actionStandard prefix={'settings'} menu noChevron/>
        <RowRight>
          <Button navPrimary text={'Create POs'}/>
        </RowRight>
      </Row>
      <PurchasingView job={job} purchaseOrders={purchaseOrders} viewMode={viewMode}/>
    </Column>
  );
}
