import {asRounded} from '../../../utils';

export class BuyPriceCalculator {
  rounding;
  summary;

  constructor() {
  }

  calculate({items, rounding, summary}) {
    this.rounding = rounding;
    this.summary = summary;

    items.forEach((item) => {
      if (!item.isPlaceholder()) {
        if (item.isProductVariant()) {
          const {buyPrice, moq, isAveragedPrice} = this.calculateVariantBuyPrice(item, this.summary.quantities[item.getProductId]);
          if (buyPrice != null && !item.buyPriceOverride) {
            item.buyPrice = buyPrice;
            item.isAveragedPrice = isAveragedPrice;
            item.totalCost = buyPrice * item.quantity;
          }
          item.moq = moq;
        } else if (item.isDecoration()) {
          item.totalCost = item.buyPrice * item.quantity;
          console.log('Calculate decoration price');
        } else if (item.isAdditionalCost()) {
          item.totalCost = item.buyPrice * item.quantity;
          console.log('Calculate additional cost price');
        }
      }
    });
  }

  /**
   * Calculate buy price related data for a variant item. This will either use the price break associated with the
   * variant color and size, or if color or size are unspecified it will average the prices.
   *
   * @param item -
   * @param quantity - Quantity, usually the total quantity for all variants of the product
   * @returns {{buyPrice: number, isAveragedPrice: boolean, moq: number}} -
   */
  calculateVariantBuyPrice(item, quantity) {
    // Determine the default buy price. It's from the correct break, or an average of all matching price breaks.
    if (item.product) {
      const variants = item.product.variants?.filter((v) => (item.color == null || item.color === v.color) && (item.size == null || item.size === v.size));
      const calc = variants.filter(Boolean).reduce((acc, v) => {
        const pbs = v.priceBreaks ?? item.product.defaultPriceBreaks ?? item.priceBreaks;
        if (pbs) {
          const price = pbs.findLast((pb) => quantity >= pb.quantity)?.price ?? pbs[0]?.price ?? 0;
          return {
            buyPriceSum: acc.buyPriceSum + price,
            count: acc.count + 1,
            minPrice: Math.min(acc.minPrice ?? Number.MAX_SAFE_INTEGER, price),
            maxPrice: Math.max(acc.maxPrice ?? 0, price),
            moq: Math.min(acc.moq ?? Number.MAX_SAFE_INTEGER, pbs[0]?.quantity ?? 1),
          };
        }
        return acc;
      }, {buyPriceSum: 0, count: 0, minPrice: null, maxPrice: null, moq: null});
      if (calc.count > 0) {
        return {
          buyPrice: asRounded(calc.buyPriceSum / calc.count, this.rounding),
          moq: calc.moq,
          isAveragedPrice: calc.minPrice !== calc.maxPrice,
        };
      }
    }
    const pbs = item.product?.defaultPriceBreaks ?? item.priceBreaks;
    return {
      buyPrice: pbs?.findLast((pb) => quantity >= pb.quantity)?.price ?? pbs?.[0].price ?? item.price ?? 0,
      moq: 1,
      isAveragedPrice: false,
    };
  }
}
