// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import ProofPreviewTable from './ProofPreviewTable';
import {connect} from 'react-redux';
import {getProofsWithToken as _getProofs} from '../../../actions/proofs';
import {get} from 'lodash';
import queryString from 'query-string';
import ProofService from '../../../servicesOld/ProofService';
import {snackSuccess as _snackSuccess} from '../../../actions/action-types/snackbar';
import {
  openGuestRejectProofModal as _openGuestRejectProofModal,
  openProofTermsModal as _openProofTermsModal
} from '../../../actions/modals';
import Complete from './Complete';
import Header from './Header';
import {ProofImage} from './ProofImage';
import ActionButtons from './ActionButtons';
import Footer from './Footer';
import GuestRejectProofModal from '../../shared/modals/JobProofsModals/GuestRejectProofModal';
import TermsModal from '../../shared/modals/JobProofsModals/TermsModal';
import {withRouter} from 'react-router-dom';
import {getCompanyFromJob as _getCompanyFromJob} from '../../../actions/action-types/company';

const MAX_WIDTH = 1440;
const styles = (theme) => ({
  parentContainer: {backgroundColor: theme.colors.white},
  contentContainer: {
    margin: 0,
    backgroundColor: theme.colors.white,
    [theme.breakpoints.up('lg')]: {
      maxWidth: MAX_WIDTH,
      padding: `0px ${theme.spacing(10)}px ${theme.spacing(6)}px`,
    }
  }
});

class ProofPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      token: null,
      displayProofsComplete: false
    };
  }

  componentDidMount() {
    const {
      location,
      getProofs,
      getCompanyFromJob,
      openProofTermsModal,
      match: {params: {jobId}}
    } = this.props;
    const {token} = queryString.parse(location.search);
    this.setState({
      ...this.state,
      token
    });
    getCompanyFromJob(jobId);
    getProofs(jobId, token, () => {
      const {proofs} = this.props;
      const terms = get(proofs, '[0].job.company.proofTerms', '');
      const remainingProofs = proofs.filter((proof) => proof.status === 'UPLOADED').length;
      this.setState({displayProofsComplete: (remainingProofs === 0)});

      if (remainingProofs > 0 && terms && terms.length > 8) {
        openProofTermsModal();
      }

    });

  }

  approveProof = async (jobId, proofId) => {
    const {snackSuccess, getProofs} = this.props;
    // Approve proof
    await ProofService.approveProof(proofId, this.state.token);
    getProofs(jobId); // TODO: Why?
    snackSuccess('Proof approved');
    this.navigateToNextProof();
  };

  rejectProof = (jobId, proofId) => {
    const {openGuestRejectProofModal} = this.props;
    openGuestRejectProofModal({
      proofId,
      jobId,
      navigateToNextProof: this.navigateToNextProof,
      token: this.state.token,
    });
  };

  handleNextClick = () => {
    const {
      history,
      match: {params: {jobId}}
    } = this.props;
    const {token} = this.state;
    const activeProofIndex = this.getActiveProofIndex();
    const NEXT_PROOF = activeProofIndex + 1;

    const qs = queryString.stringify({
      index: NEXT_PROOF,
      token
    });

    history.push(`/jobs/${jobId}/proofs?${qs}`);
  };

  navigateToNextProof = async () => {
    const {getProofs, history, match: {params: {jobId}},} = this.props;
    await getProofs(jobId);
    const {proofs} = this.props;
    const {token} = this.state;

    // Determine if there are further Proofs to be actioned
    const NEXT_PROOF = proofs.findIndex((proof) => proof.status === 'UPLOADED');

    const qs = queryString.stringify({
      index: NEXT_PROOF,
      token
    });

    // If there's still proofs to go, navigate to next proof
    if (NEXT_PROOF !== -1) {
      history.push(`/jobs/${jobId}/proofs?${qs}`);
      return;
    }

    // Otherwise if all proofs have been actioned, display complete message
    this.setState({
      displayProofsComplete: true,
      token: null
    });
  };
  getActiveProofIndex=() => {
    const {location} = this.props;
    let activeProofIndex = parseInt(queryString.parse(location.search).index);
    if (isNaN(activeProofIndex) || !activeProofIndex){
      activeProofIndex = 0;
    }
    return activeProofIndex;
  }
  handlePreviousClick = () => {
    const {
      history,
      match: {params: {jobId}}
    } = this.props;
    const {token} = this.state;
    const activeProofIndex = this.getActiveProofIndex();
    const NEXT_PROOF = activeProofIndex - 1;

    const qs = queryString.stringify({
      index: NEXT_PROOF,
      token
    });

    history.push(`/jobs/${jobId}/proofs?${qs}`);
  };

  // Is only called from Complete screen, strips token and re-fetches proofs
  handleDisplayProofs = () => {
    const {getProofs, history, match: {params: {jobId}}} = this.props;
    const qs = queryString.stringify({index: 0});
    getProofs(jobId, null, () => {
      this.setState({displayProofsComplete: false});
      history.push(`/jobs/${jobId}/proofs?${qs}`);
    });
  };

  render() {
    const {classes, company, proofs} = this.props;
    const {displayProofsComplete} = this.state;
    const activeProofIndex =  this.getActiveProofIndex();
    let NEXT_PROOF = activeProofIndex + 1;
    const activeProof = proofs[activeProofIndex];

    return (
      <Fragment>
        <Grid container justifyContent={'center'} className={classes.parentContainer}>
          <Grid item container direction={'column'} spacing={3} className={classes.contentContainer}>
            {displayProofsComplete ? <Complete onDisplayProofs={this.handleDisplayProofs} /> : <Fragment>
              {/*HEADER*/}
              <Header
                activeProof={activeProof}
                activeProofIndex={activeProofIndex}
                proofs={proofs}
                company={company}
                />
              {/*PROOF IMAGE*/}
              <ProofImage
                activeProof={activeProof}
                onNextClick={this.handleNextClick}
                onPreviousClick={this.handlePreviousClick}
                nextDisabled={NEXT_PROOF === proofs.length}
                previousDisabled={activeProofIndex === 0}/>
              {/* PRODUCTS TABLE */}
              <Grid container item>
                {activeProof?.products?.length > 0 && <ProofPreviewTable products={get(activeProof, 'products')}/>}
              </Grid>
              {/*ACTION BUTTONS*/}
              {get(activeProof, 'status') === 'UPLOADED' ?
              <ActionButtons
              approveProof={this.approveProof}
              rejectProof={this.rejectProof}
              activeProof={activeProof}
              activeProofIndex={activeProofIndex}
              proofs={proofs}
            /> : null}
            </Fragment>}
          </Grid>
        </Grid>
        {/* Footer */}
        <Footer activeProof={activeProof} />
        <GuestRejectProofModal />
        <TermsModal />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    proofs: state.proofReducer.proofs,
    company: state.companyReducer.company,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCompanyFromJob: (jobId) => dispatch(_getCompanyFromJob(jobId)),
    getProofs: (jobId, token, callback) => dispatch(_getProofs(jobId, token, callback)),
    snackSuccess: (message) => dispatch(_snackSuccess(message)),
    openGuestRejectProofModal: (context) => dispatch(_openGuestRejectProofModal(context)),
    openProofTermsModal: () => dispatch(_openProofTermsModal())
  };
}

ProofPreview = withStyles(styles)(ProofPreview);
ProofPreview = withRouter(ProofPreview);
ProofPreview = connect(mapStateToProps, mapDispatchToProps)(ProofPreview);

export default ProofPreview;
