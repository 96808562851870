
export class PurchaseOrderSummary {
  costs = {};
  quantities = {};
  totalCost;
  totalQuantity;
  totalsAndTaxes = {};

  constructor() {
  }

  summarizeQuantities(items) {
    const quantities = {};
    let totalQuantity = 0;
    items.forEach((item) => {
      if (!item.forContext) {
        totalQuantity += item.quantity;
      }
      if (item.isProductVariant()) {
        const productId = item.getProductId();
        const relatedProductId = item.getRelatedProductId();
        const relatedVariantId = item.getRelatedVariantId();
        quantities[productId] = (quantities[productId] ?? 0) + item.quantity;
        quantities[relatedProductId] = (quantities[relatedProductId] ?? 0) + item.quantity;
        quantities[relatedVariantId] = (quantities[relatedVariantId] ?? 0) + item.quantity;
      }
    });
    this.quantities = quantities;
    this.totalQuantity = totalQuantity;
    return this;
  }

  summarizePrices(items) {
    const costs = {};
    let totalCost = 0;
    items.forEach((item) => {
      if (!item.forContext) {
        totalCost += item.totalCost;
        if (item.isProductVariant()) {
          const productId = item.getProductId();
          const relatedProductId = item.getRelatedProductId();
          const relatedVariantId = item.getRelatedVariantId();
          costs[productId] = (costs[productId] ?? 0) + item.totalCost;
          costs[relatedProductId] = (costs[relatedProductId] ?? 0) + item.totalCost;
          costs[relatedVariantId] = (costs[relatedVariantId] ?? 0) + item.totalCost;
        }
      }
    });
    this.costs = costs;
    this.totalCost = totalCost;
    return this;
  }

  summarizeTotalsAndTaxes(/*items*/) {
    this.totalsAndTaxes = {subTotal: this.totalCost};
    return this;
  }
}
