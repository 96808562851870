import React, {useMemo} from 'react';
import {AccessTime} from '@mui/icons-material';
import {Column, Page, PageHeader, Row} from '../../../componentsLib/Layout';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';
import {formatDateLong, fullName} from '../../../utils';
import {useParams} from 'react-router';
import {TabPanel, TabbedContent} from '../../../componentsLib/Basic/TabbedContent';
import {useCompany} from '../../../hooks';
import {Button} from '../../../componentsLib/Basic';
import {usePurchaseOrderState} from '../State';
import {PurchaseOrderDetailsTab} from '../Tabs/PurchaseOrderDetailsTab';

registerGlobalStyle('.purchaseorder-page', (theme) => ({ // TODO: REMOVE THIS REPETITION
  position: 'relative', // TODO: Is this needed?
  flexGrow: 1, // TODO: Is this needed?
  '.page-header': { // Move this to page
    '.text-heading': {
      span: {color: theme.colors.text.highlight},
      strong: {color: theme.colors.palette.red}
    },
    '.text-body': {
      fontSize: '1rem',
      span: {color: theme.colors.text.highlight},
    },
    '.warning': {color: theme.colors.palette.red,},
    '.time-icon': {margin: theme.spacing(.5, .5, .5, 2)},
    '.owner': {marginTop: theme.spacing(2)},
  },
  '.tabs': {padding: 0}, // Move this to page under tabbed content
}));

export function PurchaseOrderPage({updateBoardField, /*refetchBoard*/}) {
  const {idOrNumber} = useParams();
  const {purchaseOrder, refetchPurchaseOrder, /*updateField*/} = usePurchaseOrderState({idOrNumber, updateField: updateBoardField});
  const {company} = useCompany(purchaseOrder?.companyTradingEntityId);

  const context = useMemo(() => ({
    company,
    refetchPurchaseOrder,
    purchaseOrder,
    purchaseOrderToken: purchaseOrder?._id,
  }), [company, purchaseOrder, refetchPurchaseOrder]);

  return (
    <Page testId={'purchaseorder-page'} className={'purchaseorder-page'} context={context} pad>
      {purchaseOrder &&
        <>
          <PageHeader>
            <Column fillWidth>
              <HeadingText><span>{purchaseOrder.docTypeName} #{purchaseOrder.number}</span> {purchaseOrder.title}</HeadingText>
              <Row>
                <BodyText><span>{[purchaseOrder.vendor?.name, fullName(purchaseOrder.contact)].filter(Boolean).join(' | ')} </span></BodyText>
                {purchaseOrder.deadline &&
                  <>
                    <AccessTime fontSize={'small'} className={'warning time-icon'} />
                    <BodyText className={'warning'}> Deadline {formatDateLong(purchaseOrder.deadline)}</BodyText>
                  </>
                }
              </Row>
              <BodyText className={'owner'}>Owner: {purchaseOrder.owner?.fullName}</BodyText>
            </Column>
            <Column fillWidth>
              <Row justifyRight gap>
                <Button menu navStandard text={'Actions'} placement={'bottom-end'}>
                </Button>
              </Row>
            </Column>
          </PageHeader>

          {/*<SalesDocStatusBar salesDoc={salesDoc} updateField={updateField} />*/}

          <TabbedContent routeParam={'tabName'}>
            <TabPanel label='Details' routeValue={'details'}>
              <PurchaseOrderDetailsTab/>
            </TabPanel>
            <TabPanel label='Files' routeValue={'files'}>
            </TabPanel>
            <TabPanel label='Comments' routeValue={'comments'}>
              {/*<SalesDocCommentsTab salesDoc={salesDoc}/>*/}
            </TabPanel>
            <TabPanel label='Tasks' routeValue={'tasks'}>
              {/*<SalesDocTasksTab salesDoc={salesDoc} />*/}
            </TabPanel>
            <TabPanel label='Activity' routeValue={'activity'}>
              {/*<SalesDocActivityTab quote={salesDoc} />*/}
            </TabPanel>
          </TabbedContent>
        </>
      }
    </Page>
  );
}
