import React, {Fragment, useCallback, useState} from 'react';
import {
  AddCircle,
  PlaylistAdd as AdditionalCostIcon,
  Style as CatalogIcon,
  FileCopy as CopyIcon,
  AutoFixHigh as DecorationIcon,
  Delete as DeleteIcon,
  DragIndicator,
  DescriptionOutlined as PdfIcon,
  ShoppingCart as ShoppingCartIcon,
  CheckCircle as SuccessIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import {Collapse, Divider} from '@mui/material';
import {ReactComponent as OneOffDecorationIcon} from '../../../assets/images/svgicons/colors.svg';
import {ReactComponent as OneOffProductIcon} from '../../../assets/images/svgicons/laundry.svg';
import {ReactComponent as ShippingIcon} from '../../../assets/images/svgicons/package.svg';
import SAGE_LOGO from '../../../assets/images/integrations/sage/SAGE-LOGO.png';
import {Button, ButtonTheme, HoverTools, TextInput, ToolTip, WysiwygText} from '../../../componentsLib/Basic';
import {Column, Row, Table, TableCell, TableRow, usePageContext, Zoomer} from '../../../componentsLib/Layout';
import {TextSkeleton, RectangularSkeleton} from '../../../componentsLib/Skeletons';
import {BodyText, CaptionText, HeadingText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';
import {
  valueFromEvent,
  asCurrencyStringCommaSeparated,
  formatAddressTwoLines,
  formatDateShort,
  asCurrencyRateStringCommaSeparated,
  fullName
} from '../../../utils';
import {SalesDocItem} from '../Models/SalesDoc';
import {SalesDocAlert, SalesDocSection, SalesDocSections} from '../Components';
import {DragDropContext, Draggable, Droppable} from '../../../componentsLib/DragDrop';
import classNames from 'classnames';
import {ImageGallery} from '../../../componentsLib/Basic/ImageGallery';
import {PayOnlineDialog} from '../../../componentsHoops/Stripe/PayOnlineDialog';
import {useThingsMethods} from '../Toolboxes/SalesDocToolboxes';
import {useAcceptSalesDoc} from '../../../hooks/api';
import {SuccessDialog} from '../../../componentsLib/Popovers';
import {useComment} from '../Hooks';
import {isEqual} from 'lodash';
import {awaitTimeout} from '../../../utils/awaitTimeout';

const salesDocPaperWidth = 1000;
const salesDocContentWidth = 918;

const imageColumnWidth = 120;
const sizeColumnWidth = 180;
const groupItemRowColumnWidths = 130;

registerGlobalStyle('.salesdoc-view', (theme) => ({
  '.salesdoc-centered-column': {
    flex: `0 0 ${salesDocPaperWidth}px`,
    transform: 'translateX(var(--zoom-x)) scale(var(--zoom))',
    transformOrigin: 'top left',
    transition: `transform-origin ${theme.transitions.in.duration}, transform ${theme.transitions.in.duration}`,
    '.salesdoc-paper': {
      width: salesDocPaperWidth,
      background: theme.colors.background.white,
      border: `1px solid ${theme.colors.border.light}`,
      padding: theme.spacing(3),
      margin: theme.spacing(0, 0, 2, 0),
      borderRadius: theme.shape.borderRadius
    },
    '.salesdoc-action-buttons': {
      padding: theme.spacing(2, 0),
      minWidth: salesDocPaperWidth,
      justifyContent: 'end',
      '.button-success, .text-body': {
        margin: theme.spacing(0, .5),
        fontWeight: theme.fontWeight.dark,
        pointerEvents: 'none',
        'svg': {alignSelf: 'center'}
      },
      '.button-theme + .button-theme': {marginLeft: theme.spacing(1)},
    }
  },
  '.salesdoc-alert': {
    '&.customer-error-icon': {
      left: theme.spacing(-2),
      top: theme.spacing(7)
    }
  },
  '.salesdoc-group': {
    position: 'relative',
    cursor: 'default',
    marginBottom: theme.spacing(2),
    '.group-items-container': {
      border: `1px solid ${theme.colors.border.light}`,
      margin: theme.spacing(0, 2),
      paddingBottom: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      background: theme.colors.background.white,
      display: 'grid',
      gridAutoFlow: 'row',
      gridRowGap: theme.spacing(2),
      '.empty-drop-target': {padding: 0},
      '.group-items-table': {
        width: salesDocContentWidth,
        'th': {
          textAlign: 'left',
          borderBottom: `1px solid ${theme.colors.border.light}`,
          backgroundColor: theme.colors.background.empty,
          borderRadius: theme.shape.borderRadius,
          '.text-caption': {
            fontSize: '.875rem',
            fontWeight: theme.fontWeight.dark,
            color: theme.colors.text.main,
          }
        },
        'th:first-of-type': {width: 140},
        'th:nth-of-type(2)': {padding: theme.spacing(2, 2, 2, 0),},
        'th:nth-of-type(4), th:nth-of-type(5), th:nth-of-type(6)': {textAlign: 'end', width: 130},
        'th:nth-of-type(2), th:nth-of-type(3)': {width: 185,},
        'th:last-of-type': {padding: theme.spacing(2, 4, 2, 2), width: 160},
        '.group-items': {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          display: 'grid',
          gridAutoFlow: 'row',
          gridRowGap: theme.spacing(2),
          background: theme.colors.background.white,
          cursor: 'default'
        },
      },
    },
    '.group-items-container.variants-empty': {gridRowGap: theme.spacing(1),},
    '.group-items-container.non-variants-empty': {paddingBottom: 0},
    '.hide-header': {
      'th': {display: 'none'},
      minHeight: 0,
    },
    '.item-section': {background: theme.colors.background.white,},
    '.variant-item-table, .decoration-item-table, .additional-cost-item-table, .empty-item-table': {width: 870,},
    '.variant-breakdown-table': {width: '100%'},
    '.variant-breakdown-table.show': {
      opacity: 1,
      transition: theme.transitions.in.opacity,
    },
    '.variant-breakdown-table.hide': {
      opacity: 0,
      transition: theme.transitions.out.opacity,
    },
    '.variant-item-table, .variant-breakdown-table, .decoration-item-table, .additional-cost-item-table, .empty-item-table': {
      '.item-row': {verticalAlign: 'middle',},
      '.item-row.show-variant-breakdown': {
        verticalAlign: 'bottom',
        height: 70,
      },
      '.variant-breakdown-header': {'.text-caption': {fontWeight: theme.fontWeight.dark,},},
      '.variant-breakdown-details': {
        'p': {color: theme.colors.text.medium},
        'td': {verticalAlign: 'middle'},
        '.item-size': {width: sizeColumnWidth},
      },
      '.variant-breakdown-toggle-button': {verticalAlign: 'top',},
      '.item-description': {
        '.text-body': {
          'strong, b': {fontWeight: 700},
          'p:first-of-type': {marginTop: 0},
          'p:last-of-type': {marginBottom: 0},
          'ul': {marginLeft: theme.spacing(-2),},
          'li': {margin: theme.spacing(1, 0)},
          'ol': {marginLeft: theme.spacing(-3),}
        },
      },
      '.item-description, .item-quantity, .item-rate, .item-total': {width: groupItemRowColumnWidths},
      '.item-quantity, .item-rate, .item-total': {textAlign: 'end',},
      '.item-image': {
        verticalAlign: 'top',
        paddingRight: theme.spacing(2),
        width: imageColumnWidth,
        '.salesdoc-section': {margin: 0},
        'img, .react-pdf__Document': {border: 'none',},
      },
    },
  },
  '.salesdoc-section': {margin: 1},
  '.add-buttons': {
    padding: theme.spacing(4),
    'button + button': {marginLeft: theme.spacing(2)},
    '.button.minor': {
      textDecoration: 'underline',
      background: 'none',
      border: 'none',
      textTransform: 'none',
      padding: 0,
      boxShadow: 'none',
      fontWeight: theme.fontWeight.normal,
      color: theme.colors.text.main,
    }
  },
  '.salesdoc-header-company': {padding: theme.spacing(1, 2)},
  '.salesdoc-header-doc-type-title': {paddingLeft: theme.spacing(2)},
  '.document-details': {
    padding: theme.spacing(2, 2, 1, 2),
    display: 'flex',
    '.column + .column': {marginLeft: theme.spacing(6)}
  },
  '.company-logo': {
    alignItems: 'end',
    'img': {
      maxWidth: 300,
      maxHeight: 200,
    }
  },
  '.salesdoc-customer': {
    position: 'relative',
    padding: theme.spacing(1, 2),
    '.salesdoc-address-column': {justifyContent: 'end'},
    '.text-caption, .customer-contact-autocomplete-label': {
      fontSize: '0.875rem',
      color: theme.colors.text.medium
    },
    '.text-body': {fontSize: '0.875rem'},
  },
  '.salesdoc-divider-horizontal': {margin: theme.spacing(0, 2, 2, 2)},
  '.salesdoc-divider-vertical': {margin: theme.spacing(0, 1)},
  '.salesdoc-totals': {
    justifyContent: 'end',
    padding: theme.spacing(1, 3),
    '.column': {alignItems: 'end', paddingLeft: theme.spacing(6), textAlign: 'right'}
  },
  '.salesdoc-terms': {
    padding: theme.spacing(1, 2),
    '.text-heading': {color: theme.colors.text.medium},
    '.salesdoc-terms-box': {
      border: `1px solid ${theme.colors.border.light}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      'p:first-of-type': {marginTop: 0},
      'p:last-of-type': {marginBottom: 0},
      'span': {
        width: '100%',
        'p': {width: '100%'},
      },
    }
  },
  '.zoomer-container': {
    position: 'fixed',
    width: 1002,
    zIndex: 1,
    '.zoomer': {
      position: 'absolute',
      left: theme.spacing(1),
      top: theme.spacing(1),
      boxShadow: theme.shadows.hoverElevation,
    },
  },
}));

registerGlobalStyle('.salesdoc-builder', (theme) => ({
  // Width needs to be 100% this stretches the container that catches the deselect clicks for handleClearSelection
  width: '100%',
  '.salesdoc-centered-column': {
    '.salesdoc-action-buttons': {
      '.button-theme': {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    },
    '.salesdoc-group': {
      marginBottom: theme.spacing(0),
      '.group-actions-hover': {
        position: 'absolute',
        top: 2,
        left: 1,
        width: salesDocContentWidth,
        height: 50,
        opacity: 0,
        margin: theme.spacing(0, 2),
        padding: 1,
        backgroundColor: theme.colors.background.empty,
        zIndex: theme.zIndex.hovers,
        '.hover-tools': {
          top: '50%',
          transform: 'translateY(-50%)'
        },
        '&:hover': {opacity: 1},
        '.group-revenue-button': {left: theme.spacing(17)},
        '.group-drag-anchor': {
          left: theme.spacing(-2),
          cursor: 'grab',
        },
        '.group-action-buttons': {
          right: theme.spacing(-3.5),
          cursor: 'pointer',
        },
      },
      '.item-section': {
        cursor: 'default',
        '.item-action-buttons': {
          right: theme.spacing(-3.5),
          cursor: 'pointer',
        },
        '.item-drag-anchor': {
          left: theme.spacing(-2),
          cursor: 'grab',
        },
        '.item-action-buttons, .item-drag-anchor': {
          top: '50%',
          transform: 'translateY(-50%)'
        },
        '.add-more-items-menu': {
          padding: theme.spacing(.5),
          left: theme.spacing(5),
          bottom: theme.spacing(-3),
          background: theme.colors.background.white,
          borderRadius: theme.shape.borderRadius,
          alignItems: 'stretch',
          '.tooltip-container+.tooltip-container': {marginLeft: 10},
          'svg': {width: 24, height: 24, cursor: 'pointer',},
          '.sage-icon': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            img: {width: theme.spacing(4)},
          }
        },
        '.visibility-off-icon': {
          position: 'absolute',
          padding: theme.spacing(1),
          opacity: 0,
          transition: theme.transitions.out.opacity,
          color: theme.colors.text.medium,
          zIndex: theme.zIndex.hovers,
          left: theme.spacing(1),
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        },
        '&.item-hidden-from-customer': {
          boxShadow: `0 0 0 1px ${theme.colors.border.lightest}`,
          '.text': {color: theme.colors.text.medium,},
          '.visibility-off-icon': {
            opacity: 1,
            transition: theme.transitions.out.opacity,
          },
          '&:hover': {
            boxShadow: `0 0 0 1px ${theme.colors.border.light}`,
            transition: theme.transitions.in.opacity,
          },
          '&.selected': {
            boxShadow: `0 0 0 2px ${theme.colors.border.highlight}`,
            transition: theme.transitions.in.opacity,
          }
        },
      },
      '.dragging': {
        '.item-section': {
          boxShadow: theme.shadows.dragElevation,
          '.hide-on-drag': {opacity: 0},
          '.show-on-drag': {
            opacity: 1,
            boxShadow: theme.shadows.hoverElevation,
            zIndex: theme.zIndex.hovers,
          },
        },
      },
      '.add-new-group-button': {
        margin: theme.spacing(0.5, 0),
        padding: theme.spacing(0.5, 2),
        opacity: 0,
        transition: theme.transitions.out.opacity,
        width: '-webkit-fill-available',
        justifyContent: 'flex-start',
        background: theme.colors.background.grey.light,
        '&:hover': {
          opacity: 1,
          transition: theme.transitions.in.opacity,
        }
      },
      '.image-section': {padding: 1},
    },
    '.dragging': {
      '.salesdoc-group': {
        '.group-actions-hover': {
          opacity: 1,
          '.hide-on-drag': {opacity: 0},
          '.show-on-drag': {
            opacity: 1,
            boxShadow: theme.shadows.hoverElevation,
            zIndex: theme.zIndex.hovers,
          },
        },
        '.group-items-container': {boxShadow: theme.shadows.dragElevation},
        '.item-section': {
          boxShadow: 'none',
          '.hide-on-drag': {opacity: 0},
          '.show-on-drag': {display: 'none'},
        },
      },
    },
  },
  '&.toolbox-open': {transition: `transform-origin ${theme.transitions.duration.out}, transform ${theme.transitions.duration.out}`,},
}));

export function SalesDocView() {
  const {salesDoc, toolboxOpen, zoom, hideActionButtons, isBuilder, isDrawer, printPdf, salesDocPdf, setSelection} = usePageContext();
  const [draggingType, setDraggingType] = useState(null);

  const onDragStart = useCallback(({type}) => {
    setDraggingType(type);
  }, []);

  const getItemBeforeVariantIndex = useCallback(({variantId, groupId, index}) => {
    const itemsInGroup = salesDoc.getProductVariantsInGroup(groupId).filter((i) => i.variantId !== variantId);
    return salesDoc.items[salesDoc.items.indexOf(itemsInGroup[index - 1])] ?? null;
  }, [salesDoc]);

  const getItemBeforeIndex = useCallback(({itemId, groupId, index}) => {
    const itemsInGroup = salesDoc.getNonProductVariantsInGroup(groupId).filter((i) => i.itemId !== itemId);
    return salesDoc.items[salesDoc.items.indexOf(itemsInGroup[index - 1])] ?? null;
  }, [salesDoc]);

  const onDragEnd = useCallback(({draggableId, destination, source, type}) => {
    const destinationGroupId = destination?.droppableId.replace(`_${type}`, '');

    // Stop function if an item is dragged back to the same place
    if (destination?.index === source.index && destination?.droppableId === source.droppableId) {
      return;
    }

    if (destination && type === SalesDocItem.Type.VARIANT) {
      salesDoc.moveVariantsToGroup({
        afterItem: getItemBeforeVariantIndex({variantId: draggableId, groupId: destinationGroupId, index: destination.index}),
        destinationGroupId: destinationGroupId,
        variantId: draggableId
      });
    } else if (destination && type === 'nonVariant') {
      salesDoc.moveItemToGroup({
        afterItem: getItemBeforeIndex({itemId: draggableId, groupId: destinationGroupId, index: destination.index}),
        destinationGroupId: destinationGroupId,
        itemId: draggableId
      });
    } else if (destination && type === 'group' && destination.index !== source.index) {
      salesDoc.moveGroup({
        afterItem: salesDoc.items.findLast((item) => item.groupId === salesDoc.getGroupIds().filter((groupId) => groupId !== draggableId)[destination.index - 1]),
        groupId: draggableId,
      });
    }
    setDraggingType(null);
  }, [getItemBeforeIndex, getItemBeforeVariantIndex, salesDoc]);

  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);

  const billingAddressMismatch = !isEqual(salesDoc.billingAddress, salesDoc.customer?.addresses?.find(({_id}) => salesDoc.billingAddress?._id === _id));
  const shippingAddressMismatch = !isEqual(salesDoc.shippingAddress, salesDoc.customer?.addresses?.find(({_id}) => salesDoc.shippingAddress?._id === _id));

  // handleClearSelection needs to stay in SalesDocView to prevent clicks from the toolbox triggering this function.
  const handleClearSelection = useCallback((e) => {
    e.stopPropagation();
    if (!e.defaultPrevented && isBuilder) {
      setSelection({});
    }
  }, [setSelection, isBuilder]);

  return (
    <Row className={['salesdoc-view', isBuilder && 'salesdoc-builder', toolboxOpen && 'toolbox-open']} onClick={handleClearSelection}>
      <Column>
        {(!isDrawer && !printPdf) &&
          <Row className={'zoomer-container'} onClick={handleStopPropagation}>
            <Zoomer zoom={zoom.zoom} onChange={zoom.setZoom} autoZoom={zoom.auto} onChangeAutoZoom={zoom.setAuto} onFitScreen={zoom.fitScreen} />
          </Row>
        }

        {salesDoc &&
          <Column className={'salesdoc-centered-column transform-source'}>
            {!hideActionButtons &&
              <>
                {isBuilder &&
                  <SalesDocSection section={SalesDocSections.customerActionButtons}>
                    <SalesDocActionButtons />
                  </SalesDocSection>
                }

                {!isBuilder &&
                  <SalesDocActionButtons />
                }
              </>
            }
            <div className={'salesdoc-paper'} ref={salesDocPdf}>
              {isBuilder &&
                <SalesDocSection className={'pdf-no-break'} section={SalesDocSections.documentHeader}>
                  <SalesDocHeader />
                </SalesDocSection>
              }

              {!isBuilder &&
                <SalesDocHeader />
              }

              {isBuilder &&
                <SalesDocSection className={'pdf-no-break'} section={SalesDocSections.customerSection}>
                  {(billingAddressMismatch || shippingAddressMismatch) &&
                    <SalesDocAlert className={'customer-error-icon'} section={SalesDocSections.customerSection} />
                  }
                  <SalesDocCustomer />
                </SalesDocSection>
              }

              {!isBuilder &&
                <SalesDocCustomer />
              }

              <Divider flexItem className='salesdoc-divider-horizontal' />

              {isBuilder &&
                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                  <Droppable droppableId={'document'} type={'group'} isDropDisabled={draggingType !== 'group'}>
                    {salesDoc.getGroupIds().map((groupId, index) => (
                      <Draggable draggableId={groupId} index={index} key={groupId}>
                        <SalesDocGroup
                          groupId={groupId}
                          draggingType={draggingType}
                        />
                      </Draggable>
                    ))}
                  </Droppable>
                </DragDropContext>
              }

              {!isBuilder &&
                <>
                  {salesDoc.getGroupIds().map((groupId) => (
                    <SalesDocGroup
                      key={groupId}
                      groupId={groupId}
                    />
                  ))}
                </>
              }

              {isBuilder &&
                <SalesDocSection section={SalesDocSections.documentTotals}>
                  <SalesDocTermsAndTotals />
                </SalesDocSection>
              }

              {!isBuilder &&
                <SalesDocTermsAndTotals />
              }

            </div>
          </Column>
        }
      </Column>
    </Row>
  );
}

function SalesDocActionButtons() {
  const {
    salesDoc,
    salesDocToken,
    company,
    currencySymbol,
    handleDownloadPdf,
    pdfLoading,
    refetchSalesDoc
  } = usePageContext();

  const {accept: acceptSalesDocApi, isInProgress: acceptSaving} = useAcceptSalesDoc();
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState({open: false, type: ''});

  const handleCommentSuccess = useCallback(() => {
    setOpenSuccessDialog({open: true, type: 'comment'});
    setShowComment(false);
  }, []);

  const {commentText, setCommentText, savingComment, saveComment} = useComment({
    id: salesDocToken,
    successMessage: false,
    onSuccess: () => handleCommentSuccess()
  });
  const [showComment, setShowComment] = useState(false);

  const handleAccept = useCallback(async () => {
    const res = await acceptSalesDocApi({id: salesDocToken}, {successMessage: false, errorMessage: ({message}) => message});
    if (res) {
      salesDoc.accept();
      setOpenSuccessDialog({open: true, type: 'accept'});
    }
  }, [acceptSalesDocApi, salesDoc, salesDocToken, setOpenSuccessDialog]);

  const handleAcceptAndPay = useCallback(() => {
    setIsPaymentDialogOpen(true);
  }, []);

  const handlePaymentSuccess = useCallback(async () => {
    await awaitTimeout({seconds: 2});
    refetchSalesDoc();
  }, [refetchSalesDoc]);

  return (
    <>
      <Row className={['salesdoc-action-buttons']} alignCenter>
        {salesDoc.template.pdfButtonEnabled &&
          <ButtonTheme color={salesDoc.template.pdfButtonColor}>
            <Button navMinor prefix={PdfIcon} text={salesDoc.template.pdfButtonText} loading={pdfLoading} caps={false} onClick={handleDownloadPdf} />
          </ButtonTheme>
        }
        {salesDoc.template.commentButtonEnabled &&
          <ButtonTheme color={salesDoc.template.commentButtonColor}>
            <Button
              navStandard
              text={salesDoc.template.commentButtonText}
              caps={false}
              onClick={() => setShowComment(true)}
              noWrap
            />
          </ButtonTheme>
        }
        {salesDoc.template.acceptButtonEnabled &&
          <ButtonTheme color={salesDoc.template.acceptButtonColor}>

            {salesDoc.accepted &&
              <Button
                className={'button-success'}
                actionStandard
                caps={true}
                prefix={SuccessIcon}
                text={'ACCEPTED'}
              />
            }

            {!salesDoc.accepted &&
              <Button
                navPrimary
                loading={acceptSaving}
                text={salesDoc.template.acceptButtonText}
                caps={false}
                onClick={handleAccept}
                noWrap
              />
            }

          </ButtonTheme>
        }
        {salesDoc.template.payButtonEnabled && company.stripeConnectedAccountId &&
          <>
            {isPaymentDialogOpen &&
              <PayOnlineDialog
                amountToPay={salesDoc.total + salesDoc.summary.checkoutExtras.total}
                invoiceAmount={salesDoc.total}
                feesAndCharges={salesDoc.getItemsAtCheckout().map((item) => ({name: item.name, amount: salesDoc.summary.getItemTotalIncTax(item)}))}
                companyName={company.name}
                currencySymbol={currencySymbol}
                salesDocToken={salesDocToken}
                stripeConnectedAccount={company.stripeConnectedAccountId}
                onClose={() => setIsPaymentDialogOpen(false)}
                onSuccess={handlePaymentSuccess}
              />
            }
            <ButtonTheme color={salesDoc.template.payButtonColor}>

              {salesDoc.stripeInvoiceId &&
                <Button
                  className={'button-success'}
                  actionStandard
                  caps={true}
                  prefix={SuccessIcon}
                  text={'PAID'}
                />
              }

              {!salesDoc.stripeInvoiceId &&
                <Button
                  navMain
                  text={salesDoc.template.payButtonText}
                  onClick={handleAcceptAndPay}
                  caps={false}
                  noWrap
                />
              }
            </ButtonTheme>
          </>
        }
      </Row>
      {(showComment) &&
        <div className={'salesdoc-paper'}>
          <TextInput value={commentText} rows={4} multiline onChange={(e) => setCommentText(valueFromEvent(e))} />
          <Row className={'comment-action-buttons'}>
            <Button navMain text={'Save'} disabled={!commentText} onClick={saveComment} loading={savingComment} />
            <Button navMinor text={'Cancel'} onClick={() => setShowComment(false)} />
          </Row>
        </div>
      }
      {salesDoc &&
        <SuccessDialog
          title={openSuccessDialog.type === 'accept' ? `${salesDoc.docTypeName} Accepted` : 'Comment Sent'}
          onClose={() => setOpenSuccessDialog({open: false})}
          open={openSuccessDialog.open}
          topText={openSuccessDialog.type === 'accept' && `You accepted ${salesDoc.docTypeName} ${salesDoc.number}.`}
          bottomText={openSuccessDialog.type === 'accept' ? 'Thank you for your business, we\'ll be in touch with further updates' : 'Your comment was successfully added, our team will be notified'}
        />
      }
    </>
  );
}

function SalesDocHeader() {
  const {salesDoc, company} = usePageContext();
  const formattedAddress = formatAddressTwoLines(company?.addresses?.[0]);
  return (
    <>
      <Row className='salesdoc-header-company'>
        <Column fillWidth>
          <HeadingText x20>{company.name}</HeadingText>
          <BodyText>{formattedAddress.line1}</BodyText>
          <BodyText>{formattedAddress.line2}</BodyText>
          <BodyText>{company.phone}</BodyText>
          <BodyText>{company.email}</BodyText>
        </Column>
        <Column fillWidth className={'company-logo'}>
          {company.logo &&
            <img alt={`${company.name} logo`} src={company.logo} />
          }
        </Column>
      </Row>
      <Row className='salesdoc-header-doc-type-title'>
        <HeadingText x32>{salesDoc.docTypeName}</HeadingText>
      </Row>
      <Row className={'document-details'}>
        <Column>
          <CaptionText>{salesDoc.docTypeName} #</CaptionText>
          <BodyText dark>{salesDoc.number ?? 'Draft'}</BodyText>
        </Column>
        <Column>
          <CaptionText>Created</CaptionText>
          <BodyText>{formatDateShort(salesDoc.createdAt)}</BodyText>
        </Column>
        <Column>
          <CaptionText>Creator</CaptionText>
          <BodyText>{`${fullName(salesDoc.owner)} | ${salesDoc.owner?.username}`}</BodyText>
        </Column>
        {salesDoc.template.showDeadline &&
          <Column>
            <CaptionText>Deadline</CaptionText>
            <BodyText>{formatDateShort(salesDoc.deadline)}</BodyText>
          </Column>
        }
        {salesDoc.template.showReference &&
          <Column>
            <CaptionText>Reference</CaptionText>
            <BodyText>{salesDoc.reference}</BodyText>
          </Column>
        }
      </Row>
    </>
  );
}

function SalesDocCustomer() {
  const {salesDoc} = usePageContext();
  const billingAddress = formatAddressTwoLines(salesDoc.billingAddress);
  const shippingAddress = formatAddressTwoLines(salesDoc.shippingAddress);

  return (
    <Row>
      <Column className={'salesdoc-customer'} fillWidth>
        {salesDoc.customer &&
          <Row gap>
            <Column fillWidth>
              <CaptionText>Prepared For:</CaptionText>
              <HeadingText x20>{salesDoc.customer.name}</HeadingText>
              <BodyText>{fullName(salesDoc.contact)}</BodyText>
              <BodyText>{salesDoc.contact.phone}</BodyText>
              <BodyText>{salesDoc.contact.email}</BodyText>
            </Column>
            <Column fillWidth className={'salesdoc-address-column'}>
              {salesDoc.billingAddress &&
                <>
                  <CaptionText>Billing Address</CaptionText>
                  <BodyText>{billingAddress.line1}</BodyText>
                  <BodyText>{billingAddress.line2}</BodyText>
                </>
              }
            </Column>
            <Column fillWidth className={'salesdoc-address-column'}>
              {salesDoc.shippingAddress &&
                <>
                  <CaptionText>Shipping Address</CaptionText>
                  <BodyText>{shippingAddress.line1}</BodyText>
                  <BodyText>{shippingAddress.line2}</BodyText>
                </>
              }
            </Column>
          </Row>
        }
        {!salesDoc.customer &&
          <Row gap>
            <Column fillWidth>
              <CaptionText>Prepared For:</CaptionText>
              <TextSkeleton animation={false} width={196} />
              <TextSkeleton animation={false} width={242} />
              <TextSkeleton animation={false} width={104} />
              <TextSkeleton animation={false} width={162} />
            </Column>
            <Column fillWidth className={'salesdoc-address-column'}>
              <CaptionText>Billing Address</CaptionText>
              <TextSkeleton animation={false} width={221} />
              <TextSkeleton animation={false} width={104} />
            </Column>
            <Column fillWidth className={'salesdoc-address-column'}>
              <CaptionText>Shipping Address</CaptionText>
              <TextSkeleton animation={false} width={221} />
              <TextSkeleton animation={false} width={104} />
            </Column>
          </Row>
        }
      </Column>
    </Row>
  );
}

function SalesDocGroup({groupId, draggingType}) {
  const {salesDoc, isBuilder, setSelection} = usePageContext();
  const items = salesDoc.getItemsInGroup(groupId);
  const variantIds = salesDoc.getProductVariantIdsInGroup(groupId);
  const groupItem = items[0];
  const nonVariants = salesDoc.getNonProductVariantsInGroup(groupId);
  const nonVariantsCustomerVisible = nonVariants.filter((item) => ((item.showItem || !item.rollupSellPrice) && !item.applyAtCheckout));

  const addNewGroup = useCallback(() => {
    const lastItemInGroup = salesDoc.getLastItemInGroup(groupId);
    const doc = salesDoc.addGroup(lastItemInGroup);
    if (doc.newItem) {
      setSelection({section: SalesDocSections.groupSection, groupId: doc.newItem.groupId});
    }
  }, [salesDoc, groupId, setSelection]);

  // If the group is empty and we're not in the builder don't render the group table
  if(!isBuilder && !variantIds.length && !nonVariantsCustomerVisible.length){
    return null;
  }

  return (
    <Column className={'salesdoc-group pdf-break-container'}>
      {isBuilder && <GroupActionsHover groupId={groupId} />}

      {/* When group is empty show the group placeholder */}
      {(groupItem.type === SalesDocItem.Type.GROUP_PLACEHOLDER && isBuilder) &&
        <SalesDocGroupPlaceholder groupId={groupId} />
      }
      {(groupItem.type !== SalesDocItem.Type.GROUP_PLACEHOLDER) &&
        <div className={classNames(['group-items-container', variantIds.length === 0 && 'variants-empty', nonVariants.length === 0 && 'non-variants-empty'])}>
          {/* This standalone table renders the header that applies to variant and non-variant tables */}
          <Table className={'group-items-table'} labels={['', 'Item', '', 'Quantity', 'Rate (Ex Tax)', 'Total']} />

          {isBuilder &&
            <Droppable
              droppableId={`${groupItem.groupId}_${SalesDocItem.Type.VARIANT}`}
              type={SalesDocItem.Type.VARIANT}
              isDropDisabled={draggingType !== SalesDocItem.Type.VARIANT || !isBuilder}
              key={groupItem.groupId}
              className={[variantIds.length > 0 && 'populated-drop-target']}
            >
              <Table className={'group-items-table hide-header'} labels={['', '', '', '', '', '']}>
                <TableRow>
                  <TableCell colSpan={6} className={'group-items'}>
                    {variantIds.map((variantId, variantIndex) => (
                      <VariantBuilderItems variantId={variantId} key={variantId} index={variantIndex} groupId={groupId} />
                    ))}
                  </TableCell>
                </TableRow>
              </Table>
            </Droppable>
          }

          {!isBuilder &&
            <Table className={'group-items-table hide-header pdf-repeat-over-page-break'} labels={['', '', '', '', '', '']}>
              <TableRow>
                <TableCell colSpan={6} className={'group-items'}>
                  {variantIds.map((variantId, variantIndex) => (
                    <VariantItemsView variantId={variantId} key={variantIndex} />
                  ))}
                </TableCell>
              </TableRow>
            </Table>
          }

          {isBuilder &&
            <Droppable
              droppableId={`${groupItem.groupId}_nonVariant`}
              type={'nonVariant'}
              isDropDisabled={draggingType !== 'nonVariant' || !isBuilder}
              className={[nonVariants.length === 0 && 'empty-drop-target']}
            >
              <Table className={'group-items-table hide-header'} labels={['', '', '', '', '', '']}>
                <TableRow>
                  <TableCell colSpan={6} className={'group-items'}>
                    {nonVariants.map((item, itemIndex) => (
                      <Fragment key={item.itemId}>
                        {(item.type === SalesDocItem.Type.DECORATION || item.type === SalesDocItem.Type.DECORATION_PLACEHOLDER) &&
                          <DecorationBuilderItem item={item} index={itemIndex} groupId={groupId} />
                        }
                        {item.type === SalesDocItem.Type.ADDITIONAL_COST &&
                          <AdditionalCostBuilderItem item={item} index={itemIndex} groupId={groupId} />
                        }
                      </Fragment>
                    ))
                    }
                  </TableCell>
                </TableRow>
              </Table>
            </Droppable>
          }

          {!isBuilder &&
            <Table className={'group-items-table hide-header'} labels={['', '', '', '', '', '']}>
              <TableRow>
                <TableCell colSpan={6} className={'group-items'}>
                  {nonVariantsCustomerVisible.map((item) => (
                    <Fragment key={item.itemId}>
                      {(item.type === SalesDocItem.Type.DECORATION || item.type === SalesDocItem.Type.DECORATION_PLACEHOLDER) &&
                        <DecorationItemView item={item} />
                      }
                      {item.type === SalesDocItem.Type.ADDITIONAL_COST &&
                        <AdditionalCostItemView item={item} />
                      }
                    </Fragment>
                  ))}
                </TableCell>
              </TableRow>
            </Table>
          }
        </div>
      }
      {isBuilder && <Button className={'add-new-group-button'} actionPrimary prefix={AddCircle} text='Add New Group' onClick={addNewGroup} />}
    </Column>
  );
}

function VariantBuilderItems({variantId, groupId, index}) {
  const {salesDoc, setSelection} = usePageContext();
  const variant = salesDoc.getFirstVariantItem(variantId);

  const handleCopyVariant = useCallback(() => {
    const doc = salesDoc.copyVariants(variantId);
    if (doc.newItem) {
      setSelection({section: SalesDocSections.product, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId, variantId: doc.newItem.variantId});
    }
  }, [setSelection, salesDoc, variantId]);

  const handleDeleteVariant = useCallback(() => {
    salesDoc.deleteVariants(variantId);
  }, [salesDoc, variantId]);

  return (
    <Draggable draggableId={variantId} index={index} key={variantId}>
      <SalesDocSection section={SalesDocSections.product} groupId={groupId} variantId={variantId} className={'item-section hover-tools-container'}>
        <HoverTools className={'item-drag-anchor show-on-drag'} selectable>
          <DragIndicator />
        </HoverTools>
        <HoverTools className={'item-action-buttons hide-on-drag'} selectable>
          <CopyIcon onClick={handleCopyVariant} />
          <DeleteIcon onClick={handleDeleteVariant} />
        </HoverTools>
        <AddMoreItemsHoverMenu groupId={groupId} variantId={variantId} />
        {variant.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER
          ? <SalesDocEmptyItemView />
          : <VariantItemsView variantId={variant.variantId} />
        }
      </SalesDocSection >
    </Draggable>
  );
}

function VariantItemsView({variantId}) {
  const {salesDoc, currencySymbol, isBuilder, pdfLoading} = usePageContext();
  const items = salesDoc.getVariantItems(variantId);
  const firstItem = items[0];
  const product = firstItem.productId ? salesDoc.products[firstItem.productId] : null;
  const oneOff = firstItem.type === SalesDocItem.Type.VARIANT && product == null;
  const showVariantBreakdown = ((firstItem.priceMode === 'average' && firstItem.showItem) || firstItem.priceMode === 'variant');
  const [showItemDetails, setShowItemDetails] = useState(true);

  return (
    <Table className={'variant-item-table'} labels={['', '', '', '', '']}>
      <TableRow className={['item-row', 'pdf-no-break', showVariantBreakdown && 'show-variant-breakdown']}>
        <TableCell className={'item-image'} rowSpan={showVariantBreakdown ? 3 + items.length : 2}>
          {firstItem.image &&
            <>
              {(isBuilder && !oneOff) &&
                <SalesDocSection section={SalesDocSections.productImage} groupId={firstItem.groupId} variantId={firstItem.variantId} className={'image-section'}>
                  <ImageGallery images={[firstItem.image]} />
                </SalesDocSection>
              }

              {(isBuilder && oneOff) &&
                <ImageGallery images={[firstItem.image]} />
              }

              {!isBuilder &&
                <ImageGallery images={[firstItem.image]} clickForFullscreen />
              }
            </>
          }
        </TableCell>
        <TableCell colSpan={2} className={'item-description'}>
          <WysiwygText text={firstItem.getSubstitutedDescription()} />
        </TableCell>
        <TableCell className={'item-quantity'}>
          <BodyText>{salesDoc.summary.getVariantQuantity(firstItem.variantId)}</BodyText>
        </TableCell>
        <TableCell className={'item-rate'}>
          <BodyText>{firstItem.priceMode === 'average' && `${currencySymbol || '$'} ${asCurrencyRateStringCommaSeparated(firstItem.unitPrice, salesDoc.getRounding())}`}</BodyText>
        </TableCell>
        <TableCell className={'item-total'}>
          <BodyText dark>{`${currencySymbol || '$'} ${asCurrencyStringCommaSeparated(salesDoc.summary.getVariantRevenue(firstItem.variantId))}`}</BodyText>
        </TableCell>
      </TableRow>

      {showVariantBreakdown &&
        <>
          <TableRow className={'variant-breakdown-toggle-button'}>
            <TableCell className={'pdf-view-hidden'} colSpan={5}>
              <ButtonTheme color={salesDoc.template.showHideDetailsButtonsColor}>
                <Button
                  actionStandard
                  text={`${showItemDetails ? 'Hide' : 'Show'} Details`}
                  onClick={() => setShowItemDetails(!showItemDetails)}
                />
              </ButtonTheme>
            </TableCell>
          </TableRow>
          <TableRow className={'item-row'}>
            <TableCell colSpan={5}>
              <Collapse in={showItemDetails || pdfLoading} collapsedSize={40}>
                <Table className={['variant-breakdown-table', (showItemDetails || pdfLoading) ? 'show' : 'hide']} labels={['', '', '', '']}>
                  <TableRow className={'variant-breakdown-header'}>
                    <TableCell className={'item-size'}>
                      <CaptionText>Size</CaptionText>
                    </TableCell>
                    <TableCell className={'item-color'}>
                      <CaptionText>Color</CaptionText>
                    </TableCell>
                    <TableCell className={'item-quantity'}>
                      <CaptionText>Quantity</CaptionText>
                    </TableCell>
                    <TableCell className={'item-rate'}>
                      <CaptionText>Rate (Ea)</CaptionText>
                    </TableCell>
                    <TableCell className={'item-total'}>
                      <CaptionText>Total</CaptionText>
                    </TableCell>
                  </TableRow>
                  {items.map((prodItem) => (
                    <TableRow key={prodItem.itemId} className={'variant-breakdown-details'}>
                      <TableCell className={'item-size'}>
                        <BodyText>{prodItem.size}</BodyText>
                      </TableCell>
                      <TableCell className={'item-color'}>
                        <BodyText>{prodItem.color}</BodyText>
                      </TableCell>
                      <TableCell className={'item-quantity'}>
                        <BodyText>{prodItem.quantity}</BodyText>
                      </TableCell>
                      <TableCell className={'item-rate'}>
                        <BodyText>{`${currencySymbol || '$'} ${asCurrencyRateStringCommaSeparated(prodItem.unitPrice, salesDoc.getRounding())}`}</BodyText>
                      </TableCell>
                      <TableCell className={'item-total'}>
                        <BodyText>{`${currencySymbol || '$'} ${asCurrencyStringCommaSeparated(prodItem.subTotal)}`}</BodyText>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      }
    </Table>
  );
}

function DecorationBuilderItem({item, groupId, index}) {
  const {setSelection} = usePageContext();

  const handleCopyItem = useCallback(() => {
    const doc = item.copy();
    if (doc.newItem) {
      setSelection({section: SalesDocSections.decoration, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId, variantId: doc.newItem.variantId});
    }
  }, [setSelection, item]);

  const handleDeleteItem = useCallback(() => {
    item.delete();
  }, [item]);

  const hiddenFromCustomer = !item.showItem && item.rollupSellPrice;

  return (
    <Draggable draggableId={item.itemId} index={index} key={item.itemId}>
      <SalesDocSection
        key={item.itemId}
        section={SalesDocSections.decoration}
        itemId={item.itemId}
        groupId={groupId}
        className={[hiddenFromCustomer && 'item-hidden-from-customer', 'item-section', 'hover-tools-container']}
      >
        {hiddenFromCustomer &&
          <div className={'visibility-off-icon'}>
            <ToolTip tip={<span style={{whiteSpace: 'pre-line'}}>{'This item will not be shown \n to your customer.'}</span>} right>
              <VisibilityOffIcon fontSize='small' />
            </ToolTip>
          </div>
        }
        <HoverTools className={['section-selected-show', 'item-drag-anchor', 'show-on-drag']} selectable >
          <DragIndicator />
        </HoverTools>
        <HoverTools className={['section-selected-show', 'item-action-buttons', 'hide-on-drag']} selectable >
          <CopyIcon onClick={handleCopyItem} />
          <DeleteIcon onClick={handleDeleteItem} />
        </HoverTools>
        <AddMoreItemsHoverMenu groupId={item.groupId} itemId={item.itemId} />
        {item.type === SalesDocItem.Type.DECORATION_PLACEHOLDER
          ? <SalesDocEmptyItemView />
          : <DecorationItemView item={item} />
        }
      </SalesDocSection>
    </Draggable>
  );
}

function DecorationItemView({item}) {
  const {salesDoc, currencySymbol, isBuilder} = usePageContext();

  return (
    <Table className={'decoration-item-table'} labels={['', '', '', '', '']}>
      <TableRow className={'item-row pdf-no-break'}>
        <TableCell className={'item-image'}>
          {item?.image &&
            <>
              {isBuilder &&
                <ImageGallery images={[item.image]} />
              }
              {!isBuilder &&
                <ImageGallery images={[item.image]} clickForFullscreen />
              }
            </>
          }
        </TableCell>
        <TableCell colSpan={3} className={'item-description'}>
          <WysiwygText text={item.getSubstitutedDescription()} />
        </TableCell>
        <TableCell className={'item-quantity'}>
          <BodyText>{item.quantity}</BodyText>
        </TableCell>
        <TableCell className={'item-rate'}>
          <BodyText>{item.rollupSellPrice ? 'Included' : `${currencySymbol || '$'} ${asCurrencyRateStringCommaSeparated(item.unitPrice, salesDoc.getRounding())}`}</BodyText>
        </TableCell>
        <TableCell className={'item-total'}>
          <BodyText dark>{item.rollupSellPrice ? '' : `${currencySymbol || '$'} ${asCurrencyStringCommaSeparated(item.subTotal)}`}</BodyText>
        </TableCell>
      </TableRow>
    </Table>
  );
}

function AdditionalCostBuilderItem({item, groupId, index}) {
  const {setSelection} = usePageContext();

  const handleCopyItem = useCallback(() => {
    const doc = item.copy();
    if (doc.newItem) {
      setSelection({section: SalesDocSections.additionalCost, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId, variantId: doc.newItem.variantId});
    }
  }, [setSelection, item]);

  const handleDeleteItem = useCallback(() => {
    item.delete();
  }, [item]);

  const hiddenFromCustomer = (!item.showItem && item.rollupSellPrice) || item.applyAtCheckout;
  const hiddenMessage = !item.showItem && item.rollupSellPrice
    ? 'This item will not be shown \n to your customer'
    : 'This item will be shown to your \n customer when they check out online';

  return (
    <Draggable draggableId={item.itemId} index={index} key={item.itemId}>
      <SalesDocSection
        key={item.itemId}
        section={SalesDocSections.additionalCost}
        itemId={item.itemId}
        groupId={groupId}
        className={[hiddenFromCustomer && 'item-hidden-from-customer', 'item-section', 'hover-tools-container']}
      >
        {hiddenFromCustomer &&
          <div className={'visibility-off-icon'}>
            <ToolTip tip={<span style={{whiteSpace: 'pre-line'}}>{hiddenMessage}</span>} right>
              {!item.showItem && item.rollupSellPrice &&
                <VisibilityOffIcon fontSize='small' />
              }
              {(item.showItem || !item.rollupSellPrice) &&
                <ShoppingCartIcon fontSize='small' />
              }
            </ToolTip>
          </div>
        }
        <HoverTools className={['section-selected-show', 'item-drag-anchor', 'show-on-drag']} selectable >
          <DragIndicator />
        </HoverTools>
        <HoverTools className={['section-selected-show', 'item-action-buttons', 'hide-on-drag']} selectable >
          {!item.isDecorationSetupCost() &&
            <CopyIcon onClick={handleCopyItem} />
          }
          <DeleteIcon onClick={handleDeleteItem} />
        </HoverTools>

        <AddMoreItemsHoverMenu groupId={item.groupId} itemId={item.itemId} />
        <AdditionalCostItemView item={item} />
      </SalesDocSection>
    </Draggable>
  );
}

function AdditionalCostItemView({item}) {
  const {salesDoc, currencySymbol, isBuilder} = usePageContext();

  return (
    <Table className={'additional-cost-item-table'} labels={['', '', '', '', '']}>
      <TableRow className={'item-row pdf-no-break'}>
        <TableCell className={'item-image'} rowSpan={4}>
          {item?.image &&
            <>
              {isBuilder &&
                <ImageGallery images={[item.image]} />
              }
              {!isBuilder &&
                <ImageGallery images={[item.image]} clickForFullscreen />
              }
            </>
          }
        </TableCell>
        <TableCell colSpan={3} className={'item-description'}>
          <WysiwygText text={item.getSubstitutedDescription()} />
        </TableCell>
        <TableCell className={'item-quantity'}>
          <BodyText>{item.quantity}</BodyText>
        </TableCell>
        <TableCell className={'item-rate'}>
          <BodyText>{item.rollupSellPrice ? 'Included' : `${currencySymbol || '$'} ${asCurrencyRateStringCommaSeparated(item.unitPrice, salesDoc.getRounding())}`}</BodyText>
        </TableCell>
        <TableCell className={'item-total'}>
          <BodyText dark>{item.rollupSellPrice ? '' : `${currencySymbol || '$'} ${asCurrencyStringCommaSeparated(item.subTotal)}`}</BodyText>
        </TableCell>
      </TableRow>
    </Table>
  );
}

const SalesDocEmptyItemView = () => (
  <Table className={'empty-item-table'} labels={['', '', '', '', '']}>
    <TableRow className={'item-row pdf-no-break'}>
      <TableCell className={'item-image'}>
        <RectangularSkeleton rounded animation={false} width={100} height={100} />
      </TableCell>
      <TableCell colSpan={2} className={'item-description'}>
        <TextSkeleton animation={false} width={221} />
        <TextSkeleton animation={false} width={104} />
      </TableCell>
      <TableCell className={'item-quantity'}>
        <TextSkeleton animation={false} width={104} />
      </TableCell>
      <TableCell className={'item-rate'}>
        <TextSkeleton animation={false} width={104} />
      </TableCell>
      <TableCell className={'item-total'}>
        <TextSkeleton animation={false} width={104} />
      </TableCell>
    </TableRow>
    <TableRow></TableRow>
  </Table>
);

const SalesDocGroupPlaceholder = ({groupId}) => (
  <div className={'group-items-container'}>
    {/* This standalone table renders the header that applies to variant and non-variant tables */}
    <Table className={'group-items-table'} labels={['', 'Item', '', 'Quantity', 'Rate (Ex Tax)', 'Total']} />
    <Table
      className={'group-items-table hide-header'}
      labels={['', '', '', '', '', '']}
    >
      <TableRow>
        <TableCell colSpan={6} className={'group-items'}>
          <SalesDocSection section={SalesDocSections.groupSection} groupId={groupId}>
            <SalesDocEmptyItemView />
          </SalesDocSection>
        </TableCell>
      </TableRow>
    </Table>
  </div>
);

function GroupActionsHover({groupId}) {
  const {salesDoc, setSelection} = usePageContext();

  const onGroupSelection = useCallback(() => {
    setSelection({section: SalesDocSections.groupHeaderSection, groupId: groupId});
  }, [groupId, setSelection]);

  const handleCopyGroup = useCallback(() => {
    const doc = salesDoc.copyGroup(groupId);
    if (doc.newItem) {
      setSelection({section: SalesDocSections.groupHeaderSection, groupId: doc.newItem.groupId});
    }
  }, [salesDoc, groupId, setSelection]);

  const handleDeleteGroup = useCallback(() => {
    setSelection({});
    salesDoc.deleteGroup(groupId);
  }, [salesDoc, groupId, setSelection]);

  return (
    <Row className={'group-actions-hover hover-tools-container'} onClick={onGroupSelection} >
      <HoverTools className={['group-drag-anchor', 'show-on-drag']} >
        <DragIndicator />
      </HoverTools>
      <HoverTools className={['group-revenue-button', 'hide-on-drag']} >
        <Button actionPrimary text='VIEW GROUP REVENUE SUMMARY' onClick={onGroupSelection} />
      </HoverTools>
      <HoverTools className={['group-action-buttons', 'hide-on-drag']} >
        <CopyIcon onClick={handleCopyGroup} />
        <DeleteIcon onClick={handleDeleteGroup} />
      </HoverTools>
    </Row>
  );
}

function SalesDocTotalsView() {
  const {salesDoc, currencySymbol} = usePageContext();

  // Get the totals from the document, and if it is paid, add any checkout surcharges and discounts
  let subTotal = salesDoc.subTotal;
  let taxTotal = salesDoc.taxTotal;
  let total = salesDoc.total;
  if (salesDoc.onlinePaymentStatus === 'successful') {
    subTotal += salesDoc.summary.checkoutExtras.subTotal;
    taxTotal += salesDoc.summary.checkoutExtras.taxTotal;
    total += salesDoc.summary.checkoutExtras.total;
  }

  return (
    <Row className={'salesdoc-totals pdf-no-break'}>
      <Column>
        <BodyText>Subtotal</BodyText>
        <BodyText>Tax</BodyText>
        <HeadingText x20 normal>Total</HeadingText>
      </Column>
      <Column>
        <BodyText>{`${currencySymbol || '$'} ${asCurrencyStringCommaSeparated(subTotal)}`}</BodyText>
        <BodyText>{`${currencySymbol || '$'} ${asCurrencyStringCommaSeparated(taxTotal)}`}</BodyText>
        <HeadingText x20>{`${currencySymbol || '$'} ${asCurrencyStringCommaSeparated(total)}`}</HeadingText>
      </Column>
    </Row>
  );
}

function SalesDocTermsView() {
  const {salesDoc} = usePageContext();
  return (
    <Row className={'salesdoc-terms pdf-break-container'}>
      <Column fillWidth>
        <Row>
          <Column>
            <HeadingText x20>Terms:</HeadingText>
          </Column>
        </Row>
        <Row>
          <Column className={'salesdoc-terms-box pdf-break-container'} fillWidth>
            <span className={'pdf-break-container'} dangerouslySetInnerHTML={{__html: salesDoc.terms}} />
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

function SalesDocTermsAndTotals() {
  const {salesDoc} = usePageContext();
  return (
    <>
      {!salesDoc.template.hideTotals &&
        <SalesDocTotalsView />
      }
      <SalesDocTermsView />
    </>
  );
}

function AddMoreItemsHoverMenu({groupId, variantId, itemId}) {
  const {sage, salesDoc, selection, setSelection} = usePageContext();

  const showThingsToolbox = useCallback(() => {
    setSelection({section: SalesDocSections.groupSection, groupId: groupId, itemId: itemId, variantId: variantId});
  }, [setSelection, groupId, variantId, itemId]);

  const variantSelected = salesDoc.getFirstVariantItem(variantId);
  const itemSelected = salesDoc.getItem(itemId);

  const selected = variantSelected ?? itemSelected ?? salesDoc.getFirstItemInGroup(selection.groupId);

  const {
    addAdditionalCost,
    addDecoration,
    addDiscount,
    addOneOffDecoration,
    addOneOffProduct,
    addProduct,
    addSageProduct,
    addShipping,
    addSurcharge,
  } = useThingsMethods(selected);

  return (
    <HoverTools className={'add-more-items-menu hide-on-drag'} selectable>
      <Button
        actionPrimary
        prefix={AddCircle}
        text='Add more items into this Group'
        onClick={() => showThingsToolbox({groupId: groupId, variantId: variantId, itemId: itemId})}
      />

      <Divider orientation='vertical' variant='middle' flexItem className={'salesdoc-divider-vertical'} />

      <ToolTip top tip={'Catalog Product'}>
        <CatalogIcon onClick={addProduct} />
      </ToolTip>

      <ToolTip top tip={'One Off Product'}>
        <OneOffProductIcon onClick={addOneOffProduct} />
      </ToolTip>

      {sage &&
        <ToolTip top tip={'SAGE Product'}>
          <div className={'sage-icon'} onClick={addSageProduct}><img src={SAGE_LOGO} alt={'SAGE'} /></div>
        </ToolTip>
      }

      <Divider orientation='vertical' variant='middle' flexItem className={'salesdoc-divider-vertical'} />

      <ToolTip top tip={'Catalog Decoration'}>
        <DecorationIcon fontSize={'small'} onClick={addDecoration} />
      </ToolTip>

      <ToolTip top tip={'One Off Decoration'}>
        <OneOffDecorationIcon onClick={addOneOffDecoration} />
      </ToolTip>

      <Divider orientation='vertical' variant='middle' flexItem className={'salesdoc-divider-vertical'} />

      <ToolTip top tip={'Additional Cost'}>
        <AdditionalCostIcon onClick={addAdditionalCost} />
      </ToolTip>

      <ToolTip top tip={'Shipping'}>
        <ShippingIcon onClick={addShipping} />
      </ToolTip>

      {!salesDoc.isPresentation() &&
        <>
          <ToolTip top tip={'Add a percentage (%) Discount'}>
            <Button actionPrimary prefix={'arrows_more_down'} onClick={addDiscount} />
          </ToolTip>

          <ToolTip top tip={'Add a percentage (%) Surcharge'}>
            <Button actionPrimary prefix={'arrows_more_up'} onClick={addSurcharge} />
          </ToolTip>
        </>
      }

    </HoverTools>
  );
}
