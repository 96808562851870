import React from 'react';
import {Placement} from '../../../componentsLib/Popovers';
import {InfoTip} from '../../../componentsLib/Basic';
import {registerGlobalStyle} from '../../../theme';

registerGlobalStyle('.purchase-order-alert', () => ({
  '&.tooltip-container': {
    position: 'absolute',
    width: 'unset',
    height: 'unset',
    padding: 0,
  },
}));

const TIP_OFFSET = [-14, 0];

export const Alerts = {PRICE_MISMATCH: 'Identical variants have different price overrides',};

export function PurchaseOrderAlert({tip}) {
  return (
    <InfoTip className={'purchase-order-alert'} alert tip={tip} offset={TIP_OFFSET} placement={Placement.TopStart}/>
  );
}
