import {randomString} from '../utils';

export class ItemizedDocument {
  _notify;
  _pauseNotify = 0;
  template;
  items;

  constructor() {
  }

  copyWith(that) {
    return new this.constructor.prototype.constructor({...this, ...that, template: {...this.template, ...that.template}}).recalculate().notify();
  }

  recalculate() {
    return this;
  }

  notify(options = {}) {
    if (this._notify && this._pauseNotify === 0) {
      this._notify(this, options);
    }
    return this;
  }

  pauseNotify() {
    this._pauseNotify++;
    return this;
  }

  resumeNotify() {
    this._pauseNotify--;
    return this;
  }

  setNotify(notify) {
    this._notify = notify;
    return this;
  }

  getFirstItemInGroup(groupId) {
    return this.items.find((item) => item.groupId === groupId);
  }

  getFirstVariantItem(variantId) {
    return this.items.find((item) => variantId != null && item.variantId === variantId);
  }

  getGroupIds() {
    return this.items.map(({groupId}) => groupId).toUniq();
  }

  getItem(itemId) {
    return itemId ? this.items.find((item) => item.itemId === itemId) : null;
  }

  getItemsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId);
  }

  getLastItemInGroup(groupId) {
    return this.items.findLast((item) => item.groupId === groupId);
  }

  getNonProductVariantsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId && item.isNonProductVariant());
  }

  getProductVariantIdsInGroup(groupId) {
    return this.items
      .filter((item) => item.groupId === groupId && item.isProductVariant())
      .map(({variantId}) => variantId)
      .toUniq();
  }

  getVariantItems(variantId) {
    return this.items.filter((item) => variantId != null && item.variantId === variantId);
  }

  replaceItem(itemToReplace, newItem) {
    return this.copyWith({items: this.items.toSpliced(this.items.indexOf(itemToReplace), 1, newItem)});
  }

  updateItem(itemToReplace, update) {
    return this.replaceItem(itemToReplace, itemToReplace.copyWith(update));
  }

  static makeDecorationId(setupCostItemId) {
    if (setupCostItemId.endsWith(':setup')) {
      return setupCostItemId.substring(0, setupCostItemId.length - 6);
    }
    return null;
  }

  static makeDecorationSetupCostId(decorationItemId) {
    return `${decorationItemId}:setup`;
  }

  static newDraftId() {
    return `draft:${randomString()}`;
  }

  static newItemId() {
    return `item:${randomString()}`;
  }

  static newGroupId() {
    return `group:${randomString()}`;
  }

  static newVariantId() {
    return `v:${randomString()}`;
  }
}

export class ItemizedDocumentItem {
  static Type = {
    VARIANT: 'variant',
    DECORATION: 'decoration',
    ADDITIONAL_COST: 'additionalCost',

    // Placeholder item types
    GROUP_PLACEHOLDER: 'groupPlaceholder',
    PRODUCT_PLACEHOLDER: 'productPlaceholder',
    DECORATION_PLACEHOLDER: 'decorationPlaceholder',
  };

  doc;

  itemId;
  type;

  constructor() {
  }

  copyWith(that) {
    return new this.constructor.prototype.constructor({...this, ...that});
  }

  getDecorationItem() {
    return this.doc.getItem(this.getDecorationItemId());
  }

  getDecorationItemId() {
    if (this.isAdditionalCost()) {
      return ItemizedDocument.makeDecorationId(this.itemId);
    }
    return null;
  }

  getSetupCostItem() {
    return this.type === ItemizedDocumentItem.Type.DECORATION ? this.doc.getItem(ItemizedDocument.makeDecorationSetupCostId(this.itemId)) : null;
  }

  hasSetupCostItem() {
    return this.getSetupCostItem() != null;
  }

  isAdditionalCost() {
    return this.type === ItemizedDocumentItem.Type.ADDITIONAL_COST;
  }

  isDecoration() {
    return this.type === ItemizedDocumentItem.Type.DECORATION || this.type === ItemizedDocumentItem.Type.DECORATION_PLACEHOLDER;
  }

  isDecorationSetupCost() {
    return this.getDecorationItemId() != null;
  }

  isNonProductVariant() {
    return this.type !== ItemizedDocumentItem.Type.VARIANT && this.type !== ItemizedDocumentItem.Type.PRODUCT_PLACEHOLDER;
  }

  isPlaceholder() {
    switch (this.type) {
      case ItemizedDocumentItem.Type.DECORATION_PLACEHOLDER:
      case ItemizedDocumentItem.Type.GROUP_PLACEHOLDER:
      case ItemizedDocumentItem.Type.PRODUCT_PLACEHOLDER:
        return true;

      default:
        return false;
    }
  }

  isProductVariant() {
    return this.type === ItemizedDocumentItem.Type.VARIANT || this.type === ItemizedDocumentItem.Type.PRODUCT_PLACEHOLDER;
  }
}
