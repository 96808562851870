import React, {useCallback, useState} from 'react';
import {TextInput} from '.';
import {asNumber} from '../../utils';
import {HoopsPropTypes} from '../utils';

export function IntegerInput({className, value, onBlur, onChange, onChangeValue, onFocus, ...props}) {
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(asNumber(value));

  const handleChange = useCallback((e) => {
    const newValue = asNumber(e.target.value);
    onChange?.(e, newValue);
    onChangeValue?.(newValue);
    setInputValue(e.target.value);
  }, [onChange, onChangeValue]);

  const handleFocus = useCallback((e) => {
    setFocused(true);
    onFocus?.(e);
  }, [onFocus]);

  const handleBlur = useCallback((e) => {
    setFocused(false);
    setInputValue(asNumber(inputValue).toString());
    onBlur?.(e);
  }, [inputValue, onBlur]);

  if (!focused || asNumber(value) !== asNumber(inputValue)) {
    value = value == null ? '' : value.toString();
  } else {
    value = inputValue;
  }

  const handleKeyDown = useCallback((e) => {
    if (e.code === 'Period') {
      e.stopPropagation();
      e.preventDefault();
    }
  }, []);

  return (
    <TextInput
      className={[className, 'number-input']}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      type={'number'}
      value={value == null  || value === '' ? '' : asNumber(value)}
      {...props}
    />);
}

IntegerInput.propTypes = {
  className: HoopsPropTypes.className,
  autoFocus: HoopsPropTypes.bool,
  autoSize: HoopsPropTypes.bool,
  inplaceEdit: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  readOnly: HoopsPropTypes.bool,
  right: HoopsPropTypes.bool,
  selectOnFocus: HoopsPropTypes.bool,
  step: HoopsPropTypes.number,
  value: HoopsPropTypes.stringOrNumber,
  onChangeValue: HoopsPropTypes.func,
  onBlur: HoopsPropTypes.func,
  onChange: HoopsPropTypes.func,
  onFocus: HoopsPropTypes.func,
};
