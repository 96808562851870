import React, {useCallback, useLayoutEffect, useRef} from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';
import {Button} from '../Basic';
import {ExpandMore as DownArrowIcon} from '@mui/icons-material';

registerGlobalStyle('.expansion-panel', (theme) => ({
  display: 'block',
  position: 'relative',
  transition: theme.transitions.out.height,
  minHeight: 1, // This ensures that we will get a transition end event
  '&.collapsed': {
    height: 0,
    minHeight: 0,
    position: 'absolute',
    visibility: 'hidden',
    overflow: 'hidden',
  },
  '&.transitioning': {
    position: 'relative',
    overflow: 'hidden',
    visibility: 'visible',
  },
}));

registerGlobalStyle('.expansion-button', () => ({
  'svg': {transform: 'rotate3d(0, 0, 0, 180deg)'},
  '&.expanded svg': {transform: 'rotate3d(1, 0, 0, 180deg) translateY(1px)',},
}));

export function ExpansionPanel({className, expanded, onEndCollapse, onEndExpand, children}) {
  const elemRef = useRef(null);
  const stateRef = useRef({
    prevExpanded: expanded,
    height: null,
  });

  useLayoutEffect(() => {
    const state = stateRef.current;
    if (elemRef.current) {
      if (expanded) {
        if (state.height === null) {
          // If we don't know the height yet, force a recalculation and remember it
          elemRef.current.style.height = 'unset';
          const height = elemRef.current.getBoundingClientRect().height;
          if (height > 0) {
            state.height = height;
          }
        }
        // To transition the expansion, set height to 0, then force a reflow so the 0 height is
        // considered current, then set the height to the calculated height, so it will animate.
        elemRef.current.style.height = '0';
        void(elemRef.current.getBoundingClientRect().height);
        elemRef.current.style.height = `${state.height}px`;
      } else {
        // To transition the collapse, set the height to the calculated height, then force a reflow
        // so that height is considered current, then set the height to 0, so it will animate.
        elemRef.current.style.height = `${state.height}px`;
        void(elemRef.current.getBoundingClientRect().height);
        elemRef.current.style.height = '0px';
      }
    }
  }, [expanded]);

  const handleTransitionEnd = useCallback((e) => {
    if (e.propertyName === 'height') {
      stateRef.current.prevExpanded = expanded;
      elemRef.current.style.removeProperty('height');
      elemRef.current.classList.remove('transitioning');
      if (expanded) {
        onEndExpand?.(e);
      } else {
        onEndCollapse?.(e);
      }
    }
  }, [expanded, onEndCollapse, onEndExpand]);

  return (
    <div
      className={classNames([
        className,
        'expansion-panel',
        expanded ? 'expanded' : 'collapsed',
        expanded !== stateRef.current.prevExpanded && 'transitioning'
      ])}
      ref={elemRef}
      onTransitionEnd={handleTransitionEnd}
    >
      {children}
    </div>
  );
}

ExpansionPanel.propTypes = {
  className: HoopsPropTypes.className,
  expanded: HoopsPropTypes.bool,
  onEndCollapse: HoopsPropTypes.func,
  onEndExpand: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function ExpansionButton({className, disabled, expanded, name, unstyled, onExpandedChange, onClick}) {
  const handleClick = useCallback((e) => {
    onClick?.(e);
    onExpandedChange?.(!expanded);
  }, [expanded, onExpandedChange, onClick]);

  return (
    <Button
      className={[className, 'expansion-button', expanded && 'expanded', !expanded && 'collapsed']}
      navUnstyled={unstyled}
      name={name}
      disabled={disabled}
      prefix={DownArrowIcon}
      onClick={handleClick}
    />
  );
}

ExpansionButton.propTypes = {
  className: HoopsPropTypes.className,
  disabled: HoopsPropTypes.bool,
  expanded: HoopsPropTypes.bool,
  name: HoopsPropTypes.string,
  unstyled: HoopsPropTypes.bool,
  onClick: HoopsPropTypes.func,
  onExpandedChange: HoopsPropTypes.func,
};
