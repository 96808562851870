import React, {useEffect, useRef} from 'react';
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import {Controller, useFormContext} from 'react-hook-form';
import ControlledAutocomplete from './ControlledAutocomplete';
import {capitalize} from '../../utils';

const useStyles = makeStyles((theme) => ({
  greyText: {color: theme.colors.grey.main,},
  selectClass: {minWidth: 184,},
  optionTextAny: {fontStyle: 'italic',},
}));

export function AutomationTriggerConfigCustomStatusChange({viewData, entityType}) {
  const classes = useStyles();
  const {control, watch, setValue} = useFormContext();
  const jobStatus = watch('statusType');
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setValue('status', []);
  }, [jobStatus, setValue]);

  //list job status fields
  const jobStatusOptions = viewData?.fields
    ?.filter(({type}) => type === 'STATUS')
    .map(({title, path, legacyPath}) => ({value: entityType === 'job' ? legacyPath : path, label: title})) ?? [];

  //list job statuses of selected job status type
  const options = viewData?.fields
    ?.find(({path, legacyPath}) => path === jobStatus || legacyPath === jobStatus)
    ?.options.map(({statusLabel}) => ({value: statusLabel, label: statusLabel})) || [];
  options.unshift({value: '*', label: 'Anything'});

  return (
    <Grid container direction='row' justifyContent='center' alignItems='flex-end' spacing={1}>
      <Grid item>
        <Typography variant='h5' className={classes.greyText}>If the </Typography>
      </Grid>
      <Grid item>
        <FormControl>
          <InputLabel id='status-label'>Select status</InputLabel>
          <Controller
            name='statusType'
            defaultValue=''
            control={control}
            render={(props) =>
              <Select
                inputProps={{className: classes.selectClass}}
                labelId='status-label'
                {...props}
              >
                {jobStatusOptions.map((option, optIdx) =>
                  <MenuItem key={optIdx} value={option.value}>{option.label}</MenuItem>
                )}
              </Select>
            }

          />
        </FormControl>
      </Grid>
      <Grid item>
        <Typography variant='h5' className={classes.greyText}>status is changed to </Typography>
      </Grid>
      <Grid item>
        <ControlledAutocomplete
          control={control}
          name='status'
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          renderOption={(option, {selected}) => (
            <>
              <Checkbox
                color='primary'
                style={{marginRight: 8}}
                checked={selected}
              />
              <span
                className={option.label === 'Anything' && classes.optionTextAny}>{capitalize(option.label)}</span>
            </>
          )}
          renderInput={(params) => (
            <TextField {...params} label='Select' placeholder='Select'/>
          )}
          multiple={true}
          disableCloseOnSelect={true}
          defaultValue={[]}
        />
      </Grid>
    </Grid>

  );
}
