import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {Content, EdgeSidebar, EdgeTrigger, Header, Root, SidebarContent} from '@mui-treasury/layout';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import {IconButton, ButtonBase, Container} from '@mui/material';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import AppSidenav from '../../componentsOld/AppSidenav';
import Modals from '../../componentsOld/Modals';
import Reporting from '../../componentsOld/reporting/Reporting';
import TrialSubscriptionModal from '../../componentsOld/TrialSubscriptionModal';
import UnpaidSubscriptionModal from '../../componentsOld/UnpaidSubscriptionModal';
import {AccessDenied, roles, PermissionBasedRoute, MultiSessionNotification, UpdateVersionBanner} from '../../componentsHoops/AccessControl';
import ScheduleDashboard from '../../modules/schedule/ScheduleDashboard';
import TaskManyViewPage from '../../modules/task/TaskManyViewPage';
import TaskPage from '../../modules/task/TaskPage';
import theme from '../../ui/theme/index';
import Catalog from '../catalog';
import Customers from '../customers';
import Settings from '../settings';
import {JobsRoutes, SalesDocsRoutes} from '../../pages/SalesDoc';
import {PurchaseOrderRoutes} from '../../pages/Purchasing/Routes/PurchaseOrdersRoutes';
import {featureFlags} from '../../utils/featureFlag';
import {JobsLegacyRoutes, PurchaseOrdersLegacyRoutes, QuotesClassicRoutes} from './LegacyRoutes';
import {JobsClassicRoutes} from './LegacyRoutes/JobsClassicRoutes';

const useStyles = makeStyles(() => ({
  header: {color: theme.palette.secondary.contrastText,},
  menuBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    margin: 4
  },
  collapseBtn: {
    color: '#fff',
    minWidth: 0,
    width: 40,
    borderRadius: '50%',
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0.24)',
    margin: '0 auto 16px',
    '&:hover': {backgroundColor: 'rgba(0,0,0,0.38)',},
  },
  sidebarContent: {
    border: 'none',
    overflowX: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  container: {
    padding: theme.spacing(3),
    flex: '1 1 0',
    display: 'flex !important',
    flexDirection: 'column',
    position: 'relative',
  },
}));

export const APP_BASENAME = '/';

export function AppRoutes() {
  const classes = useStyles();
  const company = useSelector((state) => state.companyReducer.company);
  const user = useSelector((state) => state.authReducer.userData);

  // this line removes the X scrollbar from body in the app
  document.body.style.overflowX = 'hidden';

  const {admin, standard, lowAccess} = roles;

  return (
    <BrowserRouter basename={APP_BASENAME}>
      <Root scheme={{
        header: {
          config: {
            xs: {
              position: 'relative',
              height: 56,
              backgroundColor: theme.palette.primary.main,
            },
          },
          hidden: ['md', 'lg', 'xl']
        },
        leftEdgeSidebar: {
          config: {
            xs: {
              variant: 'temporary',
              width: 'auto',
            },
            md: {
              variant: 'permanent',
              width: 232,
              collapsible: true,
              collapsedWidth: 64,
            },
          },
        },

      }}
      >
        <TrialSubscriptionModal />
        <UnpaidSubscriptionModal />
        <Modals />
        <MultiSessionNotification/>

        <Header
          sx={{
            borderBottom: '1px solid',
            borderColor: 'divider',
            backgroundColor: theme.palette.primary.main,
          }}
          elevation={2}
          className={classes.header}
        >
          <EdgeTrigger target={{anchor: 'left', field: 'open'}}>
            {(open, setOpen) => (
              <IconButton onClick={() => setOpen(!open)} size='large'>
                {open ? <KeyboardArrowLeftIcon /> : <MenuIcon className={classes.menuBtn} />}
              </IconButton>
            )}
          </EdgeTrigger>
        </Header>

        {/* Left Menu */}
        <EdgeSidebar anchor='left' sx={{'.MuiPaper-root': {boxShadow: 'none'}}}>
          {(props) => (
            <>
              <SidebarContent className={classes.sidebarContent} style={{overflow: 'hidden'}}>
                <AppSidenav collapsed={props.state.leftEdgeSidebar.collapsed} />
              </SidebarContent>

              <EdgeTrigger target={{anchor: 'left', field: 'collapsed'}}>
                {(collapsed, setCollapsed) => (
                  <ButtonBase
                    onClick={() => setCollapsed(!collapsed)}
                    sx={{
                      minHeight: 40,
                      width: '100%',
                      bgcolor: 'grey.100',
                      borderTop: '1px solid',
                      borderColor: 'grey.200',
                    }}
                  >
                    {collapsed ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                  </ButtonBase>
                )}
              </EdgeTrigger>
            </>
          )}
        </EdgeSidebar>

        {company._id && user._id &&
          <Content className={classes.content}>
            <UpdateVersionBanner/>
            <Container maxWidth={false} className={`${classes.container} page-container`}>
              <Switch>
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/catalog'>
                  <Catalog/>
                </PermissionBasedRoute>
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/customers'>
                  <Customers/>
                </PermissionBasedRoute>

                {/* Jobs */}
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path={'/jobs'}>
                  {featureFlags.JobsPOsRebuild.enabled() ? <JobsRoutes/> : <JobsLegacyRoutes/>}
                </PermissionBasedRoute>
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} featureFlag={featureFlags.JobsPOsRebuild} path={'/classic-jobs'}>
                  <JobsClassicRoutes/>
                </PermissionBasedRoute>

                {/* Quotes */}
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path={'/sales'}>
                  {<SalesDocsRoutes/>}
                </PermissionBasedRoute>
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path={'/quotes'}>
                  {<QuotesClassicRoutes/>}
                </PermissionBasedRoute>

                {/* Purchase Orders */}
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} featureFlag={featureFlags.JobsPOsRebuild} path='/purchasing'>
                  <PurchaseOrderRoutes/>
                </PermissionBasedRoute>
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/classic-purchase-orders'>
                  <PurchaseOrdersLegacyRoutes/>
                </PermissionBasedRoute>

                {/* Settings */}
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/settings'>
                  <Settings/>
                </PermissionBasedRoute>

                {/* Tasks */}
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} exact path={'/tasks'}>
                  <TaskManyViewPage/>
                </PermissionBasedRoute>
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path={['/tasks/:entityId', '/app/tasks/:entityId']}>
                  <TaskPage/>
                </PermissionBasedRoute>
                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} exact path={'/schedule'}>
                  <ScheduleDashboard/>
                </PermissionBasedRoute>
                <Route path='/accessdenied'>
                  <AccessDenied/>
                </Route>
                <PermissionBasedRoute allowedRoles={[admin]} path={'/reporting'}>
                  <Reporting/>
                </PermissionBasedRoute>

                <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} exact path={'/schedule'}>
                  <ScheduleDashboard/>
                </PermissionBasedRoute>
                {/* This redirect adjusts a url that comes out of the comments project (repo in git) once the url is changed the normal route security conditions apply */}
                <Redirect from='/quoteV2s/:salesDocId' to='/sales/:salesDocId' />
                {/*  this is the login redirect */}
                {/*  add some comment to easy find later. */}
                {/*  login( */}
                {/*  history.push('/jobs') */}
                <Redirect to={'/jobs'}/>
              </Switch>
            </Container>
          </Content>
        }
      </Root>
    </BrowserRouter>
  );
}
