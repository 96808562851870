import React, {useState} from 'react';
import Lottie from 'react-lottie';
import successAlertIcon from '../../assets/lotties/success-alert-icon.json';
import {HoopsPropTypes} from '../../componentsLib';
import {Link} from '../../componentsLib/Basic';
import {Column} from '../../componentsLib/Layout';
import {ModalDialog} from '../../componentsLib/Popovers';
import {CaptionText} from '../../componentsLib/Text';
import {registerGlobalStyle} from '../../theme';
import {PayOnline} from './PayOnline';

registerGlobalStyle('.stripe-payment-dialog', (theme) => ({
  '.modal-dialog-content': {
    minWidth: 600,
    minHeight: 512,
    width: 600,
    height: 512,
    padding: theme.spacing(0, 4, 4, 4),
  },
  '.payment-success': {padding: theme.spacing(3)},
  '.text': {width: 'unset',},
  '.dialog-title.text': {
    fontSize: '2.25rem',
    fontWeight: theme.fontWeight.normal,
    textAlign: 'center',
  },
  '.text-caption.text': {
    textAlign: 'center',
    fontSize: '1rem',
    margin: 0,
  },
  '#card-element': {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: `1px solid ${theme.colors.border.light}`,
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-notchedOutline': {border: 'none'}
  },
  '.amounts': {
    margin: theme.spacing(2),
    padding: theme.spacing(5, 3, 0, 3),
    '.row': {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '.heading-text': {},
    '.text-body': {fontSize: theme.fontSize(16)},
    '.text-caption': {fontSize: theme.fontSize(14)},
  },
  button: {
    alignSelf: 'center',
    marginBottom: theme.spacing(1),
  },
  '.error-message': {
    marginTop: theme.spacing(1),
    color: theme.colors.palette.red,
    lineHeight: 1.2,
    marginBottom: theme.spacing(-3),
  },
  '.stripe-message': {
    marginTop: theme.spacing(5),
    'svg': {color: theme.colors.text.highlight},
  }
}));

export function PayOnlineDialog({
  amountToPay,
  companyName,
  feesAndCharges,
  invoiceAmount,
  salesDocToken,
  stripeConnectedAccount,
  onClose,
  onSuccess,
}) {
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState();

  const handleSuccessfulPayment = ({invoiceUrl: _invoiceUrl}) => {
    setInvoiceUrl(_invoiceUrl);
    setIsPaymentSuccessful(true);
    onSuccess();
  };

  return (
    <ModalDialog className={'stripe-payment-dialog'} okText={''} cancelText={''} title={'Accept & Pay Online'} onClose={onClose}>
      {!isPaymentSuccessful &&

        <PayOnline
          amountToPay={amountToPay}
          invoiceAmount={invoiceAmount}
          feesAndCharges={feesAndCharges}
          companyName={companyName}
          salesDocToken={salesDocToken}
          onSuccess={handleSuccessfulPayment}
          stripeConnectedAccount={stripeConnectedAccount}
        />

      }
      {isPaymentSuccessful &&
        <div className='payment-success'>
          <Lottie
            options={successLottie}
            height={200}
            width={200}
          />
          <Column alignCenter gap>
            <CaptionText text={'Payment successful'} />
            <CaptionText text={'Thank you'} />
            {invoiceUrl &&
              <Link href={invoiceUrl}>View Invoice/Receipt</Link>
            }
          </Column>
        </div>
      }
    </ModalDialog>
    );
}

PayOnlineDialog.propTypes = {
  amountToPay: HoopsPropTypes.number,
  companyName: HoopsPropTypes.string,
  feesAndCharges: HoopsPropTypes.arrayOfObject,
  invoiceAmount: HoopsPropTypes.number,
  salesDocToken: HoopsPropTypes.string,
  stripeConnectedAccount: HoopsPropTypes.string,
  onClose: HoopsPropTypes.func,
  onSuccess: HoopsPropTypes.func,
};

const successLottie = {
  loop: false,
  autoplay: true,
  animationData: successAlertIcon,
  rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}
};
