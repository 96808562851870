import React, {createContext, useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import NewIcon from '@mui/icons-material/NewReleases';
import ThumbsUp from '@mui/icons-material/ThumbUpAlt';
import {Collapse, Fade} from '@mui/material';
import {Row} from '../../../componentsLib/Layout';
import {Button} from '../../../componentsLib/Basic';
import {useLocalStorageState} from '../../../hooks';
import {featureFlags} from '../../../utils/featureFlag';
import {HoopsPropTypes} from '../../../componentsLib';
import {BodyText} from '../../../componentsLib/Text';
import {getLocalStorageItem} from '../../../utils';
import classNames from 'classnames';
import {FeatureFlag} from '../../../componentsHoops/AccessControl';

export function PurchaseBoardBanner() {
  const {alwaysHideBanner, setAlwaysHideBanner, setPreferNewPurchaseBoard, usingNewPurchaseBoard} = useNewPurchaseBoardBannerContext();
  const history = useHistory();

  const handleSwitchEditor = useCallback(() => {
    if (usingNewPurchaseBoard) {
      history.replace('/classic-purchase-orders');
    } else {
      history.replace('/purchasing');
    }
  }, [history, usingNewPurchaseBoard]);

  const handleGotIt = useCallback(() => {
    setPreferNewPurchaseBoard(usingNewPurchaseBoard);
    setAlwaysHideBanner(true);
  }, [usingNewPurchaseBoard, setAlwaysHideBanner, setPreferNewPurchaseBoard]);

  return (
    <FeatureFlag disallow={featureFlags.disallowOldQuoting}>
      <Row className={usingNewPurchaseBoard ? 'purchaseboard-banner new' : 'purchaseboard-banner old'}>
        <Collapse in={!alwaysHideBanner}>
          <Row className={'banner-content'} alignCenter pad gap>
            <BodyText>
              We&apos;ve updated Purchase Orders to integrate seamlessly with SalesDocs. We hope you like the new features, but you can continue to use the classic Purchase Orders if you prefer.
            </BodyText>
            <Button className={'got-it'} unstyled onClick={handleGotIt} text={'Got it'} suffix={ThumbsUp} noWrap/>
            <Button unstyled onClick={handleSwitchEditor} noWrap>
              {usingNewPurchaseBoard ? 'Use classic Purchase Orders' : 'Use PurchaseBoard'}
            </Button>
          </Row>
        </Collapse>
      </Row>
    </FeatureFlag>
  );
}

export function ShowPurchaseBoardBannerButton() {
  const {alwaysHideBanner, setAlwaysHideBanner, usingNewPurchaseBoard} = useNewPurchaseBoardBannerContext();

  const handleShowBanner = useCallback(() => {
    setAlwaysHideBanner(false);
  }, [setAlwaysHideBanner]);

  return (
    <FeatureFlag disallow={featureFlags.disallowOldQuoting}>
      <Fade in={alwaysHideBanner}>
        <div className={classNames(['show-purchaseboard-button', usingNewPurchaseBoard ? 'new' : 'old'])}>
          <Button onClick={handleShowBanner} prefix={NewIcon}/>
        </div>
      </Fade>
    </FeatureFlag>
  );
}

const Context = createContext(null);
const useNewPurchaseBoardBannerContext = () => useContext(Context);

export function isUsingPurchaseBoard() {
  return getLocalStorageItem('purchaseboard|preferPurchaseBoard', true);
}

export function NewPurchaseBoardBannerProvider({children}) {
  const [alwaysHideBanner, setAlwaysHideBanner] = useLocalStorageState('purchaseboard|alwaysHideBanner', false);
  const [preferNewPurchaseBoard, setPreferNewPurchaseBoard] = useLocalStorageState('purchaseboard|preferPurchaseBoard', true);

  const value = useMemo(() => ({
    alwaysHideBanner,
    setAlwaysHideBanner,
    preferNewPurchaseBoard,
    setPreferNewPurchaseBoard,
    usingNewPurchaseBoard: !window.location.pathname.includes('classic'),
  }), [alwaysHideBanner, preferNewPurchaseBoard, setAlwaysHideBanner, setPreferNewPurchaseBoard]);
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}

PurchaseBoardBanner.propTypes = {};
ShowPurchaseBoardBannerButton.propTypes = {};
NewPurchaseBoardBannerProvider.propTypes = {children: HoopsPropTypes.any.isRequired,};
