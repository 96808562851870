import React, {useCallback, useState} from 'react';
import {SendJobModal} from '../../../componentsHoops/Modals';
import {Button, Decorator, Link} from '../../../componentsLib/Basic';
import {PopupItem, PopupMenuList, usePopupMenuContext} from '../../../componentsLib/Popovers';
import {useMountEffect} from '../../../hooks';
import {useFetchSalesDoc} from '../../../hooks/api';
import {BodyText} from '../../../componentsLib/Text';

export function JobActionMenu({salesDoc: _salesDoc, refetchSalesDocs}) {
  const [fetchedSalesDoc, setFetchedSalesDoc] = useState(null);
  const salesDoc = fetchedSalesDoc ?? _salesDoc;
  const {closeMenu} = usePopupMenuContext();
  const [showSend, setShowSend] = useState(false);
  const {fetch: fetchSalesDocApi} = useFetchSalesDoc();

  useMountEffect(() => {
    (async () => {
      if (!fetchedSalesDoc) {
        const fetched = await fetchSalesDocApi(salesDoc._id);
        setFetchedSalesDoc(fetched?.salesDoc);
        if (fetched?.salesDoc?.invoice?.status !== salesDoc.invoice?.status) {
          refetchSalesDocs();
        }
      }
    }
    )();
  });

  const handleShowSend = useCallback(async () => {
    setShowSend(true);
  }, []);

  const handleCloseSend = useCallback(() => {
    setShowSend(false);
    closeMenu();
  }, [closeMenu]);

  // TODO add in the rest of the Job Action menu items

  return (
    <PopupMenuList className={'job-action-menu'}>
      <PopupItem disabled >
        <Link to={'#'}>
          <Decorator>edit</Decorator>
          <BodyText>Edit</BodyText>
        </Link>
      </PopupItem>
      <PopupItem prefix='send' text='Send' onClick={handleShowSend} keepOpenHidden={true} />
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>description</Decorator>
          <BodyText>PDF Job Sheet</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>description</Decorator>
          <BodyText>PDF Proof Sheet</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>description</Decorator>
          <BodyText>PDF Packing Sheet</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>description</Decorator>
          <BodyText>PDF Decorator Spec Sheet</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>file_copy</Decorator>
          <BodyText>Duplicate</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>local_shipping</Decorator>
          <BodyText>Shipping Label</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>image</Decorator>
          <BodyText>Upload Proof</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>description</Decorator>
          <BodyText>Add Purchase Order</BodyText>
        </Link>
      </PopupItem>
      <PopupItem disabled>
        <Link to={'#'}>
          <Decorator>assignment_turned_in</Decorator>
          <BodyText>Add Task</BodyText>
        </Link>
      </PopupItem>

      {showSend &&
        <SendJobModal salesDoc={salesDoc} onClose={handleCloseSend} />
      }
    </PopupMenuList>
  );
}

export const JobActionMenuButton = ({salesDoc, updateField, refetchSalesDocs}) => (
  <Button menu navStandard text={'Actions'} placement={'bottom-end'}>
    <JobActionMenu salesDoc={salesDoc} updateField={updateField} refetchSalesDocs={refetchSalesDocs} />
  </Button>
);

