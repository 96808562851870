import React, {forwardRef, useCallback} from 'react';
import {GridFilterPanel as MuiGridFilterPanel,} from '@mui/x-data-grid-pro';
import {GridPanelWrapper} from '@mui/x-data-grid/components/panel/GridPanelWrapper';
import {GridPanelContent} from '@mui/x-data-grid/components/panel/GridPanelContent';
import {usePageContext} from '../../componentsLib/Layout';
import {Checkbox} from '../../componentsLib/Basic';
import {valueFromEvent} from '../../utils';
import {registerGlobalStyle} from '../../theme';
import {PermissionBasedContent, SubscriptionTypes} from '../AccessControl';

registerGlobalStyle('.purchaseorder-grid-filter-panel', () => ({'.checkbox .text-caption': {fontSize: '1rem'}}));

export const ViewGridFilterPanel = forwardRef(
function PurchaseOrderGridFilterPanel(props, ref) {
  const {viewState} = usePageContext();

  const handleChangeShowArchived = useCallback((e) => {
    viewState.setParameter({archived: valueFromEvent(e) ? true : null});
  }, [viewState]);

  const handleChangeShowVoided = useCallback((e) => {
    viewState.setParameter({voided: valueFromEvent(e) ? true : null});
  }, [viewState]);

  return (
    <GridPanelWrapper className={'purchaseorder-grid-filter-panel'} ref={ref} {...props}>
      <GridPanelContent>
        <MuiGridFilterPanel {...props}/>
        {viewState.allowedParameters?.includes('archived') &&
          <Checkbox label={'Show Archived'} checked={viewState.currentView.parameters?.archived != null} onChange={handleChangeShowArchived}/>
        }
        <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
          {viewState.allowedParameters?.includes('voided') &&
            <Checkbox label={'Show Voided'} checked={viewState.currentView.parameters?.voided != null} onChange={handleChangeShowVoided}/>
          }
        </PermissionBasedContent>
      </GridPanelContent>
    </GridPanelWrapper>
  );
});
