import React from 'react';
import {Switch} from 'react-router-dom';
import {ErrorBoundaryRoute} from '../../../componentsHoops/Errors';
import {PurchaseBoardPage} from '../Pages';

export function PurchaseOrderRoutes() {
  return (
    <Switch>
      <ErrorBoundaryRoute exact path={[
        '/purchasing/queue',
        '/purchasing/queue/:tabName',
      ]}>
        <PurchaseBoardPage queue/>
      </ErrorBoundaryRoute>
      <ErrorBoundaryRoute exact path={[
        '/purchasing',
        '/purchasing/jobs/:jobIdOrNumber',
        '/purchasing/jobs/:jobIdOrNumber/:tabName',
        '/purchasing/sales/:salesDocIdOrNumber',
        '/purchasing/sales/:salesDocIdOrNumber/:tabName',
        '/purchasing/:idOrNumber',
        '/purchasing/:idOrNumber/:tabName',
      ]}>
        <PurchaseBoardPage/>
      </ErrorBoundaryRoute>
    </Switch>
  );
}
