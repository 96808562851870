import React, {createContext, useContext, useMemo} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {useCompany} from '../../hooks';
import {Row, RowRight} from './Row';
import PropTypes from 'prop-types';
import {HeadingText} from '../Text';
import {Column} from './Column';

registerGlobalStyle('.hoops-page', (theme) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  minWidth: 600,
  overflow: 'hidden',
  '&.page-paper': {backgroundColor: theme.colors.background.white,},
  '.page-scroll-container': {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '&.page-pad': {padding: theme.spacing(3)},
    '&.align-center': {alignItems: 'center'},
  },
  '.page-header': {
    padding: theme.spacing(1.5, 2, 1.375),
    alignItems: 'center',
    columnGap: theme.spacing(2),
    '.text': {
      width: 'auto',
      span: {
        color: theme.colors.text.highlight,
        marginRight: theme.spacing(1),
      },
      strong: {color: theme.colors.palette.red}
    },
    '.text-heading': {
      fontSize: theme.fontSize(32),
      lineHeight: 1.25,
    },
    '.header-info > .text-body': {fontSize: '1rem'},
    '&>*:first-child': {flexGrow: 1},
    '&.sticky': {
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
      backgroundColor: theme.colors.background.white,
      borderBottom: `1px solid ${theme.colors.border.light}`,
    },
    '&:is(.sliding-drawer .page-header)': {
      position: 'static',
      padding: 0,
      backgroundColor: theme.colors.background.empty,
      '.text-heading': {
        color: theme.colors.text.dark,
        fontSize: theme.fontSize(24),
        fontWeight: theme.fontWeight.light,
      },
      '.subheading': {
        fontSize: theme.fontSize(16),
        fontWeight: theme.fontWeight.normal,
        marginBottom: theme.spacing(2),
      },
      '.text-body': {fontSize: theme.fontSize(16)},
    },
  },
  '.page-footer': {
    backgroundColor: theme.colors.background.empty,
    position: 'sticky',
    bottom: 0,
    zIndex: theme.zIndex.appBar,
  },
}));

export const PageContext = createContext(null);
export const usePageContext = () => useContext(PageContext);
export function useMergePageContext(...args) {
  const parentContext = usePageContext();
  return useMemo(() => Object.assign({}, parentContext, ...args),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [parentContext, args.length, ...args]);
}

export function Page({className, testId, alignCenter, context, pad, paper, title, children}) {
  const {company} = useCompany();
  const currencySymbol = context?.currencySymbol ?? company.currencySymbol;
  const mergedContext = useMergePageContext(context, {company, currencySymbol});

  if (title) {
    if (!title.includes('| Hoops')) {
      title = title + ' | Hoops';
    }
    document.title = title;
  }

  document.documentElement.style.setProperty('--currencyContent', `"${currencySymbol}"`);
  document.documentElement.style.setProperty('--currencySymbol', `${currencySymbol}`);

  return (
    <div className={classNames([className, 'hoops-page', paper && 'page-paper'])} id={testId}>
      <div className={classNames(['page-scroll-container', pad && 'page-pad', alignCenter && 'align-center'])}>
        <PageContext.Provider value={mergedContext}>
          {children}
        </PageContext.Provider>
      </div>
    </div>
  );
}

Page.propTypes = {
  className: HoopsPropTypes.className,
  testId: HoopsPropTypes.string,
  alignCenter: HoopsPropTypes.bool,
  context: HoopsPropTypes.object,
  pad: HoopsPropTypes.bool,
  paper: HoopsPropTypes.bool,
  title: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

export function PageContainer({className, style, children}) {
  return (
    <div className={classNames([className, 'page-container'])} style={style}>
      {children}
    </div>
  );
}

PageContainer.propTypes = {
  className: HoopsPropTypes.className,
  style: HoopsPropTypes.object,
  children: HoopsPropTypes.children,
};

export function PageHeader({className, title, sticky, children}) {
  return (
    <Row className={[className, 'page-header', sticky && 'sticky', title && 'title']}>
      {title &&
        <>
          <HeadingText h3>
            {title}
          </HeadingText>
          <RowRight>
            {children}
          </RowRight>
        </>
      }
      {!title &&
        children
      }
    </Row>
  );
}

PageHeader.propTypes = {
  className: HoopsPropTypes.className,
  title: HoopsPropTypes.string,
  sticky: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};

export function HeaderInfo({className, children}) {
  return (
    <Column className={className}>
      {children}
    </Column>
  );
}

HeaderInfo.propTypes = {
  className: HoopsPropTypes.className,
  children: HoopsPropTypes.children,
};

export function PageFooter({children}) {
  return (
    <Row className={'page-footer'} alignBaseline space>
      {children}
    </Row>
  );
}

PageFooter.propTypes = {children: PropTypes.any.isRequired};
