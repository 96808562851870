import React, {createContext, useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import NewIcon from '@mui/icons-material/NewReleases';
import ThumbsUp from '@mui/icons-material/ThumbUpAlt';
import {Collapse, Fade} from '@mui/material';
import {Row} from '../../../componentsLib/Layout';
import {Button} from '../../../componentsLib/Basic';
import {useLocalStorageState} from '../../../hooks';
import {featureFlags} from '../../../utils/featureFlag';
import {HoopsPropTypes} from '../../../componentsLib';
import {BodyText} from '../../../componentsLib/Text';
import {getLocalStorageItem} from '../../../utils';
import classNames from 'classnames';
import {FeatureFlag} from '../../../componentsHoops/AccessControl';

export function JobBoardBanner() {
  const {alwaysHideBanner, setAlwaysHideBanner, setPreferNewJobBoard, usingNewJobBoard} = useNewJobBoardBannerContext();
  const history = useHistory();

  const handleSwitchEditor = useCallback(() => {
    if (usingNewJobBoard) {
      history.replace('/classic-jobs');
    } else {
      history.replace('/jobs');
    }
  }, [history, usingNewJobBoard]);

  const handleGotIt = useCallback(() => {
    setPreferNewJobBoard(usingNewJobBoard);
    setAlwaysHideBanner(true);
  }, [usingNewJobBoard, setAlwaysHideBanner, setPreferNewJobBoard]);

  return (
    <FeatureFlag allow={featureFlags.JobsPOsRebuild}>
      <Row className={'jobboard-banner new'}>
        <Collapse in={!alwaysHideBanner}>
          <Row className={'banner-content'} alignCenter pad gap>
            <BodyText>
              We&apos;ve updated JobBoard to integrate seamlessly with SalesDocs. We hope you like the new features, but you can continue to use the classic JobBoard if you prefer.
            </BodyText>
            <Button className={'got-it'} unstyled onClick={handleGotIt} text={'Got it'} suffix={ThumbsUp} noWrap/>
            <Button unstyled onClick={handleSwitchEditor} noWrap>
              {usingNewJobBoard ? 'Use classic JobBoard' : 'Use JobBoard'}
            </Button>
          </Row>
        </Collapse>
      </Row>
    </FeatureFlag>
  );
}

export function ShowJobBoardBannerButton() {
  const {alwaysHideBanner, setAlwaysHideBanner, usingNewJobBoard} = useNewJobBoardBannerContext();

  const handleShowBanner = useCallback(() => {
    setAlwaysHideBanner(false);
  }, [setAlwaysHideBanner]);

  return (
    <FeatureFlag disallow={featureFlags.disallowOldQuoting}>
      <Fade in={alwaysHideBanner}>
        <div className={classNames(['show-jobboard-button', usingNewJobBoard ? 'new' : 'old'])}>
          <Button onClick={handleShowBanner} prefix={NewIcon}/>
        </div>
      </Fade>
    </FeatureFlag>
  );
}

const Context = createContext(null);
const useNewJobBoardBannerContext = () => useContext(Context);

export function isUsingJobBoard() {
  return getLocalStorageItem('jobboard|preferJobBoard', true);
}

export function NewJobBoardBannerProvider({children}) {
  const [alwaysHideBanner, setAlwaysHideBanner] = useLocalStorageState('jobboard|alwaysHideBanner2', false);
  const [preferNewJobBoard, setPreferNewJobBoard] = useLocalStorageState('jobboard|preferJobBoard', true);

  const value = useMemo(() => ({
    alwaysHideBanner,
    setAlwaysHideBanner,
    preferNewJobBoard,
    setPreferNewJobBoard,
    usingNewJobBoard: !window.location.pathname.includes('classic'),
  }), [alwaysHideBanner, preferNewJobBoard, setAlwaysHideBanner, setPreferNewJobBoard]);
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}

JobBoardBanner.propTypes = {};
ShowJobBoardBannerButton.propTypes = {};
NewJobBoardBannerProvider.propTypes = {children: HoopsPropTypes.any.isRequired,};
