// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import {Grid, MuiThemeProvider, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import green from '../../../ui/theme/green';
import {Cancel} from '@material-ui/icons';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloudUpload from '@material-ui/icons/CloudUpload';
import red from '../../../ui/theme/red';
import defaultCompanyLogo from '../../../assets/images/default-logo.png';

const styles = (theme) => ({
  headerContainer: {
    width: '100%',
    minHeight: 100,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  logoContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerLogo: {
    maxWidth: '100%',
    maxHeight: '100%',
    [theme.breakpoints.up('md')]: {maxWidth: '25%'}
  },
  proofText: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {fontSize: '20px'}
  },
  statusText: {marginLeft: theme.spacing(1)},
});

class Header extends Component {

  statusHelper = (status) => {
    const {classes} = this.props;
    switch (status) {
      case 'UPLOADED':
        return (
          <Fragment>
            <CloudUpload />
            <Typography align='right' className={classes.statusText}>
              Awaiting Approval
            </Typography>
          </Fragment>
        );

      case 'APPROVED':
        return (
          <MuiThemeProvider theme={green}>
            <CheckCircle color='primary' />
            <Typography color='primary' align='right' className={classes.statusText}>
              {status}
            </Typography>
          </MuiThemeProvider>
        );

      case 'REJECTED':
        return (
          <MuiThemeProvider theme={red}>
            <Cancel color='primary' />
            <Typography color='primary' align='right' className={classes.statusText}>
              {status}
            </Typography>
          </MuiThemeProvider>
        );

      default:
      return null;
    }
  };

  render() {
    const {classes, activeProof, activeProofIndex, company, proofs} = this.props;

    const getTradingEntityIndexOrDefault = (entities, companyTradingEntityId) => {
      if (!companyTradingEntityId) {
        return 0;
      }
      const idx = entities.findIndex((entity) => entity._id.toString() === companyTradingEntityId.toString());
      return idx !== -1 ? idx : 0;
    };

    const companyEntityIndex = activeProof?.job?.customer
      ? getTradingEntityIndexOrDefault(company?.companyTradingEntities, activeProof?.job?.customer?.companyTradingEntityId)
      : 0;

    const logo = company?.companyTradingEntities?.[companyEntityIndex]?.logo ?? defaultCompanyLogo;
    const whitelabelPortal = activeProof?.job?.customer?.settings?.whitelabelProofPortal ?? false;

    return (
      <>
        <Grid container spacing={2}
              className={classes.headerContainer}
              justifyContent={'space-between'}
              alignItems={'center'}>
          <Grid item xs={3} sm={4} md={4} className={classes.logoContainer}>
            {!whitelabelPortal && <Hidden lgUp>
              <img className={classes.headerLogo}
                   src={logo}
                   alt='Header logo'
              />
            </Hidden>}
          </Grid>
          <Hidden mdDown>
            <Grid item md={4} className={classes.logoContainer}>
              {!whitelabelPortal &&
                <img className={classes.headerLogo}
                     src={logo}
                     alt='Header logo'
              />}
            </Grid>
          </Hidden>
          <Grid item xs={9} sm={8} md={4}>
            <MuiThemeProvider theme={green}>
              <Typography variant={'h4'} color='primary' align='right' className={classes.proofText}>
                Proof {activeProofIndex + 1} of {proofs.length}
              </Typography>
              <Grid container justifyContent='flex-end'>
                {this.statusHelper(activeProof?.status)}
              </Grid>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </>
    );
  }
}

Header = withStyles(styles)(Header);

export default Header;
