import React, {useCallback, useMemo} from 'react';
import {uniq} from 'lodash';
import {useViewColumns, useViewState, ViewTabs} from '../../../componentsHoops/Views';
import {Page, PageHeader} from '../../../componentsLib/Layout';
import {HeadingText} from '../../../componentsLib/Text';
import {Button} from '../../../componentsLib/Basic';
import {CLEAR_JOB} from '../../../actions/jobs';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {useJobsState} from '../State/useJobsState';
import {ACCOUNTING_STATUS, AccountingStatusCell, ActionMenuCell, LinkCell, PowerGrid, PowerGridComponents} from '../../../componentsLib/PowerGrid';
import {SlidingDrawer} from '../../../componentsLib/Popovers';
import {SalesDocPage} from '../../SalesDoc';
import {JobBoardActionMenu, JobBoardGridFilterPanel} from '../Components';
import {FeatureFlag, PermissionBasedContent, SubscriptionTypes} from '../../../componentsHoops/AccessControl';
import {useCompany} from '../../../hooks';
import {JobBoardBanner, NewJobBoardBannerProvider, ShowJobBoardBannerButton} from '../../SalesDoc/Components';
import {ClassicJobPage} from './ClassicJobPage';
import {featureFlags} from '../../../utils/featureFlag';

const staticColumnInfo = {
  'number': {
    width: 133,
    renderCell: (props) => <LinkCell to={`/classic-jobs/${props.row._id}`} {...props} />,
    cellClassName: 'bold',
  },
  'digest.quote.number': {
    filters: ['equals', 'isEmpty', 'isNotEmpty'],
    sort: -1,
    align: 'center',
    editable: false,
    renderCell: (props) => <LinkCell to={`/classic-jobs/sales/${props.row.digest.quote?.number}`} {...props} />,
    cellClassName: 'bold',
  },
  'digest.invoice._id': {
    width: 180,
    filters: ['isAnyOf', 'isEmpty', 'isNotEmpty'],
    type: 'singleSelect',
    valueOptions: Object.entries(ACCOUNTING_STATUS).map(([key, {label}]) => ({value: key, label})),
    renderCell: (props) => <AccountingStatusCell invoice={props.row.digest.invoice?._id ? props.row.digest.invoice : null} {...props} />,
  },
  'digest.quote.stripeInvoiceId': {
    width: 180,
    editable: false,
    renderCell: ({value}) => value && <LinkCell value={'View Invoice'} href={`https://dashboard.stripe.com/invoices/${value}`}/>,
    valueGetter: ({row}) => row.digest?.quote?.stripeInvoiceId ?? null,
    filters: ['isEmpty', 'isNotEmpty'],
    align: 'center',
  },
  'customer.name': {},
  'deadlineAt': {purpose: 'dueDate'},
  'DECORATION': {
    valueGetter: ({row, colDef: {valueOptions}}) =>
      uniq(row.digest?.decorationIds?.map((id) => (valueOptions || []).find(({decorationIds}) => decorationIds.includes(id))?.label) ?? [])
  },
  'VENDOR': {
    valueGetter: ({row, colDef: {valueOptions}}) =>
      uniq(row.digest?.vendorIds?.map((id) => (valueOptions || []).find((option) => option.value === id)?.label) ?? [])
  },
  'actions': {
    renderCell: (props) => (
      <ActionMenuCell {...props}>
        <JobBoardActionMenu job={props.row} updateField={props.colDef.context.updateField} refetchSalesDocs={props.colDef.context.refetch} />
      </ActionMenuCell>
    ),
  }
};

const JobGridComponents = {
  ...PowerGridComponents,
  FilterPanel: JobBoardGridFilterPanel,
};

export function ClassicJobBoardPage() {
  const baseJobsPath = featureFlags.JobsPOsRebuild.enabled() ? '/classic-jobs' : '/jobs';
  const dispatch = useDispatch();
  const history = useHistory();
  const {jobId, salesDocNumber, customerId} = useParams();
  const {company: {appAccess}} = useCompany();
  const viewState = useViewState('jobs', {
    viewLimit: appAccess?.jobBoard?.viewLimit,
    fieldLimit: appAccess?.jobBoard?.columnLimit,
    homeViewOnly: !!customerId,
  });
  const {jobs, total, isLoading: isJobsLoading, refetch: refetchJobs, updateField} =
    useJobsState({...viewState.apiState, ...(customerId ? {fixedFilter: {customerId}} : {})});
  const extraColumnInfo = useMemo(() => ({
    ...staticColumnInfo,
    'number': {
      ...staticColumnInfo['number'],
      renderCell: (props) => <LinkCell to={`${baseJobsPath}/${props.row._id}`} {...props} />,
    },
    'digest.quote.number': {
      ...staticColumnInfo['digest.quote.number'],
      renderCell: (props) => <LinkCell to={`${baseJobsPath}/sales/${props.row.digest.quote?.number}`} {...props} />,
    },
  }), [baseJobsPath]);
  const viewColumns = useViewColumns(viewState, {
    extraColumnInfo,
    updateField,
    refetch: refetchJobs,
    legacyPath: true,
  });

  const handleCloseDrawer = useCallback(() => {
    history.push(baseJobsPath);
  }, [baseJobsPath, history]);

  const handleAddJob = useCallback(() => {
    dispatch({type: CLEAR_JOB});
    history.push(`/${baseJobsPath}/new`);
  }, [baseJobsPath, dispatch, history]);

  const context = useMemo(() => ({viewState}), [viewState]);

  return (
    <Page testId={'jobboard-page'} className={'jobboard-page'} context={context} title={'Jobs | Hoops'} paper>
      {customerId == null &&
        <NewJobBoardBannerProvider>
          <JobBoardBanner />
          <PageHeader>
            <HeadingText>
              Job<span>Board</span>
              <FeatureFlag allow={featureFlags.JobsPOsRebuild}>
                &nbsp;<mark>Classic</mark>
              </FeatureFlag>
            </HeadingText>
            <ShowJobBoardBannerButton />
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
              <Button navMain text={'ADD Job'} onClick={handleAddJob} />
            </PermissionBasedContent>
          </PageHeader>
        </NewJobBoardBannerProvider>
      }

      {customerId == null &&
        <ViewTabs viewState={viewState} />
      }

      <PowerGrid
        columnState={viewColumns}
        components={JobGridComponents}
        density={viewState.currentView.density}
        loading={viewState.isLoading || isJobsLoading}
        page={viewState.page}
        pageSize={viewState.pageSize}
        rows={jobs}
        rowsTotal={total}
        search={viewState.search}
        sort={viewState.currentView.sort}
        filters={viewState.currentView.filters}
        viewChanged={viewState.currentView.changed}
        viewReadOnly={viewState.currentView.readonly}

        onChangeDensity={viewState.setDensity}
        onChangeFilters={viewState.setFilters}
        onChangePage={viewState.setPage}
        onChangePageSize={viewState.setPageSize}
        onChangeSearch={viewState.setSearch}
        onChangeSort={viewState.setSort}
        onSaveView={viewState.saveView}
      />

      <SlidingDrawer className={'jobboard-drawer job-drawer'} open={jobId != null} onClose={handleCloseDrawer}>
        {jobId != null &&
          <ClassicJobPage job={{_id: jobId}} updateJobBoard={refetchJobs} />
        }
      </SlidingDrawer>

      <SlidingDrawer className={'jobboard-drawer salesdoc-drawer'} open={salesDocNumber != null} onClose={handleCloseDrawer}>
        {salesDocNumber != null &&
          <SalesDocPage />
        }
      </SlidingDrawer>
    </Page>
  );
}
