import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import {featureFlagEnabled} from '../../utils/featureFlag';
import {permissionCheck} from './permissionCheck';
import {ErrorBoundaryRoute} from '../Errors';

export const PermissionBasedRoute = ({
  children,
  allowedRoles = [],
  disallowedSubscriptions = [],
  featureFlag,
  disallowedFeatureFlag,
  ...props
}) => {
  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);

  if (!user.role) {
    // wait for user to be downloaded and set
    return null;
  }

  if (featureFlag && !featureFlagEnabled(featureFlag)) {
    return <Redirect to={'/jobs'} />;
  }

  if (disallowedFeatureFlag && featureFlagEnabled(disallowedFeatureFlag)) {
    return <Redirect to={'/jobs'} />;
  }

  if (!permissionCheck({allowedRoles, disallowedSubscriptions})) {
    return <Redirect to='/accessdenied' />;
  }

  return children && (
    <ErrorBoundaryRoute {...props}>{children}</ErrorBoundaryRoute>
  );
};
