import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {registerGlobalStyle} from '../../theme';
import {BodyText} from '../Text';
import {formatDateLong} from '../../utils';

registerGlobalStyle('.deadline-view', (theme) => ({
  display: 'flex',
  alignItems: 'baseline',
  columnGap: theme.spacing(.5),
  color: theme.colors.palette.red,
  'svg': {
    fontSize: 'inherit',
    alignSelf: 'center',
  },
}));

export function Deadline({className, date}) {
  return (
    <BodyText className={[className, 'deadline-view']}>
      <AccessTimeIcon/>
      Deadline {formatDateLong(date)}
    </BodyText>
  );
}
