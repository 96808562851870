import {SendDialog} from './SendDialog';
import React, {useCallback} from 'react';
import {useSendPurchaseOrder, useUsersCache} from '../../hooks/api';

export function SendPurchaseOrderModal({purchaseOrder, onClose}) {
  const {send: sendApi} = useSendPurchaseOrder();
  const {_id: purchaseOrderId, contact, customer, number, ownerId, vendor} = purchaseOrder;
  const {data: {users = []}} = useUsersCache();
  const owner = users.find(({_id: userId}) => userId === ownerId);

  const handleSend = useCallback(async (sendOptions) => {
    if (sendOptions.email?.to || sendOptions.sms?.phone) {
      const to = sendOptions.email?.to && sendOptions.sms?.phone
        ? `${sendOptions.email.to} and ${sendOptions.sms.phone}`
        : sendOptions.email?.to ?? sendOptions.sms?.phone;
      await sendApi({id: purchaseOrderId, ...sendOptions}, {successMessage: `Purchase Order #${number} to ${to}`});
    }
  }, [number, purchaseOrderId, sendApi]);

  return (
    <SendDialog
      entity={'purchaseOrder'}
      title={`Send Purchase Order #${number}`}
      onClose={onClose}
      onSend={handleSend}
      to={vendor.email ?? contact?.email ?? customer?.email}
      replyTo={owner?.username}
      phone={contact?.phone ?? customer?.phone}
    />
  );
}
