import {Chip, Link as MuiLink, MuiThemeProvider, Paper, Table, TableContainer, Typography} from '@material-ui/core';
import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {deleteEmailTemplate, makeDefaultEmailTemplate} from '../../actions/emailTemplate';
import {permissionCheck, SubscriptionTypes} from '../../componentsHoops/AccessControl';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader} from '../../componentsOld/HoopsQuery';
import {GQL_EMAIL_TEMPLATE_PAGINATION} from '../../queries/emailTemplate';
import purple from '../../ui/theme/purple';
import EmailTemplateManyTableActionMenu from './EmailTemplateManyTableActionMenu';
import {EmailTemplateContext} from './EmailTemplateManyViewPage';
import {splitCamelCase} from '../../utils';

const EmailTemplateManyTable = ({queryStateId = 'emailTemplateMany',}) => {
    const dispatch = useDispatch();
    const {setOpen, setActiveEmailTemplate} = useContext(EmailTemplateContext);

    const subFilter = permissionCheck({allowedSubscriptions: [SubscriptionTypes.proofs]}) ? {entity: 'proof'} : {};

    const columns = [
        {
            label: 'Type',
            field: 'title',
            render: (rowData) => <Typography variant='body2' style={{textTransform: 'capitalize'}}>{rowData.via}</Typography>
        },
        {
            label: 'Template Name',
            field: 'title',
            render: (rowData) => (
                    <MuiLink component={Link} underline={'none'} color={'primary'} onClick={(e) => openEditModal(e, rowData._id)}>
                        {rowData.title}
                    </MuiLink>
                ),
        }, {
            label: 'Send from',
            field: 'entity',
            render: (rowData) => <Typography variant='body2' style={{textTransform: 'capitalize'}}>{splitCamelCase(rowData.entity)}</Typography>
        }, {
            label: 'Default',
            field: 'isDefault',
            render: (rowData) => rowData.isDefault && <MuiThemeProvider theme={purple}><Chip label='Default' color='primary' size='small' /> </MuiThemeProvider>

        }, {
            label: 'Actions',
            align: 'right',
            render: (rowData) => <EmailTemplateManyTableActionMenu rowData={rowData} handleDelete={handleDelete} handleMakeDefault={handleMakeDefault} openEditModal={openEditModal} />
        }
    ];

    const handleMakeDefault = (e, id) => {
        dispatch(makeDefaultEmailTemplate(id));
    };

    const handleDelete = (e, id) => {
        dispatch(deleteEmailTemplate(id));
    };

    const openEditModal = (e, id) => {
        setActiveEmailTemplate(id);
        setOpen(true);
    };

    return (
        <>
            <HoopsQuery refetchStateId='emailTemplate' queryStateId={queryStateId} columns={columns} gqlQuery={GQL_EMAIL_TEMPLATE_PAGINATION} resultField='emailTemplatePagination' initialFilter={subFilter} fixedFilter={subFilter} initialSort={'_ID_ASC'}>
                <HoopsQueryContext.Consumer>
                    {({items, sort, setSort, filter, setFilter, paging, setPaging, setChips}) => (<>
                            <TableContainer component={Paper}>
                                <Table style={{minWidth: 'auto'}}>
                                    <HoopsQueryTableHeader hasCheckBox={false} columns={columns} onSort={setSort} sortString={sort} />
                                    <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={columns} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={columns} field={'Email Template'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={() => setOpen(true)} />} />
                                </Table>
                            </TableContainer>
                            <HoopsQueryPagination paging={paging} onPaging={setPaging} />
                        </>)}
                </HoopsQueryContext.Consumer>
            </HoopsQuery>
        </>
    );
};

export default EmailTemplateManyTable;
