import React from 'react';
import {Switch} from 'react-router-dom';
import {useMountEffect} from '../../../hooks';
import {ErrorBoundaryRoute} from '../../../componentsHoops/Errors';
import {JobBoardPage} from '../index';

export function JobsRoutes() {
  useMountEffect(() => {
    document.title = 'Jobs | Hoops';
  });

  return (
    <Switch>
      <ErrorBoundaryRoute exact path={[
        '/jobs',
        '/jobs/sales/:salesDocNumber',
        '/jobs/:jobIdOrNumber',
        '/jobs/:jobIdOrNumber/:tabName',
      ]}>
        <JobBoardPage/>
      </ErrorBoundaryRoute>
    </Switch>
  );
}
