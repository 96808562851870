import React, {useCallback, useMemo} from 'react';
import {useHistory, useParams} from 'react-router';
import {uniq} from 'lodash';
import {Page, PageHeader} from '../../../componentsLib/Layout';
import {HeadingText} from '../../../componentsLib/Text';
import {Button} from '../../../componentsLib/Basic';
import {NewPurchaseBoardBannerProvider, PurchaseBoardBanner, ShowPurchaseBoardBannerButton} from '../Components';
import {useViewColumns, useViewState, ViewGridFilterPanel, ViewTabs} from '../../../componentsHoops/Views';
import {ACCOUNTING_STATUS, AccountingStatusCell, ActionMenuCell, LinkCell, PowerGrid, PowerGridComponents} from '../../../componentsLib/PowerGrid';
import {SlidingDrawer} from '../../../componentsLib/Popovers';
import {JobPage, SalesDocPage} from '../../SalesDoc';
import {asCurrencyStringCommaSeparated, goBackOrGoTo} from '../../../utils';
import {usePurchaseOrderListState} from '../State';
import {PurchaseOrderPage} from './PurchaseOrderPage';
import {PurchasingQueuePage} from './PurchasingQueuePage';
import {PurchaseOrderActionMenu} from '../Components/PurchaseOrderActionMenu';

const staticColumnInfo = {
  'number': {
    filters: ['equals'],
    renderCell: (props) => <LinkCell to={`/purchasing/${props.row.number}`} {...props} />,
  },
  'docTypeName': {
    filters: ['isAnyOf'],
    editable: false,
    type: 'singleSelect',
    valueGetter: ({row: {docTypeName, documentType} = {}} = {}) => documentType == null ? 'Classic PO' : docTypeName,
  },
  'subTotal': {
    innerClassName: 'currency-prefix',
    valueGetter: ({value}) => asCurrencyStringCommaSeparated(value),
    editable: false,
    filters: ['isNotZero', 'isZero', '=', '>=', '>', '<=', '<'],
    type: 'number',
    align: 'right',
  },
  'accounting.status': {
    filters: ['isAnyOf', 'isEmpty', 'isNotEmpty'], type: 'singleSelect',
    valueOptions: Object.entries(ACCOUNTING_STATUS).map(([key, {label}]) => ({value: key, label})),
    renderCell: (props) => <AccountingStatusCell invoice={props.row.invoice} {...props} />,
  },
  'DECORATION': {valueGetter: ({row}) => uniq(row.summary?.decorations?.map(({category}) => category) ?? [])},
  'VENDOR': {valueGetter: ({row}) => uniq(row.summary?.vendors?.map(({name}) => name) ?? [])},
  'actions': {
    renderCell: (props) => (
      <ActionMenuCell {...props}>
        <PurchaseOrderActionMenu purchaseOrder={props.row} updateField={props.colDef.context.updateField} refetchPurchaseOrder={props.colDef.context.refetch} />
      </ActionMenuCell>
    ),
  },
};

const PurchaseOrderGridComponents = {
  ...PowerGridComponents,
  FilterPanel: ViewGridFilterPanel,
};

export function PurchaseBoardPage({queue}) {
  const history = useHistory();
  const {customerId, idOrNumber, jobIdOrNumber, salesDocIdOrNumber} = useParams();
  const viewState = useViewState('pos');
  const {purchaseOrders, total, isLoading, refetch: refetchPurchaseOrders, updateField, purchaseOrderTypeNames}
    = usePurchaseOrderListState({customerId, ...viewState.apiState});
  const extraColumnInfo = useMemo(() => ({
    ...staticColumnInfo,
    docTypeName: {
      ...staticColumnInfo.docTypeName,
      valueOptions: Object.entries(purchaseOrderTypeNames ?? {}).map(([label, value]) => ({label, value})),
    },
  }), [purchaseOrderTypeNames]);
  const viewColumns = useViewColumns(viewState, {extraColumnInfo, updateField, refetch: refetchPurchaseOrders});

  const handleAddPurchaseOrder = useCallback(() => {
    history.push('/purchasing/new');
  }, [history]);

  const handleViewQueue = useCallback(() => {
    history.push('/purchasing/queue');
  }, [history]);

  const handleCloseDrawer = useCallback(() => {
    goBackOrGoTo('/purchasing');
  }, []);

  const context = useMemo(() => ({viewState}), [viewState]);

  return (
    <Page testId={'purchaseboard-page'} className={'purchaseboard-page'} context={context} title={'Purchasing | Hoops'} paper>
      <NewPurchaseBoardBannerProvider>
        <PurchaseBoardBanner />
        <PageHeader>
          <HeadingText>Purchase<span>Board</span></HeadingText>
          <ShowPurchaseBoardBannerButton />
          <Button navStandard noWrap text={'Purchasing Queue'} onClick={handleViewQueue} />
          <Button navMain noWrap text={'Add Purchase Order'} onClick={handleAddPurchaseOrder} />
        </PageHeader>
      </NewPurchaseBoardBannerProvider>

      <ViewTabs viewState={viewState} />

      <PowerGrid
        columnState={viewColumns}
        components={PurchaseOrderGridComponents}
        density={viewState.currentView.density}
        loading={viewState.isLoading || isLoading}
        page={viewState.page}
        pageSize={viewState.pageSize}
        rows={purchaseOrders}
        rowsTotal={total}
        search={viewState.search}
        sort={viewState.currentView.sort}
        filters={viewState.currentView.filters}
        viewChanged={viewState.currentView.changed}
        viewReadOnly={viewState.currentView.readonly}

        onChangeDensity={viewState.setDensity}
        onChangeFilters={viewState.setFilters}
        onChangePage={viewState.setPage}
        onChangePageSize={viewState.setPageSize}
        onChangeSearch={viewState.setSearch}
        onChangeSort={viewState.setSort}
        onSaveView={viewState.saveView}
      />

      <SlidingDrawer className={'purchaseboard-drawer purchaseorder-drawer'} open={idOrNumber != null} onClose={handleCloseDrawer}>
        {idOrNumber != null &&
          <PurchaseOrderPage/>
        }
      </SlidingDrawer>

      <SlidingDrawer className={'purchaseboard-drawer salesdoc-drawer'} open={salesDocIdOrNumber != null} onClose={handleCloseDrawer}>
        {salesDocIdOrNumber != null &&
          <SalesDocPage updateField={updateField} />
        }
      </SlidingDrawer>

      <SlidingDrawer className={'purchaseboard-drawer job-drawer'} open={jobIdOrNumber != null} onClose={handleCloseDrawer}>
        {jobIdOrNumber != null &&
          <JobPage job={{_id: jobIdOrNumber}} />
        }
      </SlidingDrawer>

      <SlidingDrawer className={'purchaseboard-drawer purchase-queue-drawer'} open={queue} onClose={handleCloseDrawer}>
        {queue &&
          <PurchasingQueuePage/>
        }
      </SlidingDrawer>
    </Page>
  );
}
