import React, {useCallback, useMemo, useState} from 'react';
import {Column, Fields, Row, Table, TableCell, TableHeading, TableHeadingCell, TableRow, usePageContext} from '../../../componentsLib/Layout';
import {useGetJob, useUpdateJob} from '../../../hooks/api';
import {Button, TextInput} from '../../../componentsLib/Basic';
import {BodyText, CaptionText} from '../../../componentsLib/Text';
import {formatAddressTwoLines, formatDateTimeShort, fullName, inputNameFromEvent, valueFromEvent} from '../../../utils';
import {registerGlobalStyle} from '../../../theme';
import {ModalDialog} from '../../../componentsLib/Popovers';
import {useUser} from '../../../hooks';
import {TabHeading} from '../../../componentsLib/Basic/TabbedContent';

registerGlobalStyle('.job-shipping-tab', (theme) => ({
  '.customer-information': {width: '100%'},
  '.shipping-instructions-button': {fontWeight: theme.fontWeight.dark},
  '.job-shipping-tracking-numbers': {
    width: '100%',
    'th:last-child': {textAlign: 'right'},
    '.icon .decorator': {color: theme.colors.text.mediumIcon},
    'th, td': {padding: theme.spacing(1)},
    'tr': {
      borderBottomColor: theme.colors.border.light,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
    },
  },
}));

registerGlobalStyle('.shipping-instructions-dialog, .add-tracking-number-dialog', (theme) => ({
  '.modal-dialog-inner': {
    minWidth: 600,
    maxWidth: 700,
    'input + span': {marginTop: theme.spacing(2)},
    '.text-input.container': {
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
    },
  }
}));

export function JobShippingTab() {
  const {salesDoc, refetchSalesDoc, updateBoardField} = usePageContext();
  const {data: classicJob, refetch: refetchClassicJob} = useGetJob(salesDoc.jobId);
  const {user} = useUser();
  const {mutateAsync: updateClassicJob} = useUpdateJob();

  const handlePrintShippingLabel = useCallback(() => {
    if (salesDoc.hasClassicJob()) {
      window.open(`${process.env.REACT_APP_BASE_URL}/jobs/${salesDoc.jobId}/shipping-label`, '_blank');
    } else {
      // TODO - this still needs a salesDoc equivalent link > this is found in the backend
      console.log('TODO');
    }
  }, [salesDoc]);

  const handleUpdateJob = useCallback(async (model) => {
    if (salesDoc.hasClassicJob()) {
      await updateClassicJob({id: salesDoc.jobId, data: {...model}});
      await refetchClassicJob();
    } else {
      await updateBoardField({id: salesDoc._id, field: Object.keys(model)[0], value: Object.values(model)[0]}, salesDoc);
    }
    await refetchSalesDoc();
  }, [refetchClassicJob, refetchSalesDoc, salesDoc, updateBoardField, updateClassicJob]);

  // Shipping instructions
  const shippingInstructions = classicJob?.shippingInstructions ?? salesDoc.shippingInstructions;
  const [isShippingInstructionsOpen, setIsShippingInstructionsOpen] = useState(false);
  const [shippingInstructionsText, setShippingInstructionsText] = useState(shippingInstructions);

  const toggleShippingInstructionModal = useCallback(() => {
    setIsShippingInstructionsOpen((prev) => !prev);
  }, []);

  const handleSaveInstructions = useCallback(() => {
    handleUpdateJob({shippingInstructions: shippingInstructionsText});
    setIsShippingInstructionsOpen(false);
  }, [handleUpdateJob, shippingInstructionsText]);

  // Tracking Number
  const trackingNumbers = useMemo(() => classicJob?.trackingNumbers ?? salesDoc.trackingNumbers ?? [], [classicJob, salesDoc]);
  const [isTackingNumberModalOpen, setIsTackingNumberModalOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState();

  const toggleTrackingNumberModal = useCallback(() => {
    setIsTackingNumberModalOpen((prev) => !prev);
  }, []);

  const updateTrackingNumber = useCallback((event) => {
    if (inputNameFromEvent(event) === 'trackingLink') {
      setTrackingNumber((prev) => ({...prev, trackingLink: `http://${valueFromEvent(event)}`}));
    } else {
      setTrackingNumber((prev) => ({...prev, [inputNameFromEvent(event)]: valueFromEvent(event)}));
    }
  }, []);

  const handleOpenTrackingNumberModal = useCallback((editTrackingNumber, index) => {
    const emptyTrackingNumber = {
      shippingCompany: '',
      trackingNumber: '',
      trackingLink: '',
      createdAt: new Date(),
      createdById: user._id,
      createdByName: fullName(user),
      // creator used in classic jobs
      creator: {_id: user._id, name: fullName(user)},
    };
    setTrackingNumber(index ? {...editTrackingNumber, index} : emptyTrackingNumber);
    setIsTackingNumberModalOpen(true);
  }, [user]);

  const handleSendTrackingNumberModal = useCallback(() => {
    // TODO add send modal
  }, []);

  const handleSaveTrackingNumber = useCallback(() => {
    if (trackingNumber.index) {
      handleUpdateJob({trackingNumbers: trackingNumbers.toSpliced(trackingNumber.index, 1, trackingNumber)});
    } else {
      handleUpdateJob({trackingNumbers: [...trackingNumbers, trackingNumber]});
    }
    setIsTackingNumberModalOpen(false);
  }, [handleUpdateJob, setIsTackingNumberModalOpen, trackingNumber, trackingNumbers]);

  const handleDeleteTrackingNumber = useCallback((index) => {
    handleUpdateJob({trackingNumbers: trackingNumbers.toSpliced(index, 1)});
  }, [handleUpdateJob, trackingNumbers]);

  const contact = salesDoc.contact ?? {};
  const billingAddress = formatAddressTwoLines(salesDoc.customer?.addresses?.find((address) => address.label === 'BILLING'));
  const shippingAddress = formatAddressTwoLines(salesDoc.customer?.addresses?.find((address) => address.label === 'SHIPPING'));

  return (
    <Column className={'job-shipping-tab'} paper fillWidth pad gap>
      <TabHeading decorator={'person'} heading={'Customer Information'}>
        <Button navPrimary text={'Print Shipping Label'} onClick={handlePrintShippingLabel} />
      </TabHeading>

      <Fields columns={3} fillWidth>
        <div>
          <CaptionText medium text={'Company'} />
          <BodyText text={`${salesDoc.customer?.name ?? ''} ${salesDoc.customer?.deletedAt ? '(Deleted)' : ''}`} />
        </div>

        <div>
          <CaptionText medium text={'Contact Name'} />
          <BodyText text={fullName(contact)} />
        </div>

        <div>
          <CaptionText medium text={'Email'} />
          <BodyText text={contact.email} />
        </div>

        <div>
          <CaptionText medium text={'Contact Number'} />
          <BodyText text={contact.phone} />
        </div>

        <div>
          <CaptionText medium text={'Billing Address'} />
          <BodyText>{billingAddress.line1}</BodyText>
          <BodyText>{billingAddress.line2}</BodyText>
        </div>

        <div>
          <CaptionText medium text={'Shipping Address'} />
          <BodyText>{shippingAddress.line1}</BodyText>
          <BodyText>{shippingAddress.line2}</BodyText>
        </div>

        <div>
          {shippingInstructions &&
            <>
              <CaptionText medium text={'Shipping Instructions'} />
              <BodyText text={shippingInstructions} />
            </>
          }
          <Button
            className={'shipping-instructions-button'}
            actionPrimary
            text={shippingInstructions ? 'Update Shipping Instructions' : 'Add Shipping Instructions'}
            onClick={toggleShippingInstructionModal}
          />
        </div>
      </Fields>

      <TabHeading decorator={'local_shipping'} heading={'Tracking Numbers'}>
          <Row gap>
            <Button navPrimary text={'Send Shipping Notification'} onClick={handleSendTrackingNumberModal} />
            <Button navPrimary text={'Add New Tracking Number'} onClick={handleOpenTrackingNumberModal} />
          </Row>
      </TabHeading>

      <Table className={'job-shipping-tracking-numbers'}>
        <TableHeading>
          <TableHeadingCell text={'Shipping Company'} />
          <TableHeadingCell text={'Tracking Number'} />
          <TableHeadingCell text={'Date Added'} />
          <TableHeadingCell text={'Creator'} />
          <TableHeadingCell text={'Actions'} width={50} />
        </TableHeading>

        {trackingNumbers.map((_trackingNumber, index) => (
          <TableRow key={index}>
            <TableCell text={_trackingNumber.shippingCompany} />
            <TableCell text={_trackingNumber.trackingNumber} />
            <TableCell text={formatDateTimeShort(_trackingNumber.createdAt)} />
            <TableCell text={_trackingNumber.createdByName ?? _trackingNumber.creator?.name} />
            <TableCell alignRight>
              <Button unstyled prefix={'edit'} onClick={() => handleOpenTrackingNumberModal(_trackingNumber, index)} />
              <Button unstyled prefix={'delete'} onClick={() => handleDeleteTrackingNumber(index)} />
            </TableCell>
          </TableRow>
        ))}
      </Table>

      {isShippingInstructionsOpen &&
        <ModalDialog
          className={'shipping-instructions-dialog'}
          title={shippingInstructions ? 'Update Shipping Instructions' : 'Add Shipping Instructions'}
          onOk={handleSaveInstructions}
          onCancel={toggleShippingInstructionModal}
        >
          <TextInput
            label={'Shipping Instructions'}
            multiline
            rows={5}
            value={shippingInstructionsText}
            onChange={(e) => setShippingInstructionsText(valueFromEvent(e))}
          />
        </ModalDialog>
      }

      {isTackingNumberModalOpen &&
        <ModalDialog
          className={'add-tracking-number-dialog'}
          title={'Add New Tracking Number'}
          onOk={handleSaveTrackingNumber}
          onCancel={toggleTrackingNumberModal}
        >
          <TextInput
            label={'Shipping Company'}
            value={trackingNumber.shippingCompany}
            name={'shippingCompany'}
            onChange={updateTrackingNumber}
          />
          <TextInput
            label={'Tracking Number'}
            value={trackingNumber.trackingNumber}
            name={'trackingNumber'}
            onChange={updateTrackingNumber}
          />
          <TextInput
            label={'Tracking Link'}
            prefix={<BodyText text={'https://'} />}
            value={trackingNumber.trackingLink?.replace(/^http:\/\//, '')}
            name={'trackingLink'}
            onChange={updateTrackingNumber}
          />
        </ModalDialog>
      }
    </Column>
  );
}
